export interface Student {
    id: number,
    lastName: string,
    firstName: string,
    birthDate: Date,
    address: string,
    zipCode: string,
    city: string,
    email: string,
    cellular: string,
    legalResponsible1Cellular: string,
    legalResponsible2Cellular: string,
    legalResponsible1Email: string,
    legalResponsible2Email: string,
    legalResponsible1FullName: string,
    legalResponsible2FullName: string,
    birthDateLabel: string, 
    notes: Note[],
    classe: string,
    period:string,
    classeId: number|undefined,
    legalResponsible: string,
    status: number,
    totalAbsences: number,
    totalLates: number,
    genre:number,
    absences: string,
    lates: string,
    publicId:string,
    comments :string,
    canBeDeleted: boolean,
    reason: number,
    displayAbsences:string,
    displayLates:string,
    absencesCount :number,
    notesCount : { [key: number]: {
        element: number,
        total : number 
    }} ;
}

export interface Note {
    id: number,
    value: number,
    scale: number | undefined,
    coefficient: number | undefined,
    subjectId: number | undefined,
    date?: string,
    isBlocked: boolean,
    subject: string,
    calendarDate?: string,
    comment?: string,
    classeAverage: number,
    bonus: number,
    isAbsent:boolean,
    dateLabel:string
    subjectAverageValue:number,
    classSubjectAverageValue : number
}

export interface History{
    class:string,
    id:number,
    period:string
}

export enum Status {
    Registered = "0",
    Unregistered = "1",
    Excluded = "2",
  }
  
  export enum Reason {
    No = 0,
    RedList = 1,
  }
  
  export const genres = [
    {
      id: 0,
      name: "Masculin",
    },
    {
      id: 1,
      name: "Féminin",
    },
  ];
  
  export const statuses = [
    {
      id: 0,
      name: "Inscrit",
    },
    {
      id: 1,
      name: "Désinscrit",
    },
    {
      id: 2,
      name: "Exclu",
    },
  ];
  
  export const reasons = [
    {
      id: 0,
      name: "Départ volontaire",
    },
    {
      id: 1,
      name: "Liste Rouge",
    },
    {
      id: 2,
      name: "Exclu",
    },
  ];
  
  export type UpdateProps =
    | "lastName"
    | "firstName"
    | "birthDateLabel"
    | "zipCode"
    | "city"
    | "email"
    | "genre"
    | "address"
    | "cellular"
    | "legalResponsible1Cellular"
    | "legalResponsible2Cellular"
    | "legalResponsible1Email"
    | "legalResponsible2Email"
    | "status"
    | "reason"
    | "classe"
    | "legalResponsible1FullName"
    | "legalResponsible2FullName"
    | "comments";

    export const initStudent = {
        absences:'',
        absencesCount:0,
        address:'',
        birthDate:new Date(),
        birthDateLabel:'',
        canBeDeleted:false,
        cellular:'',
        city:'',
        classe:'',
        comments:'',
        displayAbsences:'',
        displayLates:'',
        email:'',
        firstName:'',
        genre:-1,
        id:-1,
        lastName:'',
        lates:'',
        legalResponsible:'',
        legalResponsible1Cellular:'',
        legalResponsible1Email:'',
        legalResponsible1FullName:'',
        legalResponsible2Cellular:'',
        legalResponsible2Email:'',
        legalResponsible2FullName:'',
        notes:[],
        period:'',
        publicId:'',
        reason:-1,
        status:-1,
        totalAbsences:0,  
        totalLates:-1,
        zipCode:'',
        classeId:-1,
        notesCount:{}
      }