import React, { Fragment } from "react";
import { classNames, isAdmin } from "../../common/utils";
import { Student } from "./student";
import { useNavigate } from "react-router-dom";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";

interface StudentCardProps {
  student?: Student;
}
enum Status {
  Registered = "Inscrit",
  Unregisterd = "Désinscrit",
  Excluded = "Exclu",
}
const StudentCard = ({ student }: StudentCardProps) => {
  const history = useNavigate();

  const getValueName = (status?: number): string => {
    if (status === 0) return Status.Registered;
    if (status === 1) return Status.Unregisterd;
    if (status === 2) return Status.Excluded;
    return "";
  };

  const status = getValueName(student?.status);
  return student !== undefined ? (
   
    <>
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate cursor-pointer" onClick={()=> history(`/eleve/${student?.id}`)}>
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-gray-900 
             ">
              {`${student.lastName} ${student.firstName}`}
            </h3>
            <h3 className="truncate text-sm font-thin text-gray-900">
              {`${student.birthDateLabel}`}
            </h3>

            <span
              className={classNames(
                "inline-flex flex-shrink-0 items-center rounded-full",
                " px-1.5 py-0.5 text-xs font-medium",
                " ring-1 ring-inset ring-green-600/20",
                `${
                  status === Status.Registered
                    ? "bg-green-50 text-green-700"
                    : "bg-gray-300"
                }`
              )}
            >
              {status}
            </span>
          </div>
          <div className="flex justify-start">
            <h3 className="truncate text-sm font-medium text-gray-900">
              {`${student.classe ?? "(sans)"}`}
            </h3>
          </div>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <a
              href={`mailto:${student.legalResponsible1Email}`}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >
              <EnvelopeIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
              Email
            </a>
          </div>
          <div className="-ml-px flex w-0 flex-1">
            <a
              href={`tel:${student.legalResponsible1Cellular}`}
              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >
              <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              Appel
            </a>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Fragment />
  );
};

export default StudentCard;
