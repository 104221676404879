import * as yup from "yup";
import ForgottenPassword from "./forgottenPassword";

export const LoginLabels = {
  Valid: "Valider",
  IsRequired: "Ce champ est requis",
  ForgottenPasswordLabel:    "La demande de mot passe est effectuée. Vous recevrez un mail.",
  Login: 'Identifiant',
  Connect : 'Se connecter',
  PleaseConnect : 'Connectez-vous à votre compte',
  LoginError: "L'identifiant ou le mot de passe est erroné",
  Password: 'Mot de passe',
  ForgottenPassword:'Mot de passe oublié?',
  PleaseEnterUserName: 'Veuillez entrer votre identifiant',
  PleaseEnterNewPassword: 'Veuillez entre votre nouveau mot de passe',
  ConfirmPassword: 'Confirmation du mot de passe',
  ChangingPwdSuccess: "Le changement de mot de passe s'est bien déroulé",
  Error: "Une erreur est survenue",
};

export const loginSchema = yup
  .object({
    userName: yup.string().required(LoginLabels.IsRequired),
    pwd: yup.string().required(LoginLabels.IsRequired),
  })
  .required();

export type LoginModel = yup.InferType<typeof loginSchema>;

export const forgottenPwdSchema = yup
  .object({
    userName: yup.string().required(LoginLabels.IsRequired)    
  })
  .required();
  
export type ForgottenLoginModel = yup.InferType<typeof forgottenPwdSchema>;

export const confirmForgottenPwdSchema = yup
  .object({
    pwd: yup.string().required(LoginLabels.IsRequired).min(5,"Longueur minimale 5 caractères"),
    confirmPwd: yup.string()
        .required(LoginLabels.IsRequired)
        .min(5,"Longueur minimale 5 caractères")
        .oneOf([yup.ref('pwd')], "La confirmation n'est pas équivalente au mot de passe "),
  })
  .required();

export type ConfirmForgottenPwdModel = yup.InferType<typeof confirmForgottenPwdSchema>;