enum ApiUrls
{
    Users = 'api/user',  
    Subjects = 'api/subject', 
    Settings = 'api/settings', 
    Students = 'api/student', 
    Classes = 'api/class',
    Teachers = 'api/teacher',
    Note = 'api/note',
    Login='api/login',
    Level='api/level',
    Pole='api/pole',
    Absence='api/absence',
    Network = 'api/network'
}

export default ApiUrls