import React, { useEffect, useState } from "react";
import { BeeUser } from "./beeUser";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import * as Routes from "../../routing/routes";
import {
  createDownloadLink,
  getFileHeaders,
  getHeaders,
  isAdmin,
} from "../../common/utils";
import DeleteModal from "../common/delete";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { fetchApi } from "../../common/api";
import { EnvelopeIcon, TrashIcon } from "@heroicons/react/20/solid";
import Button from "../components/button";
import SearchBar from "../components/searchBar";
import { Item, Menu, useContextMenu } from "react-contexify";

const Labels = {
  Title: "Liste des utilisateurs",
  Add: "Ajouter",
  Error: "Une erreur est survenue",
};

const Users = () => {
  const [users, setUsers] = useState<BeeUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<BeeUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isDownLoading, setIsDownLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [userName, setUserName] = useState("");
  const { addToast } = useToasts();
  const history = useNavigate();
  const MENU_ID = "display-class-id";
  const { show } = useContextMenu({ id: MENU_ID });

  const fetchUsers = async (): Promise<BeeUser[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Users}`;
    return await fetchApi<BeeUser[]>(url);
  };

  const deleteUser = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Users}`;

    const body = {
      teacherId: currentUserId,
      userName,
    };

    const response = await fetch(url, {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `Suppression réussie`;
      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.UTILISATEURS);
      setCurrentUserId(0);
      setUserName("");
      setReload(!reload);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUsers().then((response) => {
      if (response) {
        setUsers(response);
        setFilteredUsers(response);
      }
      setIsLoading(false);
    });
  }, [reload]);

  const downloadUsers = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Teachers}/export`;

    setIsDownLoading(true);
    const response = await fetch(url, {
      method: "GET",
      headers: getFileHeaders(),
    });

    const blob = await response.blob();
    createDownloadLink(blob, "utilisateurs.csv");
    setIsDownLoading(false);
  };
  const onDeleteClick = (user: BeeUser) => {
    const id = user.id;
    setCurrentUserId(id);
    setUserName(user.userName);
    setShowDelete(true);
  };

  const onSearch = (pattern: string) => {
    const elements =
      pattern === ""
        ? users
        : users.filter((u) => u.userName.includes(pattern));

    setFilteredUsers(elements);
  };
  
  const displayMenu = (e: any, name: string) => {
    show({ event: e, props: { key: "value" } });
    setUserName(name);
  };

  const edit = (name:string) => {
    const url = `/utilisateur/modification/${name}`;
    history(url);
  };
  return (
    <div className="">
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        confirm={deleteUser}
      />
      

      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}

      {!isLoading && (
        <>
          
          <div className="w-full md:hidden flex-row">
            <div className="relative mt-2 mb-2 mr-2 flex">
              <Button
                label={isDownLoading ? "...En cours" : "Télécharger"}
                onClick={downloadUsers}
              />
            </div>
            <div className="flex flex-row">
              <SearchBar onSearch={onSearch} />
              <div className="relative mt-2 mb-2 ml-2">
                <Button
                  label="Ajouter"
                  onClick={() => history(Routes.UTILISATEURS_ADD)}
                />
              </div>
            </div>
          </div>

          <div className="hidden sm:flex-col w-full md:flex flex-row">
            <div className="relative mt-2 mb-2 mr-2 flex justify-left">
              <Button
                label={isDownLoading ? "...En cours" : "Télécharger"}
                onClick={downloadUsers}
              />
            </div>
            <div className="hidden w-full md:flex flex-row justify-end">
              <SearchBar onSearch={onSearch} />
              <div className="relative mt-2 mb-2 ml-2 mr-2">
                <Button label="Ajouter" onClick={() => history(Routes.UTILISATEURS_ADD)} />
              </div>
            </div>
          </div>
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            {filteredUsers.map((user) => (
              <li
                key={user.userName}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              >
                <div
                  className="flex w-full items-center justify-between space-x-6 p-6 cursor-pointer"
                  onClick={()=>edit(user.userName)}                 
                >
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {user.userName}
                      </h3>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {user.role}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a
                        href={`mailto:${user.userName}`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <EnvelopeIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-gray-400"
                        />
                        Email
                      </a>
                    </div>
                    <div className="flex w-0 flex-1">
                      <a
                        href="#"
                        onClick={() => onDeleteClick(user)}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <TrashIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-gray-400"
                        />
                        Supprimer
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Users;
