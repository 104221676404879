import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiUrls from "../../common/urls";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import BeeLoader from "../../layout/loader";
import {
  ConfirmForgottenPwdModel,
  confirmForgottenPwdSchema,
  LoginLabels,
} from "./loginSchema";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchApi, postApi } from "../../common/api";
interface ForgottenPwd {
  login: string;
  token: string;
}

const ConfirmForgottenPassword = () => {
  const guid = (useParams() as any)?.guid;
  const [forgottenPwd, setForgottenPwd] = useState<ForgottenPwd>();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmForgottenPwdModel>({
    resolver: yupResolver(confirmForgottenPwdSchema),
  });

  const onSubmit = async (data: ConfirmForgottenPwdModel) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Login}/forgotten-password/validate`;
    const body = {
      login: forgottenPwd?.login,
      resetToken: forgottenPwd?.token,
      pwd: data.pwd,
      confirmPwd: data.confirmPwd,
    };
    setIsLoading(true);

    const response = await postApi(url,body);

    if (response?.ok) {
      addToast(LoginLabels.ChangingPwdSuccess, { appearance: "success", autoDismiss: true });
      history(Routes.LOGIN);
      setIsLoading(false);
    } else {
      addToast(LoginLabels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const fetchData = async (): Promise<ForgottenPwd|null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Login}/forgotten-password/${guid}`;
  
    return await fetchApi<ForgottenPwd>(url);
  };

  useEffect(() => {
    fetchData().then((response) => {
      if(response){
        setForgottenPwd(response);
      }
    });
  }, []);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {LoginLabels.PleaseEnterNewPassword}
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6"
          >
            <div>
              <label
                htmlFor="userName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {LoginLabels.Password}
              </label>
              <div className="mt-2">
                <input
                  {...register("pwd")}
                  className="block w-full rounded-md border-0 py-1.5
                   text-gray-900 shadow-sm ring-1 
                   ring-inset ring-gray-300
                    placeholder:text-gray-400 focus:ring-2 
                    focus:ring-inset focus:ring-indigo-600 
                    sm:text-sm sm:leading-6"
                />
                <p className="text-red-600 text-sm font-medium">
                  {errors.pwd?.message}
                </p>
              </div>
            </div>

            <div>
              <label
                htmlFor="confirmPwd"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {LoginLabels.ConfirmPassword}
              </label>
              <div className="mt-2">
                <input
                  {...register("confirmPwd")}
                  className="block w-full rounded-md border-0 py-1.5
                   text-gray-900 shadow-sm ring-1 
                   ring-inset ring-gray-300
                    placeholder:text-gray-400 focus:ring-2 
                    focus:ring-inset focus:ring-indigo-600 
                    sm:text-sm sm:leading-6"
                />
                <p className="text-red-600 text-sm font-medium">
                  {errors.confirmPwd?.message}
                </p>
              </div>
            </div>

            <div>
              {isLoading && (
                <div className="flex items-end justify-center">
                  <BeeLoader />
                </div>
              )}
              {!isLoading && (
                <>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-purple-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                  >
                    {LoginLabels.Valid}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmForgottenPassword;
