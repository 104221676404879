import React, { useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import { getHeaders, getFileHeaders } from "../../common/utils";
import Resource from "./resource";
import BeeLoader from "../../layout/loader";
import { useToasts } from "react-toast-notifications";
import "./styles.scss";
import { fetchApi } from "../../common/api";
import PhotoIcon from "@heroicons/react/24/outline/PhotoIcon";

const Labels = {
  Title: "Gestion des bulletins",
  Error: "Une erreur est survenue",
  EmailSender: "E-mail de l'expéditeur",
  EmailObject: "Objet du mail",
  EmailAdult: "Email des classes adulte",
  EmailChild: "Email des classes Enfants/ados",
  Logo: "Logo",
  Signature: "Signature",
};

enum ResourceKey {
  mailAdult,
  mailChildren,
  emailProvenance,
  emailObject,
  logo,
  signature,
}

const Bulletins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>();
  const [logo, setLogo] = useState<Blob>();
  const [signature, setSignature] = useState<Blob>();
  const { addToast } = useToasts();

  const fetchResources = async (): Promise<Resource[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/resources`;
    return await fetchApi<Resource[]>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchResources().then((response) => {
      if (response) {
        setResources(response);
      }

      setIsLoading(false);
    });
  }, []);

  const onChangeLogo = (e: any) => {
    setLogo(e.target.files[0]);
  };
  const onChangeSignature = (e: any) => {
    setSignature(e.target.files[0]);
  };

  const saveImages = async (key: ResourceKey, file: any) => {
    let formData = new FormData();
    formData.append("Type", key.toString());
    formData.append("FormFile", file);

    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/resources/files`;

    setIsLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: getFileHeaders(),
      body: formData,
    });

    if (response.ok) {
      const message = `Enregistrement bien effectué.`;
      addToast(message, { appearance: "success", autoDismiss: true });
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const getLogoUrl = () => (logo ? URL.createObjectURL(logo) : "");
  const getSignatureUrl = () =>
    signature ? URL.createObjectURL(signature) : "";

  return (
    <>
      {isLoading && (
        <>
          <div className="flex justify-center">
            <BeeLoader />
          </div>
        </>
      )}
      {!isLoading && (
        <>
          <form>
            <div className="space-y-12 ml-2">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Labels.Logo}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Une fois le logo enregistré, rafraîchir la page en faisant
                    ctrl + f5
                  </p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <div className="col-span-full">
                    <div className="mt-2 flex justify-center
                           rounded-lg border 
                           border-dashed border-gray-900/25 px-6 py-10
                           pb-5">
                      <div className="text-center">
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Importer le logo</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                              onChange={onChangeLogo}
                              accept=".png, .jpg, .jpeg"
                            />
                          </label>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          .png, .jpg, .jpeg
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                onClick={() => saveImages(ResourceKey.logo, logo)}
                type="submit"
                className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
             text-white shadow-sm
              hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sauvegarder le logo
              </button>
            </div>
          </form>
          <br />
          <form>
            <div className="space-y-12">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10  border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Labels.Signature}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Cette image sera utilisée en guise de signature sur les
                    bulletins
                  </p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <div className="col-span-full">
                    <div className="mt-2 flex justify-center 
                    rounded-lg border border-dashed border-gray-900/25 px-6 py-10
                    pb-5">
                      <div className="text-center">
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-upload1"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Importer la signature</span>
                            <input
                              id="file-upload1"
                              name="file-upload1"
                              type="file"
                              className="sr-only"
                              onChange={onChangeSignature}
                              accept=".png, .jpg, .jpeg"
                            />
                          </label>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          .png, .jpg, .jpeg
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                onClick={() => saveImages(ResourceKey.signature, signature)}
                type="submit"
                className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
             text-white shadow-sm
              hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sauvegarder la signature
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );

  // return <div className='row'>
  //     <div className="settings-title">
  //         <h4>{Labels.Title}</h4>
  //     </div>
  //     {
  //         isLoading && <BeeLoader />
  //     }

  //     {
  //         !isLoading && resources && <div className='row settings'>

  //             <div className='row mt-3'>
  //                 <label className='bold'>Une fois le logo enregistré, rafraîchir la page en faisant ctrl + f5</label>
  //                 <br />
  //                 <br />
  //                 <div className='settings-header'>
  //                     <label className='bold'>{Labels.Logo} </label>
  //                 </div>
  //                 <div className='row' >
  //                     <div className='col-10'>
  //                         <div className='row'>
  //                             <div className='col-8'>
  //                                 <input type='file' className='form-control' onChange={onChangeLogo} accept=".png, .jpg, .jpeg" />
  //                             </div>
  //                             <div className='col-4'>
  //                                 <img src={getLogoUrl()} height={'50px'} width={'50px'} />
  //                             </div>
  //                         </div>
  //                     </div>
  //                     <div className='col-1'>
  //                         <button className='btn' onClick={() => saveImages(ResourceKey.logo, logo)}><i className='icon-save'></i></button>
  //                     </div>
  //                 </div>
  //             </div>

  //             <div className='row mt-3'>
  //                 <div className='settings-header'>
  //                     <label className='bold'>{Labels.Signature} </label>
  //                 </div>
  //                 <div className='row' >
  //                     <div className='col-10'>
  //                         <div className='row'>
  //                             <div className='col-8'>
  //                                 <input type='file' className='form-control' onChange={onChangeSignature} accept=".png, .jpg, .jpeg" />
  //                             </div>
  //                             <div className='col-4'>
  //                                 <img src={getSignatureUrl()} height={'50px'} width={'50px'} />
  //                             </div>
  //                         </div>
  //                     </div>
  //                     <div className='col-1'>
  //                         <button className='btn' onClick={() => saveImages(ResourceKey.signature, signature)}><i className='icon-save'></i></button>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     }

  // </div>
};

export default Bulletins;
