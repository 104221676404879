import React, { Fragment, useEffect, useState } from "react";
import { fetchApi } from "../../common/api";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import students from "../students";
import { ClassContent, StudentReport, Subject } from "./class";
import TrimestresHeader from "./trimestreHeader";

interface SummaryProps {
  classId: number;
}

const Summary = ({ classId }: SummaryProps) => {
  const [yearSetting, setYearSetting] = useState<number | undefined>(undefined);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState<ClassContent>();

  const fetchSubjects = async (): Promise<Subject[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${classId}/subjects`;
    return await fetchApi<Subject[]>(url);
  };

  const getClassContent = async (): Promise<ClassContent | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/content/${classId}`;
    if (yearSetting) {
      url += `/${yearSetting}`;
    }
    const result = await fetchApi<ClassContent>(url);
    if (result === null) {
      return null;
    } else return result;
  };
  const fetchCurrentYearSetting = async (): Promise<number | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/current-year-setting`;
    return await fetchApi<number>(url);
  };

  useEffect(() => {
    if (classId) {
      setIsLoading(true);
      getClassContent().then((response) => {
        if (response !== null) {
          setContent(response);
        }
        setIsLoading(false);
      });
      fetchSubjects().then((response) => {
        if (response) {
          setSubjects(response);
        }
      });
    }
    if (yearSetting === undefined) {
      fetchCurrentYearSetting().then((response) => {
        if (response !== null) {
          setYearSetting(response);
        }
      });
    }
  }, [classId, yearSetting]);
  const setYearsSettings = (value: number) => {
    setYearSetting(value);
  };
  const setYears = () => {
    setYearSetting(undefined);
  };
  return (
    <Fragment>
      {isLoading && <BeeLoader />}     
      {!isLoading && students.length > 0 && (
        <div className="row">
          <TrimestresHeader
            chooseTrimestre={setYearsSettings}
            currentSetting={yearSetting}
          />
        </div>
      )}
      {!isLoading && students.length > 0 && (
        <>
          <div className="flow-root mb-5">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        <div style={{ textAlign: "left" }}>
                          Nom
                          <div>{content?.students.length} élèves(s)</div>
                        </div>
                      </th>
                      <th     scope="col"
                      className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          style={{ width: "60px" }}>Nb Absences</th>
                      {subjects?.map((s) => {
                        return (
                          <th
                          scope="col"
                          className="py-3.5 pl-2 pr-3 text-sm 
                          font-semibold text-gray-900 sm:pl-0
                          text-center"
                    
                            style={{
                              backgroundColor: s.color,
                              color: s.penColor,
                              width: "60px",
                            }}
                          >
                            <div>{s.alias}</div>
                            <div>{s.coefficient}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bold pl-2 text-left">Total</td>
                      <td></td>
                      {subjects?.map((subject) => {
                        const student = content?.students[0];
                        return (
                          <td key={subject.id} className="whitespace-nowrap  pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {student?.notesCount[subject.id].total}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <div>&nbsp;</div>
                    </tr>
                    {content?.students.map((s) => {
                      return (
                        <tr key={s.id} className="odd:bg-gray-200">
                          <td className="whitespace-nowrap  pl-2 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0">{`${s.lastName} ${s.firstName}`}</td>
                          <td className="whitespace-nowrap  pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{s.absencesCount}</td>
                          {subjects?.map((subject) => {
                            return <td>{s.notesCount[subject.id].element}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Summary;
