import React from "react";
import { classNames, isAdmin } from "../../common/utils";
import Select from "../components/select";
import Button from "../components/button";

enum Mode {
  Note,
  Absence,
  Results,
  Reports,
  Students,
  Year,
  Summary,
}

interface ActionButtonsProps {
  notes: () => void;
  averages: () => void;
  results: () => void;
  absences: () => void;
  students: () => void;
  summary: () => void;
  tabSelected: Mode;
}

const Labels = {
  Notes: "Notes",
  Results: "Résultats",
  Averages: "Bulletins",
  Absences: "Assiduité",
  Students: "Elèves",
  Summary: "Aperçu",
};

const ActionButtons = ({
  notes,
  averages,
  results,
  absences,
  students,
  summary,
  tabSelected,
}: ActionButtonsProps) => {
  const getClasses = (mode: Mode): string => {
    let classes = " confirm action-button";
    if (mode === tabSelected) {
      classes = `${classes} active`;
    }
    return classes;
  };
  const links = [
    {
      id: Mode.Absence,
      name: `${Labels.Absences}`,
    },
    {
      id: Mode.Note,
      name: `${Labels.Notes}`,
    },
    {
      id: Mode.Summary,
      name: `${Labels.Summary}`,
    },
    {
      id: Mode.Results,
      name: `${Labels.Results}`,
    },
    {
      id: Mode.Reports,
      name: `${Labels.Averages}`,
    },
    isAdmin() && {
      id: Mode.Students,
      name: `${Labels.Students}`,
    },
  ];

  const onSelectChange = (mode: Mode) => {
    switch (mode) {
      case Mode.Absence:
        absences();
        break;
      case Mode.Note:
        notes();
        break;
      case Mode.Reports:
        averages();
        break;
      case Mode.Summary:
        summary();
        break;
      case Mode.Results:
        results();
        break;
      case Mode.Students:
        students();
        break;
    }
  };

  return (
    <div className="row mb-2">
      <div className="sm:hidden w-full">
        <Select
          data={links}
          onChange={onSelectChange}
          errorLabel=""
          hasError={false}
          label=""
          selectLabel="Choisissez un module"
          value={tabSelected}
        />
      </div>
      <div className="hidden sm:block">
        <div className="flex flex-row justify-center">
          <nav aria-label="Tabs" className="flex space-x-4 ">
            <a
              href="#"
              onClick={absences}
              className={classNames(
                tabSelected === Mode.Absence
                  ? "text-purple-500 "
                  : "text-gray-500 hover:text-purple-500",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {" "}
              {Labels.Absences}
            </a>
            <a
              href="#"
              onClick={notes}
              className={classNames(
                tabSelected === Mode.Note
                  ? "text-purple-500 "
                  : "text-gray-500 hover:text-purple-500",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {" "}
              {Labels.Notes}
            </a>
            <a
              href="#"
              onClick={summary}
              className={classNames(
                tabSelected === Mode.Summary
                  ? "text-purple-500 "
                  : "text-gray-500 hover:text-purple-500",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {" "}
              {Labels.Summary}
            </a>
            <a
              href="#"
              onClick={results}
              className={classNames(
                tabSelected === Mode.Results
                  ? "text-purple-500 "
                  : "text-gray-500 hover:text-purple-500",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {" "}
              {Labels.Results}
            </a>
            <a
              href="#"
              onClick={averages}
              className={classNames(
                tabSelected === Mode.Reports
                  ? "text-purple-500 "
                  : "text-gray-500 hover:text-purple-500",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {" "}
              {Labels.Averages}
            </a>
            {!isAdmin() && (
              <a
                href="#"
                onClick={students}
                className={classNames(
                  tabSelected === Mode.Students
                    ? "text-purple-500 "
                    : "text-gray-500 hover:text-purple-500",
                  "rounded-md px-3 py-2 text-sm font-medium"
                )}
              >
                {" "}
                {Labels.Students}
              </a>
            )}
          </nav>
          
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
