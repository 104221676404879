import { classNames } from "../../common/utils";

interface SideBarNavigationProps {
  data: Element[];
  onClick: (id: number) => void;
  selectedValue: number;
}
export interface Element {
  id: number;
  name: string;
}
const SideBarNavigation = ({
  data,
  onClick,
  selectedValue,
}: SideBarNavigationProps) => {
  return (
    <>
      <div className="sm:hidden w-full">
        <select
          id="tabs"
          name="tabs"
          value={selectedValue}
          onChange={(e) => onClick(parseInt(e.currentTarget.value))}
          className="block w-full rounded-md border-gray-300
           focus:border-purple-500 focus:ring-purple-500"
        >
          <option value={-1}>---</option>
          {data.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Sidebar" className="flex flex-1 flex-col">
          <ul role="list" className="-mx-2 space-y-1">
            {data.map((item) => (
              <li key={item.name}>
                <a
                  onClick={() => onClick(item.id)}
                  className={classNames(
                    selectedValue === item.id
                      ? "bg-opacity-55"
                      : "bg-opacity-100",
                    "text-white",
                    "bg-blue-500",
                    "hover:bg-orange-500 hover:bg-opacity-75 hover:text-white",
                    "group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 ",

                    "hover:no-underline cursor-pointer"
                  )}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SideBarNavigation;
