import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiUrls from "../../common/urls";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import BeeLoader from "../../layout/loader";
import { useParams } from "react-router-dom";
import Subject, { LevelCoeff } from "./subject";
import { SketchPicker } from "react-color";
import { Level } from "../levels/level";
import { getHeaders } from "../../common/utils";
import Pole from "../poles/pole";
import { fetchApi } from "../../common/api";
import InputLabelValidation from "../components/inputLabelValidation";
import Select from "../components/select";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";

const Labels = {
  IsRequired: "Ce champ est requis",
  CorrectForm: "Veuillez corriger les erreurs",
  Error: "Une erreur est survenue",
  Subject: "Détail matière",
  SelectPole: "Pôles",
};

const AddSubject = () => {
  const id = (useParams() as any)?.id;
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [levelCoeffs, setLevelCoeffs] = useState<LevelCoeff[]>([]);
  const [alias, setAlias] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [levels, setLevels] = useState<Level[]>([]);
  const [penColor, setPenColor] = useState("");
  const [poles, setPoles] = useState<Pole[]>([]);
  const [poleId, setPoleId] = useState(0);
  const [traduction, setTraduction] = useState("");

  const { addToast } = useToasts();
  const history = useNavigate();

  const validate = (): boolean => {
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!name) {
      errorsTemp["name"] = Labels.IsRequired;
    }

    isValid = Object.entries(errors).length === 0;
    setErrors(errorsTemp);
    return isValid;
  };

  const onSubmit = async () => {
    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Subjects}`;

    const body = {
      name: name,
      color: color,
      id: parseInt(id),
      alias: alias,
      penColor: penColor,
      levelCoeffs: levelCoeffs,
      poleId: poleId,
      traduction: traduction,
    };

    setisLoading(true);
    const type = id ? "PUT" : "POST";
    const response = await fetch(url, {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = !id
        ? `la matière ${name} est créée`
        : `la matière ${name} est mise à jour`;

      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.MATIERES);
      setisLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setisLoading(false);
    }
  };

  const onLevelChecked = (level: number) => {
    if (!level) return false;
    let copies: LevelCoeff[] = [...levelCoeffs];

    const levelCoeff = {
      level: level,
      coefficient: 1,
    };
    const find = copies.find((c) => c.level === level);
    if (!find) {
      copies.push(levelCoeff);
    } else {
      copies.splice(copies.indexOf(find), 1);
    }
    setLevelCoeffs(copies);
  };

  const onCoeffChange = (level: number, value: number) => {
    if (!level) return false;
    let copies: LevelCoeff[] = [...levelCoeffs];
    let levelCoeff = copies.find((c) => c.level === level);
    if (levelCoeff) {
      levelCoeff.coefficient = value;
      setLevelCoeffs(copies);
    }
  };

  const fetchSubject = async (): Promise<Subject | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Subjects}/${id}`;
    return await fetchApi<Subject>(url);
  };

  const fetchLevels = async (): Promise<Level[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Level}`;
    return await fetchApi<Level[]>(url);
  };

  const fetchPoles = async (): Promise<Pole[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Pole}`;
    return await fetchApi<Pole[]>(url);
  };

  useEffect(() => {
    if (id) {
      setisLoading(true);
      fetchSubject().then((response) => {
        if (response) {
          setName(response.name);
          setAlias(response.alias);
          setLevelCoeffs(response.levelCoeffs);
          setPoleId(response.poleId);
          setTraduction(response.traduction);
          if (response.color) {
            setColor(response.color);
          }
          if (response.penColor) {
            setPenColor(response.penColor);
          }
        }

        setisLoading(false);
      });
    }
    fetchPoles().then((response) => {
      if (response) {
        setPoles(response);
      }
    });
    fetchLevels().then((response) => {
      if (response) {
        setLevels(response);
      }
    });
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}
      {!isLoading && (
        <>
         <h2 className="text-md font-bold text-gray-900 mb-2">
           Edition d'une matière
          </h2>
          <form onSubmit={onSubmit}>
            <div className="space-y-12">
              <div className="mt-8 sm:flex-col flex-row md:flex">
                <div className="mr-5">
                  <InputLabelValidation
                    errorLabel={errors["name"]}
                    hasError={errors["name"] !== undefined}
                    inputValue={name}
                    onChangeValue={(e) => setName(e.currentTarget.value)}
                    label="Nom"
                  />
                  <InputLabelValidation
                    errorLabel={""}
                    hasError={false}
                    inputValue={alias}
                    onChangeValue={(e) => setAlias(e.currentTarget.value)}
                    label="Alias"
                  />
                  <InputLabelValidation
                    errorLabel={""}
                    hasError={false}
                    inputValue={traduction}
                    onChangeValue={(e) => setTraduction(e.currentTarget.value)}
                    label="Traduction"
                  />
                  <Select
                    hasError={false}
                    errorLabel=""
                    label="Pôles"
                    data={poles}
                    value={poleId}
                    onChange={setPoleId}
                    selectLabel="Choisissez un pôle"
                  />
                </div>
                <div className="mt-2">
                  <div className="form-group ">
                    <div className="row">
                      <div className="col-md-6">
                        <SketchPicker
                          color={color}
                          onChangeComplete={(color) => setColor(color.hex)}
                        />
                      </div>
                      <div className="col-md-6">
                        <SketchPicker
                          color={penColor}
                          onChangeComplete={(color) => setPenColor(color.hex)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div style={{ color: penColor, backgroundColor: color }}>
                      Aperçu
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Apparaîtra dans le niveau
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Niveau
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Coeff
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {levels.map((level) => {
                          const coeff =
                            levelCoeffs &&
                            levelCoeffs.find((lc) => lc.level === level.id)
                              ?.coefficient;
                          const readonly =
                            levelCoeffs &&
                            !levelCoeffs.find((lc) => lc.level === level.id);
                          return (
                            <tr key={level.id} className="odd:bg-gray-200">
                              <td className="whitespace-nowrap pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    levelCoeffs &&
                                    levelCoeffs.filter(
                                      (i) => i.level === level.id
                                    ).length > 0
                                  }
                                  onChange={() => {}}
                                  onClick={(e) => onLevelChecked(level.id)}
                                />
                              </td>
                              <td className="whitespace-nowrap py-1 pl-1  text-sm font-medium text-gray-900">
                                {level.name}
                              </td>
                              <td className="whitespace-nowrap py-1 pl-1 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                <input
                                  type="number"
                                  step="0.1"
                                  readOnly={readonly}
                                  disabled={readonly}
                                  value={coeff}
                                  className="w-[3rem] h-5"
                                  onChange={(e) => {
                                    onCoeffChange(
                                      level.id,
                                      parseFloat(e.currentTarget.value)
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <CancelButton url={Routes.MATIERES} />
              <SubmitButton />
            </div>
          </form>
        </>
      )}
      {/* <div className="row">

            <div className="col-md-12">
                <h4>{Labels.Subject}</h4>
                <form className="form-horizontal" method="post">
                    <div className='row'>
                        <div className='col-xs-12 col-md-2'>
                            <div className="form-group ">
                                <div className='form-floating' >
                                    <input type="text" placeholder="Nom" className="form-control" required
                                        value={name}
                                        onChange={(e) => setName(e.currentTarget.value)} />
                                    <label>Nom</label>
                                    {
                                        errors['name'] &&
                                        <span className='error'>{errors['name']}</span>
                                    }
                                </div>

                            </div>
                            <div className="form-group ">
                                <div className='form-floating' >
                                    <input type="text" placeholder="Alias" className="form-control"
                                        value={alias} maxLength={4}
                                        onChange={(e) => setAlias(e.currentTarget.value)} />
                                    <label>Alias</label>
                                </div>


                            </div>
                            <div className="form-group ">
                                <div className='form-floating' >
                                    <input type="text" placeholder="Traduction" className="form-control"
                                        value={traduction}
                                        onChange={(e) => setTraduction(e.currentTarget.value)} />
                                    <label>Traduction</label>
                                </div>


                            </div>
                            <div className="form-group ">
                                <div className='form-floating' >
                                    <select className='form-select' value={poleId}
                                        onChange={(e) => setPoleId(parseInt(e.currentTarget.value))}>
                                        <option>{Labels.SelectPole}</option>
                                        {
                                            poles && poles.map((t: Pole) => {
                                                return <option key={t.id.toString()} value={t.id}>
                                                    {t.name}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <label>Pôles</label>
                                </div>


                            </div>
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <div className="form-group ">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <SketchPicker color={color} onChangeComplete={(color) => setColor(color.hex)} />
                                    </div>
                                    <div className='col-md-6'>
                                        <SketchPicker color={penColor} onChangeComplete={(color) => setPenColor(color.hex)} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div style={{ color: penColor, backgroundColor: color }}>
                                    Aperçu
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group ">
                                {
                                    levels && <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Apparaîtra dans le niveau
                                                </th>
                                                <th>
                                                    Niveau
                                                </th>
                                                <th>
                                                    Coeff
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                levels.map(level => {
                                                    const coeff = levelCoeffs && levelCoeffs.find(lc => lc.level === level.id)?.coefficient;
                                                    const readonly = levelCoeffs && !levelCoeffs.find(lc => lc.level === level.id);
                                                    return <tr>
                                                        <td>
                                                            <input type="checkbox"
                                                                checked={levelCoeffs && levelCoeffs.filter(i => i.level === level.id).length > 0}
                                                                onChange={() => { }}
                                                                onClick={(e) => onLevelChecked(level.id)} />
                                                        </td>
                                                        <td>{level.name}</td>
                                                        <td>
                                                            <input type="number" step="0.1" readOnly={readonly} disabled={readonly} value={coeff}
                                                                onChange={(e) => { onCoeffChange(level.id, parseFloat(e.currentTarget.value)) }} />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }

                            </div>
                        </div>

                    </div>

                    <div className="form-group">
                        <div className="text-center">
                            {
                                isLoading && <BeeLoader />
                            }
                            {
                                !isLoading && <div className='mt-5'>
                                    <button type="button" onClick={onSubmit} className="btn mr-5">   <i className='icon-save'></i> </button>
                                    <a className='btn' href={Routes.MATIERES}><i className='icon-undo'></i></a>
                                </div>
                            }


                        </div>
                    </div>
                </form>
            </div>

        </div> */}
    </div>
  );
};

export default AddSubject;
