import React, { Fragment, useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import {
  createDownloadLink,
  getHeaders,
  getHeadersPdf,
  isAdmin,
} from "../../common/utils";
import BeeLoader from "../../layout/loader";

import { Student } from "./student";

import "./styles.scss";
import * as Routes from "../../routing/routes";

import { useNavigate } from "react-router-dom";
import { stat } from "fs";
import { fetchApi, fetchPdf } from "../../common/api";
import StudentCard from "./studentCard";
import SearchBar from "../components/searchBar";
import Button from "../components/button";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import Select from "../components/select";

interface StudentsProps {
  classeId?: number;
}

enum StudentStatus {
  Undefined = -1,
  Registered = 0,
  Unregistered = 1,
  Excluded = 2,
}

const statuses = [
  {
    id: 0,
    name: "Inscrit",
  },
  {
    id: 1,
    name: "Désinscrit",
  },
  {
    id: 2,
    name: "Exclu",
  },
];

const Labels = {
  NoEntries: "Aucune élève à afficher"
};


const Students = ({ classeId }: StudentsProps) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownLoading, setIsDownLoading] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<StudentStatus | null>(
    null
  );
  const history = useNavigate();

  const fetchStudents = async (): Promise<Student[] | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Students}`;
    if (classeId) {
      url = `${url}/classe/${classeId}`;
    }
    return await fetchApi<Student[]>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStudents().then((response) => {
      if (response) {
        setStudents(response);
        setFilteredStudents(response);
        if (response.length > 0) {
          setCurrentStudentId(response[0].id);
        }
      }
      setIsLoading(false);
    });
  }, [classeId]);

  const downloadStudents = async () => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Students}/download-all`;

    if (classeId) {
      url = `${url}/classe/${classeId}`;
    }
    setIsDownLoading(true);
    const blob = await fetchPdf(url);
    setIsDownLoading(false);
    if (!blob) return;
    createDownloadLink(blob, "Eleves.zip");
  };

  const getValueName = (status: number): string => {
    if (status === 0) return "Inscrit";
    if (status === 1) return "Désinscrit";
    if (status === 2) return "Exclu";
    return "";
  };

  const onSearch = (value: string) => {
    const elements = students.filter(
      (s) =>
        s.address?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.birthDateLabel
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.cellular?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.city?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.classe?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.email?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.firstName?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.lastName?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        s.legalResponsible
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.legalResponsible1Cellular
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.legalResponsible1Email
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.legalResponsible1FullName
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.legalResponsible2Cellular
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.legalResponsible2Email
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.legalResponsible2FullName
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        getValueName(s.status)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        s.zipCode?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );

    setFilteredStudents(elements);
  };

  const filterBy = (status: StudentStatus) => {
    if (status === StudentStatus.Undefined) {
      setFilteredStudents(students);
    } else {
      const elements = students.filter((s) => s.status === status);
      setFilteredStudents(elements);
    }
  };

  return (
    <div className="row">
      {
        !isLoading && students.length > 0 &&
        <h2 className="text-md font-bold text-gray-900">Liste des élèves</h2>
      }

      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}
      {
        !isLoading && students.length === 0 && <>
          <h2 className="text-md font-bold text-gray-900">{Labels.NoEntries}</h2>
        </>
      }
      {!isLoading && students.length > 0 && (
        <>
          {
            <div className="sm:flex-col md:flex flex-row">
              <div className="sm:flex-col w-full md:flex flex-row">
                <div className="relative mt-2 mb-2 mr-2 flex justify-left">
                  <Button
                    label={isDownLoading ? "...En cours" : "Télécharger"}
                    onClick={downloadStudents}
                  />
                </div>
                <div className="relative block md:hidden">
                  <Select
                    hasError={false}
                    label=""
                    errorLabel=""
                    data={statuses}
                    onChange={(e) => filterBy(e)}
                    selectLabel="Choisissez un statut"
                  />
                </div>

                <div className="hidden w-full md:flex flex-row justify-end">
                  <div className="mr-2">
                    <Select
                      hasError={false}
                      label=""
                      errorLabel=""
                      data={statuses}
                      onChange={(e) => filterBy(e)}
                      selectLabel="Choisissez un statut"
                    />
                  </div>

                  <SearchBar onSearch={onSearch} />
                  <div className="relative mt-2 mb-2 ml-2 mr-2">
                    <Button label="Ajouter" onClick={() => history("/eleve")} />
                  </div>
                </div>
                <div className="w-full flex flex-row justify-left md:hidden">
                  <SearchBar onSearch={onSearch} />
                  <div className="relative mt-2 mb-2 ml-2 mr-2">
                    <Button label="Ajouter" onClick={() => history("/eleve")} />
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="mt-2">
            {filteredStudents.length === 0 && (
              <>
                <h2 className="text-md font-bold text-gray-900">
                  Aucun résultat
                </h2>
              </>
            )}
            <ul
              role="list"
              className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
            >
              {filteredStudents.map((student) => (
                <li
                  key={student.id}
                  className=" col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                >
                  <StudentCard student={student} />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Students;
