import { Fragment, useState } from "react";
import LocalStorageKey from "../../common/localStorage";
import ApiUrls from "../../common/urls";
import * as Routes from "../../routing/routes";
import BeeLoader from "../../layout/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginLabels, LoginModel, loginSchema } from "./loginSchema";
import { postApi } from "../../common/api";
import SubmitButton from "../components/submitButton";

interface AuthenticateResponse {
  token: string;
}

const Login = () => {
  const [wrongConnect, setWrongConnect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginModel>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data: LoginModel) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Login}`;
    const body = {
      login: data.userName,
      pwd: data.pwd,
    };
    setIsLoading(true);

    const response = await postApi(url,body);
    const authResponse = (await response?.json()) as AuthenticateResponse;

    if (authResponse.token) {
      localStorage.setItem(LocalStorageKey.Auth, authResponse.token);

      if (localStorage.getItem(LocalStorageKey.Notes) === "null") {
        localStorage.setItem(LocalStorageKey.Notes, JSON.stringify([{}]));
      }
      if (localStorage.getItem(LocalStorageKey.Comments) === "null") {
        localStorage.setItem(LocalStorageKey.Comments, JSON.stringify([{}]));
      }

      window.location.href = Routes.CLASSES;
    } else {
      setWrongConnect(true);
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {LoginLabels.PleaseConnect}
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          {wrongConnect && (
            <div className="text-red-600 text-sm font-medium">
              {LoginLabels.LoginError}
            </div>
          )}

          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-6"
            >
              <div>
                <label
                  htmlFor="userName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {LoginLabels.Login}
                </label>
                <div className="mt-2">
                  <input
                    {...register("userName")}
                    className="block w-full rounded-md border-0 py-1.5
                       text-gray-900 shadow-sm ring-1 
                       ring-inset ring-gray-300
                        placeholder:text-gray-400 focus:ring-2 
                        focus:ring-inset focus:ring-indigo-600 
                        sm:text-sm sm:leading-6"
                  />
                  <p className="text-red-600 text-sm font-medium">
                    {errors.userName?.message}
                  </p>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {LoginLabels.Password}
                </label>
                <div className="mt-2">
                  <input
                    {...register("pwd")}
                    type="password"
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p className="text-red-600 text-sm font-medium">
                    {errors.pwd?.message}
                  </p>
                </div>
              </div>

              <div className="flex items-end justify-end">
                <div className="text-sm leading-6">
                  <a
                    href="/mot-de-passe-oublie"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    {LoginLabels.ForgottenPassword}
                  </a>
                </div>
              </div>

              <div>
                {isLoading && (
                  <div className="flex items-end justify-center">
                    <BeeLoader />
                  </div>
                )}
                {!isLoading && (
                  <>
                    <SubmitButton label={LoginLabels.Connect} />
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
