import { getHeaders, getHeadersPdf } from "./utils";

export const postApi = async (url:string, body:any): Promise<Response|null>=>{
    try{
        const response = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(body)
        });
        return response;
    }
    catch(e:any){
        console.log(e);
    }
    return null;
}

export  const fetchApi= async <T>(url:string): Promise<T|null>=>{
    try{
        const response = await fetch(url, {
            method: 'GET',
            headers: getHeaders()
        });
        
        return await response.json() as T;
    }
    catch(e:any){
        console.log(e);
    }

    return null;
}

export const fetchPdf = async (url:string): Promise<Blob|null>=>{
    try{
        const response = await fetch(url, {
            method: 'GET',
            headers:  getHeadersPdf()
        });
        
        return await response.blob();
    }
    catch(e:any){
        console.log(e);
    }
    return null;
}

