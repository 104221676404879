import React, { useEffect, useState, Fragment } from 'react';
import { YearStudentReport, Subject } from './class';
import ApiUrls from '../../common/urls';
import BeeLoader from '../../layout/loader';
import { createDownloadLink, getHeaders } from '../../common/utils';
import { fetchApi, fetchPdf } from '../../common/api';

interface YearAveragesProps {
    id: number
}

interface ResultColumn {
    id: number,
    name: string,
    coeff: number
}

interface PassageStudent {
    id: number,
    pas: boolean
}

const Labels = {
    LastName: 'Nom',
    FirstName: 'Prénom',
    T1: 'T1',
    T2: 'T2',
    T3: 'T3',
    Pas: 'Pas',
    Red: 'Red',
    Moy: 'Moy gén.'
}


const YearAverages = ({ id }: YearAveragesProps) => {

    const [content, setContent] = useState<YearStudentReport[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<ResultColumn[]>();
    const [sortByName, setSortByName] = useState(true);
    const [passStudents, setPassStudents] = useState<PassageStudent[]>([]);
    const [download, setDownload] = useState(false);

    const fetchYearAverages = async (): Promise<YearStudentReport[] | null> => {
        const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/year/${id}`;

        return await fetchApi<YearStudentReport[]>(url);

    }

    useEffect(() => {
        setIsLoading(true);
        fetchYearAverages()
            .then(response => {
                if (response) {
                    setContent(response);

                    setColumns(getColumns(response));
                    setPassStudents(getPassages(response));
                }
                setIsLoading(false);
            });
    }, [id])

    const getPassages = (response: YearStudentReport[]) => {
        const passages = response.map(r => { return { id: r.id, pas: r.passage } });
        return passages;
    }

    const getColumns = (response: YearStudentReport[]): ResultColumn[] | undefined => {
        if (!response || response.length === 0) {
            return undefined
        }
        const reports = response[0];
        const results = reports.subjects.map((s: Subject) => {
            return { id: s.id, name: s.alias, coeff: s.coefficient }
        });
        return results;
    }

    const constructHeaders = (): JSX.Element[] | undefined => {
        const elements = columns?.map((c: ResultColumn) => {
            return <th className='yearaverage-width'>
                <div>
                    {
                        c.name
                    }
                </div>
                <div>
                    {
                        c.coeff
                    }
                </div>
            </th>

        }
        )

        return elements;
    }

    const onSortByName = () => {
        const copies = [...content];
        const sortedClasses = sortByName ? copies.sort((a, b) => a.fullName.localeCompare(b.fullName)) : copies.sort((a, b) => b.fullName.localeCompare(a.fullName));
        setSortByName(!sortByName);
        setContent(sortedClasses);
    }

    const getNoteContents = (report: YearStudentReport): JSX.Element[] | undefined => {

        const elements = columns && columns.map((c: ResultColumn) => {
            return <td className='yearaverage-width'>
                {
                    report.notes.map((n) => {
                        if (n.subjectId === c.id) {
                            const value = n.value >= 0 ? n.value : undefined;
                            return value;
                        }
                    })
                }
            </td>
        });

        return elements
    }

    const getAverageContents = (): JSX.Element[] | undefined => {

        const report = content[0];
        const elements = columns && columns.map((c: ResultColumn) => {
            return <td className='yearaverage-width bold'>
                {
                    report.notes.map((n) => {
                        if (n.subjectId === c.id) {
                            const value = n.classeAverage >= 0 ? n.classeAverage : undefined;
                            return value;
                        }
                    })
                }
            </td>
        });

        return elements
    }

    const getContents = () => {
        const contents = content.map((report: YearStudentReport) => {

            const student = passStudents.find(p => p.id === report.id);
            let passClass = '';
            let redClass = '';
            let redChecked = false;
            let passChecked = false;
            if (student) {
                passClass = student.pas === true ? '#66bb6a' : '';
                redClass = student.pas === false ? '#ef5350' : '';
                redChecked = student.pas === false;
                passChecked = student.pas === true;
            }

            return <tr className='d-flex' key={report.id}>
                <td className='note-header' style={{ textAlign: 'left' }}>
                    {
                        report.fullName
                    }
                </td>
                <td className='yearaverage-width' style={{ border: passClass === '' ? '' : `1px solid ${passClass}` }}>
                    <input type="checkbox" checked={passChecked}
                        onChange={e => checkBoxClick(report.id, true, e.currentTarget.checked)} />
                </td>
                <td className='yearaverage-width' style={{ border: redClass === '' ? '' : `1px solid ${redClass}` }}>
                    <input type="checkbox" checked={redChecked}
                        onChange={e => checkBoxClick(report.id, false, e.currentTarget.checked)} />
                </td>
                <td className='yearaverage-width bold'>
                    {report.averageT1 > -1 ? report.averageT1 : undefined}
                </td>
                <td className='yearaverage-width bold'>
                    {report.averageT2 > -1 ? report.averageT2 : undefined}
                </td>
                <td className='yearaverage-width bold'>
                    {report.averageT3 > -1 ? report.averageT3 : undefined}
                </td>
                <td className='yearaverage-width bold'>
                    {report.annualAverage > -1 ? report.annualAverage : undefined}
                </td>
                {

                    getNoteContents(report)
                }
            </tr>
        });
        const classeAverage = <tr className='d-flex'>
            <td className='note-header bold text-align-left'>Classe</td>
            <td className='yearaverage-width'>
            </td>
            <td className='yearaverage-width'>
            </td>
            <td className='yearaverage-width bold'>
                {
                    content[0].classAverageT1 > -1 ? content[0].classAverageT1 : undefined
                }
            </td>
            <td className='yearaverage-width bold'>
                {
                    content[0].classAverageT2 > -1 ? content[0].classAverageT2 : undefined
                }
            </td>
            <td className='yearaverage-width bold'>
                {
                    content[0].classAverageT3 > -1 ? content[0].classAverageT3 : undefined
                }
            </td>
            <td className='yearaverage-width bold'>
                {
                     undefined
                }
            </td>
            {
                getAverageContents()
            }
        </tr>
        const result = <Fragment>
            {
                contents
            }
            <tr className='blank_row'>&nbsp;</tr>
            {
                classeAverage
            }
        </Fragment>

        return result;
    }

    const checkBoxClick = async (id: number, val: boolean, checked: boolean) => {
        const copies = [...passStudents];
        let elt = copies.find(e => e.id === id);

        if (elt && !checked) {
            copies.splice(copies.findIndex(e => e.id === id), 1);
            setPassStudents(copies);
            return;
        }

        if (elt) {
            elt.pas = checked && val;
        }
        else {
            const student = {
                id,
                pas: val
            }
            copies.push(student);
        }
        setPassStudents(copies);
        const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/year-student-passage`;
        const body = {
            studentId: id,
            passage: val === true ? 0 : 1,
            checked
        }
        const response = await fetch(url, {
            method: 'Post',
            headers: getHeaders(),
            body: JSON.stringify(body)
        });
    }

    const downloadResults = async () => {
        setDownload(true);
        let url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/year-student-passage/${id}?all=false`;

        
        const blob = await fetchPdf(url); 
        if(!blob) return;
        const fileDownloaded =  'Résultats-année.csv';

        createDownloadLink(blob,fileDownloaded);
        
        setDownload(false);
    }

    return <Fragment>
        {
            isLoading && <BeeLoader />
        }
        {
            !isLoading && content.length === 0 &&
            <div>
                Aucun élève dans cette classe
            </div>
        }
        <br />
        {
            !isLoading && content.length > 0 &&
            <div className='row'>
                <div className='col-11'>
                    <table className="table table-responsive-sm table-striped table-sm table-hover table-bordered">
                        <thead>
                            <tr className="d-flex">
                                <th className='note-header' onClick={onSortByName}>
                                    <div style={{ textAlign: 'left' }}>
                                        {Labels.LastName} {' '}
                                        <span className='ml-5'>
                                            {sortByName
                                                ? <i className="icon-sort-by-attributes"></i>
                                                : <i className="icon-sort-by-attributes-alt"></i>
                                            }
                                        </span>
                                        {
                                            content && <div>
                                                {content.length} élève(s)
                                            </div>
                                        }
                                    </div>
                                </th>
                                <th className='yearaverage-width'>
                                    {Labels.Pas}
                                </th>
                                <th className='yearaverage-width'>
                                    {Labels.Red}
                                </th>
                                <th className='yearaverage-width'  >
                                    {Labels.T1}
                                    {' '}
                                </th>
                                <th className='yearaverage-width' >
                                    {Labels.T2}
                                    {' '}

                                </th>
                                <th className='yearaverage-width'  >
                                    {Labels.T3}
                                    {' '}
                                </th>
                                <th className='yearaverage-width'  >
                                    {Labels.Moy}
                                    {' '}
                                </th>
                                {
                                    constructHeaders()
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                getContents()
                            }
                        </tbody>
                    </table>
                </div>
                <div className='col-1'>
                    <div className='col-1'>
                        <div className='col-2 mb-3'>
                            <button className='btn'
                                onClick={() => downloadResults()}
                                type='button'><i title='Télécharger' className="icon-download-alt"></i></button>
                        </div>
                       

                    </div>

                </div>
            </div>

        }

    </Fragment>
}

export default YearAverages;