import React, { Fragment, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { fetchApi } from "../../common/api";
import ApiUrls from "../../common/urls";
import { classNames, getHeaders } from "../../common/utils";
import BeeLoader from "../../layout/loader";
import { Student } from "../students/student";
import TrimestresHeader from "./trimestreHeader";
import Select from "../components/select";

interface AbsencesProps {
  classId: number;
}

const Labels = {
  LastName: "Nom",
  NoStudent: "Aucun élève dans cette classe",
  Absence: "Absence",
  Late: "Retard",
  RecapAbsence: "Récap. Absences",
  RecapLate: "Récap. Retards",
  ChooseDate: "Choisir une date",
  TotalAbsences: "Cumul Absences",
  TotalLates: "Cumul Retards",
  YearBlocked:
    "La période est clôturée. Seuls les administrateurs peuvent ôter la clôture.",
  Error: "Une erreur est survenue",
};

interface StudentCheck {
  studentId: number;
  absent: boolean;
  late: boolean;
  absences: string;
  lates: string;
  totalAbsences: number;
  totalLates: number;
}

interface AvailableDates {
  dates: string[];
  closest: string;
}

interface LockAbsence {
  id?: number;
  locked?: boolean;
}

const Absences = ({ classId }: AbsencesProps) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentChecks, setStudentChecks] = useState<StudentCheck[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [currentDate, setCurrentDate] = useState<string | undefined>();
  const [errors, setErrors] = useState<any>({});
  const [yearSetting, setYearSetting] = useState<number | undefined>();
  const [yearAverage, setYearAverage] = useState(false);
  const [yearSettingLocked, setYearSettingLocked] = useState<boolean | null>(
    false
  );
  const [currentLockAbsence, setCurrentLockAbsence] = useState<LockAbsence>();
  const { addToast } = useToasts();

  const fetchContent = async (): Promise<Student[] | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${classId}/contents-name`;

    if (yearSetting) {
      url = `${url}/${yearSetting}`;
    }

    const result = await fetchApi<Student[]>(url);

    if (result === null) {
      return null;
    } else return result;
  };
  const fetchCurrentYearSetting = async (): Promise<number | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/current-year-setting`;
    return await fetchApi<number>(url);
  };

  const fetchLockAbsence = async (): Promise<LockAbsence | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Absence}/lock-absence/${classId}/${currentDate}`;
    return await fetchApi<LockAbsence>(url);
  };

  const fetchDates = async (): Promise<AvailableDates | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Absence}/available-dates/${classId}`;
    if (yearSetting) {
      url = `${url}/${yearSetting}`;
    }
    return await fetchApi<AvailableDates>(url);
  };

  const fetchYearSettingLock = async (): Promise<boolean | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year/lock/${yearSetting}`;
    return await fetchApi<boolean>(url);
  };

  useEffect(() => {
    if (currentDate !== undefined) {
      fetchLockAbsence().then((response) => {
        if (response) {
          setCurrentLockAbsence(response);
        }
      });
    }
  }, [currentDate]);

  useEffect(() => {
    if (yearSetting !== undefined) {
      fetchYearSettingLock().then((response) => {
        setYearSettingLocked(response);
      });
    }
  }, [yearSetting]);

  useEffect(() => {
    if (classId) {
      setIsLoading(true);
      fetchContent().then((response) => {
        if (response) {
          setStudents(response);
          const checks = response.map((s: Student) => {
            return {
              studentId: s.id,
              absent: false,
              late: false,
              absences: s.absences,
              lates: s.lates,
              totalAbsences: s.totalAbsences,
              totalLates: s.totalLates,
            };
          });
          setStudentChecks(checks);
        }

        setIsLoading(false);
      });
      fetchDates().then((response) => {
        if (response) {
          setDates(response.dates);
          setCurrentDate(response.closest);
        }
      });
      if (yearSetting === undefined && yearAverage === false) {
        fetchCurrentYearSetting().then((response) => {
          if (response) {
            setYearSetting(response);
          }
        });
      }
    }
  }, [classId, yearSetting]);

  const checkAbsence = async (
    event: React.ChangeEvent<HTMLInputElement>,
    student: Student,
    late: boolean
  ) => {
    if (!currentDate || currentDate === "-1") {
      setErrors({});
      let errorsTemp: any = {};
      errorsTemp["currentDate"] = "Veuillez choisir une date";
      setErrors(errorsTemp);
      return;
    }

    const url = `${process.env.REACT_APP_API}/${ApiUrls.Absence}`;

    const body = {
      studentId: student.id,
      late: late,
      createdDate: currentDate,
    };

    const type = event.currentTarget.checked ? "POST" : "DELETE";

    const response = await fetch(url, {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    const result = (await response.json()) as Student;
    const copy = [...students];
    const index = copy.findIndex((s) => s.id === result.id);
    if (index > -1) {
      copy.splice(index, 1, result);
      setStudents(copy);
      const copyChecks = [...studentChecks];
      let copyStudentCheck = copyChecks.find((c) => c.studentId === result.id);
      if (copyStudentCheck) {
        copyStudentCheck.absences = result.absences;
        copyStudentCheck.lates = result.lates;
        setStudentChecks(copyChecks);
      }
    }
  };
  const setYears = () => {
    setYearSetting(undefined);
    setYearAverage(true);
    setYearSettingLocked(false);
  };

  const setYearsSettings = (value: number) => {
    setYearSetting(value);
    setYearAverage(false);
  };

  const postLockAbsence = async () => {
    const url =
      currentLockAbsence && currentLockAbsence.id
        ? `${process.env.REACT_APP_API}/${ApiUrls.Absence}/lock-absence/${currentLockAbsence.id}`
        : `${process.env.REACT_APP_API}/${ApiUrls.Absence}/lock-absence`;

    const body = {
      classeId: classId,
      classDate: currentDate,
    };

    const type = currentLockAbsence && currentLockAbsence.id ? "PUT" : "POST";

    const response = await fetch(url, {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const result = (await response.json()) as LockAbsence;
      setCurrentLockAbsence(result);
    }
  };

  const getLockClass = (): string => {
    if (!currentLockAbsence) {
      return "icon-unlock";
    } else if (currentLockAbsence.locked) {
      return "icon-lock";
    } else {
      return "icon-unlock";
    }
  };

  return (
    <Fragment>
      {isLoading && (
        <BeeLoader />
      )}

      {!isLoading && students.length > 0 && (
        <TrimestresHeader
          chooseTrimestre={setYearsSettings}
          setYears={setYears}
          currentyearAverage={yearAverage}
          currentSetting={yearSetting}
        />
      )}
      {!isLoading && students.length === 0 && (
        <div>Aucun élève dans cette classe</div>
      )}
      {!isLoading && (
        <div className="row">
          {yearSettingLocked === true && (
            <label className="bold">{Labels.YearBlocked}</label>
          )}

          {dates.length > 0 && (
            <div className="col-md-3">
              
              <select
                disabled={yearSettingLocked || yearAverage === true}
                onChange={(e) => {
                  console.log("coucou",e.currentTarget.value)
                  setErrors({});
                  setCurrentDate(e.currentTarget.value);
                }}
                id="location"
                name="location"
                value={currentDate}
                className={classNames(
                  "block w-full rounded-md border-0 py-1.5 pl-3 pr-10",
                  "text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2",
                  " sm:text-sm sm:leading-6"                 
                )}
              >
                <option value={-1}>Choisissez une date</option>
                {dates && dates.map((d) => (
                  <option key={d} value={d}>{d}</option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
      {!isLoading && students.length > 0 && (
        <div className="flow-root mb-5">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      <div>
                        {Labels.LastName}
                        {students && <div>{students.length} élève(s)</div>}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {Labels.Absence}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {Labels.Late}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {Labels.RecapAbsence}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {Labels.RecapLate}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {Labels.TotalAbsences}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {Labels.TotalLates}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students &&
                    students.map((s) => {
                      return (
                        <tr key={s.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div style={{ textAlign: "left" }}>
                              {`${s.lastName} ${s.firstName}`}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <input
                              type="checkbox"
                              disabled={
                                yearSettingLocked ||
                                yearAverage === true ||
                                (currentLockAbsence &&
                                  currentLockAbsence.locked)
                              }
                              onChange={(e) => checkAbsence(e, s, false)}
                              checked={
                                currentDate !== undefined &&
                                studentChecks
                                  .find((stu) => stu.studentId === s.id)
                                  ?.absences.includes(
                                    `${currentDate.split(" ")[1]} ${currentDate.split(" ")[2]
                                    }`
                                  )
                              }
                            />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <input
                              type="checkbox"
                              disabled={
                                yearSettingLocked ||
                                yearAverage === true ||
                                (currentLockAbsence &&
                                  currentLockAbsence.locked)
                              }
                              onChange={(e) => checkAbsence(e, s, true)}
                              checked={
                                currentDate !== undefined &&
                                studentChecks
                                  .find((stu) => stu.studentId === s.id)
                                  ?.lates.includes(
                                    `${currentDate.split(" ")[1]} ${currentDate.split(" ")[2]
                                    }`
                                  )
                              }
                            />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div
                              style={{ textAlign: "left" }}
                              className="ellipsis"
                              title={s.absences}
                            >
                              {s.displayAbsences}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div
                              style={{ textAlign: "left" }}
                              className="ellipsis"
                              title={s.lates}
                            >
                              {s.displayLates}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div>{s.totalAbsences}</div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div>{s.totalLates}</div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {!isLoading && dates.length > 0 && (
        <div className="flex justify-center">
          <button
            onClick={postLockAbsence}
            className="flex w-20  justify-center rounded-md
                             bg-blue-500 px-3 py-1.5 text-sm 
                             font-semibold leading-6 text-white 
                             shadow-sm hover:bg-orange-500 
                             focus-visible:outline focus-visible:outline-2 
                             focus-visible:outline-offset-2
                              focus-visible:outline-pink-600"
          >
            <i className={getLockClass()}></i>
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default Absences;
