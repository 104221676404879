import LocalStorageKey from "./localStorage";
import {jwtDecode } from "jwt-decode";

export const isEmailAddress = (value: string): boolean => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(value);
}

export const isAuthenticated = (): boolean => {  
    const token = localStorage.getItem(LocalStorageKey.Auth);
    return token !== undefined && token !== null && !isTokenExpired(token);
}

const isTokenExpired = (token: string) => {
    try {
        const decoded: any = jwtDecode(token);
        if (decoded.exp < Date.now() / 1000) {
            // Checking if token is expired.
            return true;
        } else return false;
    } catch (err) {
        console.log("expired check failed!");
        return false;
    }
};

export const isAdmin = () : boolean => {
    const token = localStorage.getItem(LocalStorageKey.Auth);
    if(!token) {
        return false;
    }
    try {
        const decoded: any = jwtDecode(token);
        return decoded.IsAdmin === 'True';       
    } catch (err) {
        console.log("expired check failed!");
        return false;
    }
};

export const getUserName = () : string => {
    const token = localStorage.getItem(LocalStorageKey.Auth);
    if(!token || !isAuthenticated()) {
        return '';
    }
    try {
        const decoded: any = jwtDecode(token);
        const result = `${decoded.LastName} ${decoded.FirstName} `;   
        return result;   
    } catch (err) {        
        return '';
    }
}

export const getLastConnexion = () : string => {
    const token = localStorage.getItem(LocalStorageKey.Auth);
    if(!token || !isAuthenticated()) {
        return '';
    }
    try {
        const decoded: any = jwtDecode(token);
        let result = '';
        if(decoded.LastConnexionDate){
            result = `Dernière connexion : ${decoded.LastConnexionDate} `; 
        }
         
        return result;   
    } catch (err) {        
        return '';
    }
}

export const getHeaders = (): Headers  =>{
    const token = localStorage.getItem(LocalStorageKey.Auth);
    const headers = new Headers({
        Authorization : `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    })
    return headers;
}

export const getHeadersPdf = (): Headers  =>{
    const token = localStorage.getItem(LocalStorageKey.Auth);
    const headers = new Headers({
        Authorization : `Bearer ${token}`,
        'Content-Type': 'application/pdf'
    })
    return headers;
}

export const getFileHeaders = (): Headers  =>{
    const token = localStorage.getItem(LocalStorageKey.Auth);
    const headers = new Headers({
        Authorization : `Bearer ${token}`       
    })
    return headers;
}

export const createDownloadLink = (blob:Blob, linkName:string)=>{
    const b = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = b;
        link.setAttribute('download', `${linkName}`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
}

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }