import React, { Fragment } from "react";
import Pole from "./pole";
import { useNavigate } from "react-router";

import * as RoutesUrl from "../../routing/routes";
import Button from "../components/button";
interface PoleCardProps {
  clickOnDelete: (i: number) => void;
  clickOnDisplay: (i?: number) => void;
  pole?: Pole;
}
const PoleCard = ({ clickOnDelete, clickOnDisplay, pole }: PoleCardProps) => {
  const history = useNavigate();
  return (
    <>
      <div className="flex justify-end">
        <Button label="Ajouter" onClick={() => history(RoutesUrl.POLE_ADD)} />
      </div>
      <div className="space-y-12">
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-10 
              border-gray-900/10 pb-12 "
        >
          <div className="ml-2">
            <div
              className="grid grid-cols-1 gap-x-8 gap-y-10 
              border-b border-gray-900/10 pb-12 
              md:grid-cols-2"
            >
              <div className="md:col-span-1 text-left">
                {pole?.subjects.length === 0 ? (
                  <div>
                    Veuillez ajouter des matières à partir de l'onglet
                    "matières"
                  </div>
                ) : (
                  <div className="flow-root mb-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Matières associées
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {pole?.subjects.split(",").map((ass) => {
                              return (
                                <tr className="odd:bg-gray-200">
                                  <td className="whitespace-nowrap pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    <div className="w-full">
                                      <div className="flex justify-center">
                                        {ass}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="">
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <div></div>
                  <button
                    onClick={() => clickOnDisplay(pole?.id)}
                    type="submit"
                    className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
             text-white shadow-sm
              hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Editer
                  </button>

                  {pole?.subjects.length === 0 && (
                    <>
                      <button
                        onClick={() => clickOnDelete(pole?.id)}
                        type="button"
                        className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
             text-white shadow-sm
              hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Supprimer
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoleCard;
