import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiUrls from "../../common/urls";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import BeeLoader from "../../layout/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgottenLoginModel, forgottenPwdSchema, LoginLabels } from "./loginSchema";
import { postApi } from "../../common/api";

const ForgottenPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgottenLoginModel>({
    resolver: yupResolver(forgottenPwdSchema),
  });

  const onSubmit = async (data: ForgottenLoginModel) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Login}/forgotten-password`;
    const body = {
      login: data.userName,
    };
    setIsLoading(true);

    await postApi(url,body);

    addToast(LoginLabels.ForgottenPasswordLabel, {
      appearance: "success",
      autoDismiss: true,
    });
    history(Routes.LOGIN);
    setIsLoading(false);
  };
  
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {LoginLabels.PleaseEnterUserName}
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6"
          >
            <div>
              <label
                htmlFor="userName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {LoginLabels.Login}
              </label>
              <div className="mt-2">
                <input
                  {...register("userName")}
                  className="block w-full rounded-md border-0 py-1.5
                   text-gray-900 shadow-sm ring-1 
                   ring-inset ring-gray-300
                    placeholder:text-gray-400 focus:ring-2 
                    focus:ring-inset focus:ring-indigo-600 
                    sm:text-sm sm:leading-6"
                />
                <p className="text-red-600 text-sm font-medium">
                  {errors.userName?.message}
                </p>
              </div>
            </div>

            <div>
              {isLoading && (
                <div className="flex items-end justify-center">
                  <BeeLoader />
                </div>
              )}
              {!isLoading && (
                <>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-purple-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                  >
                    {LoginLabels.Valid}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgottenPassword;
