import React, { useEffect, useState } from "react";
import { fetchApi } from "../../common/api";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import { getHeaders } from "../../common/utils";

const Labels = {
    First: '1e T',
    Second: '2e T',
    Third: '3e T',
    Period: 'Période',
    YearSetting: 'Trimestre',
    Status: 'Statut'
}

interface ArchiveResponse {
    status: number,
    period: string,
    yearSetting: number
}

const Archives = () => {
    const [archives, setArchives] = useState<ArchiveResponse[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const statuses: {[name:number]:string} = {
        0:'Demandé',
        1: 'En cours de traitement',
        2:'Complété',
        3: 'Annulé'
    }

    const fetchArchives = async (): Promise<ArchiveResponse[] | null> => {
        const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/archive-request`;
        return await fetchApi<ArchiveResponse[]>(url)

    }

    useEffect(() => {
        loadArchiveRequests();
    }, [])

    const loadArchiveRequests = () => {
        setIsLoading(true);
        fetchArchives()
            .then((response) => {
                if (response) {
                    setArchives(response);
                }
                setIsLoading(false);
            });
    }

    const sendArchivingRequest = async (yearSetting: number): Promise<void> => {
        const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/archiving`;
        const body =
        {
            yearSetting
        }
        setIsLoading(true);
        const response = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(body)
        });

        loadArchiveRequests();
    }

    return <div className="row">
        <div className="col-3"><button className="btn" onClick={() => sendArchivingRequest(1)}>{Labels.First}</button></div>
        <div className="col-3"><button className="btn" onClick={() => sendArchivingRequest(2)}>{Labels.Second}</button></div>
        <div className="col-3"><button className="btn" onClick={() => sendArchivingRequest(3)}>{Labels.Third}</button></div>
        {
            isLoading && <BeeLoader />
        }
        <br />
        <br />
        {
            !isLoading && <div className="row">

                <div className="col-6">
                    <table className="table table-responsive-sm table-striped table-sm table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>{Labels.Period}</th>
                                <th>{Labels.YearSetting}</th>
                                <th>{Labels.Status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                archives.map(arc => <tr>
                                    <td>{arc.period}</td>
                                    <td>{arc.yearSetting}</td>
                                    <td> {statuses[arc.status]}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}

export default Archives