import { useNavigate } from "react-router";

interface CancelButtonProps {
  url?: string ;
  onCancelClick?: () => void;
  label?: string;
}
const CancelButton = ({ url, onCancelClick, label }: CancelButtonProps) => {
  const navigate = useNavigate();
  const internalClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (url) {
      navigate(url);
      return;
    }
    if (onCancelClick !== undefined) {
      onCancelClick();
    }
  };

  return (
    <>
      <button
        className="rounded-md  px-3 py-2 text-sm font-semibold
            shadow-sm
            hover:bg-orange-500 focus-visible:outline
            focus-visible:outline-2
            focus-visible:outline-offset-2
            focus-visible:outline-indigo-600
            hover:text-white
            hover:no-underline"
        onClick={(e)=>internalClick(e)}
      >
        {label ?? "Annuler"}
      </button>
    </>
  );
};

export default CancelButton;
