import React, { useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import { getHeaders, getFileHeaders } from "../../common/utils";
import Resource from "./resource";
import BeeLoader from "../../layout/loader";
import { useToasts } from "react-toast-notifications";
import "./styles.scss";
import { fetchApi } from "../../common/api";

const Labels = {
  Title: "Définition des champs",
  Error: "Une erreur est survenue",
  EmailSender: "E-mail de l'expéditeur",
  EmailObject: "Objet du mail",
  EmailAdult: "Email des classes adulte",
  EmailChild: "Email des classes Enfants/ados",
  Logo: "Logo",
  Signature: "Signature",
};

enum ResourceKey {
  mailAdult,
  mailChildren,
  emailProvenance,
  emailObject,
  logo,
  signature,
}

const Resources = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>();
  const [logo, setLogo] = useState<Blob>();
  const [signature, setSignature] = useState<Blob>();
  const { addToast } = useToasts();

  const fetchResources = async (): Promise<Resource[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/resources`;
    return await fetchApi<Resource[]>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchResources().then((response) => {
      if (response) {
        setResources(response);
      }

      setIsLoading(false);
    });
  }, []);

  const saveResource = async (type: ResourceKey, value: string | undefined) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/resources`;

    const body = {
      type,
      value,
    };
    setIsLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `Enregistrement bien effectué.`;
      addToast(message, { appearance: "success", autoDismiss: true });
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const updateProps = (key: ResourceKey, value: string) => {
    if (resources) {
      let copies = [...resources];
      let copy = copies.find((r) => r.type === key);
      if (copy) {
        if (key !== ResourceKey.logo && key !== ResourceKey.signature) {
          copy.value = value;
        }
      } else {
        const element = {
          type: key,
          value: value,
          content: undefined,
        };
        copies.push(element);
      }
      setResources(copies);
    }
  };

  const onChangeLogo = (e: any) => {
    setLogo(e.target.files[0]);
  };
  const onChangeSignature = (e: any) => {
    setSignature(e.target.files[0]);
  };

  const saveImages = async (key: ResourceKey, file: any) => {
    let formData = new FormData();
    formData.append("Type", key.toString());
    formData.append("FormFile", file);

    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/resources/files`;

    setIsLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: getFileHeaders(),
      body: formData,
    });

    if (response.ok) {
      const message = `Enregistrement bien effectué.`;
      addToast(message, { appearance: "success", autoDismiss: true });
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const getLogoUrl = () => (logo ? URL.createObjectURL(logo) : "");
  const getSignatureUrl = () =>
    signature ? URL.createObjectURL(signature) : "";

  return (
    <>
      {isLoading && (
        <>
          <div className="flex justify-center">
            <BeeLoader />
          </div>
        </>
      )}
      {!isLoading && resources && (
        <>
          <div className="space-y-12  ml-2">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {Labels.EmailSender}
                </h2>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <textarea
                      className="form-control"
                      value={
                        resources.find(
                          (r) => r.type === ResourceKey.emailProvenance
                        )?.value
                      }
                      onChange={(e) =>
                        updateProps(
                          ResourceKey.emailProvenance,
                          e.currentTarget.value
                        )
                      }
                    />
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      //onClick={() => saveImages(ResourceKey.logo, logo)}
                      onClick={() =>
                        saveResource(
                          ResourceKey.emailProvenance,
                          resources.find(
                            (r) => r.type === ResourceKey.emailProvenance
                          )?.value
                        )
                      }
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
                         text-white shadow-sm
                        hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sauvegarder l'email
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {Labels.EmailObject}
                </h2>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <textarea
                      className="form-control"
                      value={
                        resources.find(
                          (r) => r.type === ResourceKey.emailObject
                        )?.value
                      }
                      onChange={(e) =>
                        updateProps(
                          ResourceKey.emailObject,
                          e.currentTarget.value
                        )
                      }
                    />
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      onClick={() =>
                        saveResource(
                          ResourceKey.emailObject,
                          resources.find(
                            (r) => r.type === ResourceKey.emailObject
                          )?.value
                        )
                      }
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
                         text-white shadow-sm
                        hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sauvegarder l'objet du mail
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {Labels.EmailAdult}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Information importante : les élements{" "}
                  <b>[NOM] [PRENOM] [CLASSE]</b> seront des balises changées
                  lors d'un envoi de bulletin
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full row-span-full">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <textarea
                      style={{ minHeight: "230px" }}
                      className="form-control"
                      value={
                        resources.find((r) => r.type === ResourceKey.mailAdult)
                          ?.value
                      }
                      onChange={(e) =>
                        updateProps(
                          ResourceKey.mailAdult,
                          e.currentTarget.value
                        )
                      }
                    />
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      onClick={() =>
                        saveResource(
                          ResourceKey.mailAdult,
                          resources.find(
                            (r) => r.type === ResourceKey.mailAdult
                          )?.value
                        )
                      }
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
                         text-white shadow-sm
                        hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sauvegarder email classes adulte
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {Labels.EmailChild}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Information importante : les élements{" "}
                  <b>[NOM] [PRENOM] [CLASSE]</b> seront des balises changées
                  lors d'un envoi de bulletin
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full row-span-full">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <textarea
                      style={{ minHeight: "230px" }}
                      className="form-control"
                      value={
                        resources.find(
                          (r) => r.type === ResourceKey.mailChildren
                        )?.value
                      }
                      onChange={(e) =>
                        updateProps(
                          ResourceKey.mailChildren,
                          e.currentTarget.value
                        )
                      }
                    />
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      onClick={() =>
                        saveResource(
                          ResourceKey.mailChildren,
                          resources.find(
                            (r) => r.type === ResourceKey.mailChildren
                          )?.value
                        )
                      }
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
                         text-white shadow-sm
                        hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sauvegarder email classes enfant
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {Labels.Logo}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Cette image sera insérée en tant que logo aux différents mails
                  envoyés
                </p>
              </div>
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="mt-2 flex justify-center 
                  rounded-lg border border-dashed border-gray-900/25 px-6 py-10
                  pb-5">
                    <div className="text-center">
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Importer le fichier</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={onChangeLogo}
                          />
                        </label>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">
                        .png, .jpg, .jpeg
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      onClick={() =>
                        saveResource(
                          ResourceKey.logo,
                          resources.find((r) => r.type === ResourceKey.logo)
                            ?.value
                        )
                      }
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
                         text-white shadow-sm
                        hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sauvegarder le logo
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-8 gap-y-10   border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {Labels.Signature}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Cette image sera insérée en tant que signature aux différents
                  mails envoyés
                </p>
              </div>
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="mt-2 flex justify-center rounded-lg border 
                  border-dashed border-gray-900/25 px-6 py-10
                  pb-5">
                    <div className="text-center">
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Importer le fichier</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={onChangeSignature}
                          />
                        </label>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">
                        .png, .jpg, .jpeg
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      onClick={() =>
                        saveResource(
                          ResourceKey.signature,
                          resources.find(
                            (r) => r.type === ResourceKey.signature
                          )?.value
                        )
                      }
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
                         text-white shadow-sm
                        hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sauvegarder la signature
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

};

export default Resources;
