import React, { Fragment, useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import { classNames, getHeaders } from "../../common/utils";
import BeeLoader from "../../layout/loader";
import Pole from "./pole";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DeleteModal from "../common/delete";
import * as Routes from "../../routing/routes";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import "./styles.scss";
import { fetchApi } from "../../common/api";
import PoleCard from "./poleCard";
import SideBarNavigation from "../components/sideBarNavigation";

const Labels = {
  Error: "Une erreur est survenue",
  NoEntries: "Aucune pôle à afficher",
  Add: "Ajouter",
  Title: "Liste des pôles",
};

const Poles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [poles, setPoles] = useState<Pole[]>([]);
  const [currentPoleId, setCurrentPoleId] = useState<number>(-1);
  const [showDelete, setShowDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const { addToast } = useToasts();
  const history = useNavigate();

  const updatePolePositions = async (poles: Pole[]) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Pole}/update-positions`;

    const positions = poles.map((p: Pole, i: number) => {
      return {
        id: p.id,
        position: i,
      };
    });
    const data = {
      positions,
    };
    await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  const fetchPoles = async (): Promise<Pole[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Pole}`;
    return await fetchApi<Pole[]>(url);
  };

  const onDelete = async (id: number | null | undefined) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Pole}`;

    const body = {
      id: id,
    };

    setIsLoading(true);
    const response = await fetch(url, {
      method: "Delete",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      addToast("Suppression effectuée", {
        appearance: "success",
        autoDismiss: true,
      });
      setIsLoading(false);
      setReload(!reload);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPoles().then((response) => {
      if (response && response.length > 0) {
        setCurrentPoleId(response[0].id);
        setPoles(response);
      }
      setIsLoading(false);
    });
  }, [reload]);

  const clickOnDelete = (i: number | undefined) => {
    if (i) {
      setCurrentPoleId(i);
      setShowDelete(true);
    }
  };

  const reorder = (list: Pole[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const results = reorder(
      poles,
      result.source.index,
      result.destination.index
    );
    setPoles(results);
    await updatePolePositions(results);
  };

  const getActiveClasse = (expectedId: number): string => {
    return currentPoleId === expectedId ? "active" : "";
  };
  const onMenuClick = (expectedLevel: number) => {
    setCurrentPoleId(expectedLevel);
  };
  const getContent = (): JSX.Element => {
    var content = poles.map((pole: Pole, index: number) => {
      return (
        <Draggable
          draggableId={pole.id.toString()}
          index={index}
          key={pole.id.toString()}
        >
          {(provided) => (
            <div
              className=" "
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                style={{ textAlign: "left" }}
                className={classNames(
                  currentPoleId === pole.id
                    ? "bg-opacity-55"
                    : "bg-opacity-100",
                  "text-white",
                  "mb-1",
                  "bg-blue-500",
                  "hover:bg-orange-500 hover:bg-opacity-75 hover:text-white",
                  "group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 ",

                  "hover:no-underline cursor-pointer"
                )}
                onClick={() => onMenuClick(pole.id)}
              >
                <div style={{ marginLeft: "10px" }}>{pole.name}</div>
              </div>
            </div>
          )}
        </Draggable>
      );
    });

    return <Fragment>{content}</Fragment>;
  };
  const clickOnDisplay = (id?: number) => {
    history(`/pole/detail/${id}`);
  };
  return (
    <div className="row">
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        confirm={() => onDelete(currentPoleId)}
      />
      
      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}
      {!isLoading && poles && poles.length === 0 && (
         <h2 className="text-md font-bold text-gray-900">{Labels.NoEntries}</h2>
      )}
      {!isLoading && poles.length > 0 && (
        <div className="sm:flex-col flex-row md:flex">
          <div className="sm:hidden col-span-full mb-3">
            <label htmlFor="tabs" className="sr-only"></label>
            <select
              id="tabs"
              name="tabs"
              value={currentPoleId}
              onChange={(e) => onMenuClick(parseInt(e.currentTarget.value))}
              className="block w-full rounded-md border-gray-300
           focus:border-purple-500 focus:ring-purple-500"
            >
              <option value={-1}>---</option>
              {poles.map((tab) => (
                <option key={tab.id} value={tab.id}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav aria-label="Sidebar" className="flex flex-1 flex-col">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div
                      className=""
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {getContent()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </nav>
          </div>

          <div className="w-full">
            {currentPoleId > -1 && (
              <PoleCard
                pole={poles.find((p) => p.id === currentPoleId)}
                clickOnDelete={() =>
                  clickOnDelete(poles.find((p) => p.id === currentPoleId)?.id)
                }
                clickOnDisplay={() =>
                  clickOnDisplay(poles.find((p) => p.id === currentPoleId)?.id)
                }
              />
            )}
          </div>
        </div>
      )}
      
    </div>
  );
};

export default Poles;
