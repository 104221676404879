import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiUrls from "../../common/urls";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import { getHeaders, isEmailAddress } from "../../common/utils";
import InputLabelValidation from "../components/inputLabelValidation";
import Select from "../components/select";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";

const Labels = {
  IsRequired: "Ce champ est requis",
  Diff: "Le mot de passe ainsi que sa confirmation sont différents",
  Email: "Le format de l'identifiant n'est pas conforme",
  Lenght: "Veuillez entrer un mot de passe compris entre 5 et 10 caractères",
  CorrectForm: "Veuillez corriger les erreurs",
  Error: "Une erreur est survenue",
};

export const roles = [
  {
    id: 0, 
    name: "Administrateur",
    alias:'BeeLearningAdmin'
  },
  {
    id: 1, 
    name: "Professeur",
    alias:'BeeLearningProf'
  },
];

const Add = () => {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirmation, setPwdConfirmation] = useState("");
  const [role, setRole] = useState<number>(-1);
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { addToast } = useToasts();
  const history = useNavigate();

  const validate = (): boolean => {
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!lastName) {
      errorsTemp["lastName"] = Labels.IsRequired;
    }
    if (!firstName) {
      errorsTemp["firstName"] = Labels.IsRequired;
    }
    if (!pwd) {
      errorsTemp["pwd"] = Labels.IsRequired;
    }
    if (!pwdConfirmation) {
      errorsTemp["pwdConfirmation"] = Labels.IsRequired;
    }
    if (role === -1) {
      errorsTemp["role"] = Labels.IsRequired;
    }

    if (pwd && pwdConfirmation && pwd !== pwdConfirmation) {
      errorsTemp["pwdConfirmation"] = Labels.Diff;
    }

    if (pwd && (pwd.length < 5 || pwd.length > 10)) {
      errorsTemp["pwd"] = Labels.Lenght;
    }

    if (!isEmailAddress(userName)) {
      errorsTemp["userName"] = Labels.Email;
    }

    isValid = Object.entries(errorsTemp).length === 0;

    setErrors(errorsTemp);
    return isValid;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Users}`;

    const body = {
      lastName,
      firstName,
      userName,
      pwd,
      pwdConfirmation,
      role:role === 0 ? "BeeLearningAdmin": "BeeLearningProf",
    };

    setisLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    const result = await response.json();

    if (response.ok && result === true) {
      const message = `l'utilisateur ${lastName} ${firstName} est créé`;

      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.UTILISATEURS);
      setisLoading(false);
    } else if (result === false) {
      const message = `l'identifiant ${userName} est déjà utilisé`;

      addToast(message, { appearance: "error", autoDismiss: true });

      setisLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setisLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Création d'un utilisateur
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Veuillez compléter le formulaire suivant
              </p>
            </div>
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["lastName"] !== undefined}
                  inputValue={lastName}
                  label="Nom"
                  onChangeValue={(e) => setLastName(e.currentTarget.value)}
                />
              </div>
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["firstName"] !== undefined}
                  inputValue={firstName}
                  label="Prénom"
                  onChangeValue={(e) => setFirstName(e.currentTarget.value)}
                />
              </div>
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["userName"] !== undefined}
                  inputValue={userName}
                  label="Identifiant (email)"
                  onChangeValue={(e) => setUserName(e.currentTarget.value)}
                />
              </div>
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["pwd"] !== undefined}
                  inputValue={pwd}
                  type="password"
                  label="Mot de passe (min 5 caractères max 10)"
                  onChangeValue={(e) => setPwd(e.currentTarget.value)}
                />
              </div>
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["pwdConfirmation"] !== undefined}
                  inputValue={pwdConfirmation}
                  type="password"
                  label="Confirmation du mot de passe"
                  onChangeValue={(e) =>
                    setPwdConfirmation(e.currentTarget.value)
                  }
                />
              </div>
              <div className="sm:col-span-3">
                <Select
                  hasError={errors["role"] != undefined}
                  errorLabel={Labels.IsRequired}
                  data={roles}
                  label="Choisissez un rôle"
                  onChange={setRole}
                  value={role}
                  selectLabel="Choisissez le rôle"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <CancelButton label="Annuler" url={Routes.UTILISATEURS} />
            <SubmitButton />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Add;
