import React, { useEffect, useState } from "react";
import { classNames, isAdmin } from "../../common/utils";
import Select from "../components/select";

const Labels = {
  FirstYearSetting: "1e Trimestre",
  SecondYearSetting: "2e Trimestre",
  ThirdYearSetting: "3e Trimestre",
  YearBlocked:
    "La période est clôturée. Seuls les administrateurs peuvent ôter la clôture.",
  Year: "Année",
  DownloadResults: "Télécharger les résulats",
};

interface TrimestresHeaderProps {
  chooseTrimestre: (i: number) => void;
  currentSetting: number | undefined;
  downloadResults?: () => void;
  setYears?: () => void;
  currentyearAverage?: boolean;
  download?: boolean;
}

const TrimestresHeader = ({
  chooseTrimestre,
  currentSetting,
  downloadResults,
  setYears,
  currentyearAverage,
  download,
}: TrimestresHeaderProps) => {
  const [yearSetting, setYearSetting] = useState<number | undefined>();
  //const [download, setDownload] = useState(false);

  const [yearAverage, setYearAverage] = useState(false);

  const getActiveSettings = (value: number) =>
    value === yearSetting && yearSetting !== undefined
      ? "trimestre-header active"
      : "trimestre-header";

  const setYearsSettings = (value: number) => {
    setYearSetting(value);
    chooseTrimestre(value);
  };

  useEffect(() => {
    setYearSetting(currentSetting);
  }, [currentSetting]);

  useEffect(() => {
    if (currentyearAverage) {
      setYearAverage(currentyearAverage);
    }
  }, [currentyearAverage]);

  const getActiveTab = () =>
    yearAverage === true ? "trimestre-header active" : "trimestre-header";

  const setYearTrimestres = () => {
    setYearSetting(undefined);
    setYearAverage(true);
    if (setYears) {
      setYears();
    }
  };

  const links = [
    {
      id: 1,
      name: `${Labels.FirstYearSetting}`,
    },
    {
      id: 2,
      name: `${Labels.SecondYearSetting}`,
    },
    {
      id: 3,
      name: `${Labels.ThirdYearSetting}`,
    }   
  ];

  const onSelectChange = (mode: number) => {
    setYearSetting(mode);
    if (mode !== -1) {
      chooseTrimestre(mode);
    } else {
      setYearTrimestres();
    }
  };

  return (
    <>
      {/* {
            !downloadResults && <div className='filter-element'></div>
        }

        {
            downloadResults && isAdmin() && <div className='col-md-2'>

                <button className='btn ' title={Labels.DownloadResults} onClick={downloadResults}>
                    <i title='Télécharger les résultats' className={download ? "icon-spinner" : 'icon-download-alt'}></i>
                </button>
            </div>
        }

        <div className={getActiveSettings(1)} onClick={() => setYearsSettings(1)}>{Labels.FirstYearSetting}</div>
        <div className={getActiveSettings(2)} onClick={() => setYearsSettings(2)}>{Labels.SecondYearSetting}</div>
        <div className={getActiveSettings(3)} onClick={() => setYearsSettings(3)}>{Labels.ThirdYearSetting}</div>
        {
            setYears &&
            <div className='col-2 '><div className={getActiveTab()} onClick={() => setYearTrimestres()}>{Labels.Year}</div></div>
        } */}
      <div className="sm:hidden w-full mb-2">
        <Select
          data={links}
          onChange={onSelectChange}
          errorLabel=""
          hasError={false}
          label=""
          selectLabel="Choisissez un trimestre"
          value={yearSetting}
        />
      </div>
      <div className="hidden sm:block mb-2">
        <nav aria-label="Tabs" className="flex space-x-4 justify-center">
        <a
            href="#"
            onClick={() => setYearsSettings(1)}
            className={classNames(
              yearSetting === 1
                ? "text-purple-500 "
                : "text-gray-500 hover:text-purple-500",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
          >
            {" "}
            {Labels.FirstYearSetting}
          </a>
          <a
            href="#"
            onClick={() => setYearsSettings(2)}
            className={classNames(
              yearSetting === 2
                ? "text-purple-500 "
                : "text-gray-500 hover:text-purple-500",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
          >
            {" "}
            {Labels.SecondYearSetting}
          </a>
          <a
            href="#"
            onClick={() => setYearsSettings(3)}
            className={classNames(
              yearSetting === 3
                ? "text-purple-500 "
                : "text-gray-500 hover:text-purple-500",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
          >
            {" "}
            {Labels.ThirdYearSetting}
          </a>
        </nav>
      </div>
    </>
  );
};

export default TrimestresHeader;
