import React from "react";
import { Circles } from "react-loader-spinner";

const BeeLoader = () => {
  return (
    <div className="flex justify-center">
      <Circles color="rgb(249 115 22)" height={50} width={50} />
    </div>
  );
};

export default BeeLoader;
