import React, { Fragment } from "react";
import { Level } from "./level";
import { useNavigate } from "react-router-dom";
import * as RoutesUrl from "../../routing/routes";
import Button from "../components/button";
import CancelButton from "../components/cancelButton";
interface LevelCardProps {
  level?: Level;
  clickOnDelete: (i: number) => void;
  clickOnDisplay: (i?: number) => void;
}
const LevelCard = ({
  level,
  clickOnDelete,
  clickOnDisplay,
}: LevelCardProps) => {
  const history = useNavigate();
  return (
    <Fragment>
      <div className="flex justify-end mt-3">
        <Button label="Ajouter" onClick={() => history(RoutesUrl.LEVEL_ADD)} />
      </div>

      <div className="space-y-12 ml-2 mt-2">
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-10 
              border-gray-900/10 pb-12 
              "
        >
          
          <div className="">
            <div
              className="grid grid-cols-1 gap-x-8 gap-y-10 
              border-b border-gray-900/10 pb-12 
              md:grid-cols-2"
            >
              <div className="md:col-span-1 text-left">
                {level?.associatedSubjects.length === 0 ? (
                  <div>
                    Veuillez ajouter des matières à partir de l'onglet
                    "matières"
                  </div>
                ) : (
                 
                  <div className="flow-root mb-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Matières associées
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Coefficient
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {level?.associatedSubjects.split(";").map((ass) => {
                              return (
                                <tr className="odd:bg-gray-200">
                                  <td className="whitespace-nowrap  pl-4 pr-3 text-center text-sm font-medium text-gray-900 sm:pl-0">
                                    {ass.split("Coeff")[0]}
                                  </td>
                                  <td className="whitespace-nowrap  pl-4 pr-3 text-center text-sm font-medium text-gray-900 sm:pl-0">
                                    {ass.split("Coeff")[1].replace(":","").replace("(","").replace(")","")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="">
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <div></div>
                  <Button
                    label="Editer"
                    onClick={() => clickOnDisplay(level?.id)}
                  />

                  {level?.associatedSubjects.length === 0 && (
                    <>
                      <Button
                        label="Supprimer"
                        onClick={() => clickOnDelete(level?.id)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LevelCard;
