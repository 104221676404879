import React, { useState } from "react";
import Bulletins from "./bulletin";
import Resources from "./resources";
import YearSettings from "./yearSettings";
import Archives from "./archives";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import SideBarNavigation from "../components/sideBarNavigation";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
enum SettingsChoice {
  Year,
  Email,
  Bulletin,
  Archiving,
}

const Settings = () => {
  const [choice, setChoice] = useState<SettingsChoice>(SettingsChoice.Year);
  const choices: { [name: number]: JSX.Element } = {
    0: <YearSettings />,
    1: <Resources />,
    2: <Bulletins />,
    3: <Archives />,
  };

  const onMenuClick = (expectedChoice: SettingsChoice) => {
    setChoice(expectedChoice);
  };
  const navigation = [
    {
      name: "Gestion des trimestres",
      href: "#",
      icon: HomeIcon,
      count: "5",
      choice: SettingsChoice.Year,
      onClick: () => onMenuClick(SettingsChoice.Year),
      id: SettingsChoice.Year,
    },
    {
      name: "Gestion des emails",
      href: "#",
      icon: UsersIcon,
      choice: SettingsChoice.Email,
      onClick: () => onMenuClick(SettingsChoice.Email),
      id: SettingsChoice.Email,
    },

    {
      name: "Gestion des bulletins",
      href: "#",
      icon: CalendarIcon,
      count: "20+",
      choice: SettingsChoice.Bulletin,
      onClick: () => onMenuClick(SettingsChoice.Bulletin),
      id: SettingsChoice.Bulletin,
    },
  ];

  return (
    <div className="sm:flex-col flex-row md:flex">
      <div>
        <SideBarNavigation
          data={navigation}
          onClick={onMenuClick}
          selectedValue={choice}
        />     
      </div>
      <div className="w-full">{choices[choice]}</div>
    </div>
  );
};

export default Settings;
