import React, { Fragment } from "react";
import { NonLockedAbsence } from "./absence";

interface AbsenceCardProps {
  absence?: NonLockedAbsence;
}
const AbsenceCard = ({ absence }: AbsenceCardProps) => {
  return (
    <Fragment>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {absence?.teacher}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              En cas de nombreux appels manquants, veuillez contacter le
              professeur
            </p>
          </div>
          <div className="">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Dates
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {absence?.classeDates.map((cl) => {
                      return (
                        <tr className="odd:bg-gray-200">
                          <td className="whitespace-nowrap pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {cl}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flow-root mb-5">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                 
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Dates
                  </th>
                </tr>
              </thead>
              <tbody>
                {absence?.classeDates.map((cl) => {
                  return (
                    <tr>
                     
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {cl}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

      {/* <div key={absence?.classe} className='level-card'>
           
            <div className=''>
                {
                    `${absence?.teacher}`
                }
            </div>
            <div className=''>
                {
                    absence?.classeDates.map(ass => {
                        return <div className=''>{ass}</div>
                    })
                }
            </div>
        </div> */}
    </Fragment>
  );
};

export default AbsenceCard;
