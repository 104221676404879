interface ButtonProps {
  onClick: () => void;
  label: string;
  disabled?:boolean
}
const Button = ({ onClick, label, disabled }: ButtonProps) => {
  return (
    <>
      <button
        className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
          text-white shadow-sm
         hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={onClick}
        type="button"
        disabled={disabled}
      >
        {label}
      </button>
    </>
  );
};

export default Button;
