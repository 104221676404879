import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { classNames } from "../../common/utils";

interface SelectProps {
  label: string;
  data: any[] | undefined;
  onChange: (value: number) => void;
  value?: string | number ;
  hasError: boolean;
  errorLabel: string;
  disabled?:boolean,
  selectLabel:string
}

const Select = ({
  label,
  data,
  onChange,
  hasError,
  errorLabel,
  value,
  disabled,
  selectLabel
}: SelectProps) => {
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <select
      disabled={disabled}
        onChange={(e) => onChange(parseInt(e.currentTarget.value))}
        id="location"
        name="location"
        value={value}
        className={classNames(
          "block w-full rounded-md border-0 py-1.5 pl-3 pr-10",
          "text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2",
          " sm:text-sm sm:leading-6",
          hasError
            ? "text-red-900  ring-1 ring-inset ring-red-300 focus:ring-red-300"
            : ""
        )}
      >
        <option value={-1}>{selectLabel}</option>
        {data && data.map((d) => (
          <option key={d.id} value={d.id}>{d.name}</option>
        ))}
      </select>
      {hasError && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorLabel}
        </p>
      )}
    </div>
  );
};

export default Select;
