import React, { Fragment, useEffect, useState } from "react";
import { fetchApi } from "../../common/api";
import LocalStorageKey from "../../common/localStorage";
import ApiUrls from "../../common/urls";
import { getHeaders } from "../../common/utils";
import { Subject } from "./class";
import Select from "../components/select";

const Labels = {
  Choose: "Filtrer par matières",
};

interface FiltersProps {
  subjectFilter: (s: number) => void;
  classId?: number;
}

const Filters = ({ subjectFilter, classId }: FiltersProps) => {
  const [subjects, setSubjects] = useState<Subject[]>();

  const fetchSubjects = async (): Promise<Subject[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${classId}/subjects`;
    const token = localStorage.getItem(LocalStorageKey.Auth);
    return await fetchApi<Subject[]>(url);
  };

  useEffect(() => {
    if (classId) {
      fetchSubjects().then((response) => {
        if (response) {
          setSubjects(response);
        }
      });
    }
  }, [classId]);

  return (
    <Select
          data={subjects}
          label=""
          hasError={false}
          selectLabel={Labels.Choose}
          errorLabel=""
          onChange={(e) => subjectFilter(e)}
        />
  );
};

export default Filters;
