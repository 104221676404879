import React, { useEffect, useState } from "react";
import { fetchApi } from "../../common/api";
import ApiUrls from "../../common/urls";
import { classNames, getHeaders } from "../../common/utils";
import BeeLoader from "../../layout/loader";
import { NonLockedAbsence } from "./absence";
import AbsenceCard from "./absenceCard";
import "./styles.scss";
import SideBarNavigation, { Element } from "../components/sideBarNavigation";

const GestionAbsences = () => {
  const [nonLockedAbsences, setNonLockedAbsences] = useState<
    NonLockedAbsence[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState("");

  const fetchNonLockedAbsences = async (): Promise<
    NonLockedAbsence[] | null
  > => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Absence}/non-locked-absence`;
    return await fetchApi<NonLockedAbsence[]>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchNonLockedAbsences().then((response) => {
      if (response) {
        setNonLockedAbsences(response);
        if (response.length > 0) {
          setCurrentClass(response[0].classe);
        }
      }
      setIsLoading(false);
    });
  }, []);

  const onMenuClick = (expectedClass: string) => {
    setCurrentClass(expectedClass);
  };

  return (
    <div className="row">
      {isLoading && <BeeLoader />}
      {!isLoading && (
        <>
          <h2 className="text-md font-bold text-gray-900 mb-2">
            Appel(s) manquant(s)
          </h2>
          <div className="sm:flex-col flex-row md:flex">
            <div className="sm:hidden w-full mb-2">
              <select
                id="tabs"
                name="tabs"
                value={currentClass}
                onChange={(e) => onMenuClick(e.currentTarget.value)}
                className="block w-full rounded-md border-gray-300
           focus:border-purple-500 focus:ring-purple-500"
              >
                <option value={-1}>---</option>
                {nonLockedAbsences.map((tab) => (
                  <option key={tab.classe} value={tab.classe}>
                    {tab.classe}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block mr-5">
              <nav aria-label="Sidebar" className="flex flex-1 flex-col">
                <ul role="list" className="-mx-2 space-y-1">
                  {nonLockedAbsences.map((item) => (
                    <li key={item.classe}>
                      <a
                        onClick={() => onMenuClick(item.classe)}
                        className={classNames(
                          currentClass === item.classe
                            ? "bg-opacity-55"
                            : "bg-opacity-100",
                          "text-white",
                          "bg-blue-500",
                          "hover:bg-orange-500 hover:bg-opacity-75 hover:text-white",
                          "group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 ",

                          "hover:no-underline cursor-pointer"
                        )}
                      >
                        {item.classe}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="w-full">
              {currentClass && (
                <AbsenceCard
                  absence={nonLockedAbsences.find(
                    (l) => l.classe === currentClass
                  )}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GestionAbsences;
