import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

interface SearchBarProps {
  onSearch: (value: string) => void;
  placeHolder?:string
}

const SearchBar = ({ onSearch, placeHolder }: SearchBarProps) => {
  return (
    <>
        <div className="relative mt-2 mb-2">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </div>
          <input
            onChange={(e) => onSearch(e.currentTarget.value)}
            placeholder={placeHolder ?? "Recherche"}
            className="block rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
    </>
  );
};

export default SearchBar