import React, { useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import { useToasts } from "react-toast-notifications";
import Year from "./year";
import { getHeaders } from "../../common/utils";
import moment from "moment";
import { fetchApi } from "../../common/api";
import Button from "../components/button";
import SubmitButton from "../components/submitButton";
const Labels = {
  Error: "Une erreur est survenue",
  StartTrimestre1: "Début trimestre 1",
  EndTrimestre1: "Fin trimestre 1",
  StartTrimestre2: "Début trimestre 2",
  EndTrimestre2: "Fin trimestre 2",
  StartTrimestre3: "Début trimestre 3",
  EndTrimestre3: "Fin trimestre 3",
  Save: "Enregistrer",
  Title: "Gestion des trimestres",
};

interface YearSettingElementProps {
  startDateLabel: string;
  endDateLabel: string;
  onClick: (id: number, propName: string) => void;
  quarterId: number;
  propsName: string;
  locked: boolean;
  startDate: string;
  endDate: string;
  updateProp: (props: string, value: string) => void;
  updatePropStartName: string;
}

const YearSettingElement = ({
  startDateLabel,
  endDateLabel,
  onClick,
  propsName,
  quarterId,
  locked,
  startDate,
  endDate,
  updateProp,
  updatePropStartName,
}: YearSettingElementProps) => {
  return <></>;
};

const YearSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [yearSetting, setYearSetting] = useState<Year>();
  const { addToast } = useToasts();

  const updateProp = (prop: string, value: string) => {
    let temp: any = { ...yearSetting };
    temp[prop] = value;
    setYearSetting(temp);
    if (yearSetting) {
      console.log(
        moment(yearSetting.secondQuarterStart, "DD-MM-YYYY")
          .add(-1, "days")
          .format("DD-MM-YYYY")
      );
    }
  };

  const fetchYearSettings = async (): Promise<Year | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year`;
    return await fetchApi<Year>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchYearSettings().then((response) => {
      if (response) {
        setYearSetting(response);
      }

      setIsLoading(false);
    });
  }, []);

  const onSubmit = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year`;

    const body = {
      firstQuarterStart: yearSetting?.firstQuarterStart,
      firstQuarterEnd: yearSetting?.firstQuarterEnd,
      secondQuarterStart: yearSetting?.secondQuarterStart,
      secondQuarterEnd: yearSetting?.secondQuarterEnd,
      thirdQuarterStart: yearSetting?.thirdQuarterStart,
      thirdQuarterEnd: yearSetting?.thirdQuarterEnd,
    };
    setIsLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `Les trimestres sont bien enregistrés.`;
      addToast(message, { appearance: "success", autoDismiss: true });
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const onButtonClick = async (id: number, propName: string) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year/toggle`;

    const body = {
      id: id,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `Trimestre mis à jour.`;
      addToast(message, { appearance: "success", autoDismiss: true });
      let temp: any = { ...yearSetting };
      const val = temp[propName];

      temp[propName] = !val;
      setYearSetting(temp);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  const addDays = (date: string, nb: number): string =>
    moment(date).add(nb, "days").format("YYYY-MM-DD");

  return (
    <>
      {isLoading && (
        <>
          <div className="flex justify-center">
            <BeeLoader />
          </div>
        </>
      )}
      {!isLoading && yearSetting && (
        <>
          <form onSubmit={onSubmit}>
            <div className="space-y-12  ml-2">
              <div
                className="grid grid-cols-1 gap-x-8 gap-y-10  
                pb-12 
                    md:grid-cols-3
                    "
              >
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Labels.Title}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Veuillez ici configurer les trimestres de l'année en cours
                  </p>
                </div>
                <div className="md:col-span-2">
                  <div
                    className="grid grid-cols-1 gap-x-8 gap-y-10  
                    border-b border-gray-900/10 pb-12 
                    md:grid-cols-3
                    mb-3"
                  >
                    <div className="sm:flex-col flex-row md:col-span-2">
                      <div className="sm:flex-col md:flex flex-row mb-5">
                        <div className="w-full">
                          <label
                            htmlFor="set-1"
                            className="block text-sm font-medium leading-6
                             text-gray-900"
                          >
                            {Labels.StartTrimestre1}
                          </label>
                          <div className="mt-2">
                            <input
                              id="set-1"
                              name="set-1"
                              type="date"
                              max={yearSetting.firstQuarterEnd}
                              value={yearSetting.firstQuarterStart}
                              onChange={(e) =>
                                updateProp(
                                  "firstQuarterStart",
                                  e.currentTarget.value
                                )
                              }
                              className="block w-full rounded-md 
                              border-0 py-1.5 text-gray-900 
                              shadow-sm ring-1 ring-inset
                               ring-gray-300 placeholder:text-gray-400 
                               focus:ring-2 focus:ring-inset
                                focus:ring-purple-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="w-full mr-5">
                          <label
                            htmlFor="set-2"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {" "}
                            {Labels.EndTrimestre1}
                          </label>
                          <div className="mt-2">
                            <input
                              id="set-1"
                              name="set-1"
                              type="date"
                              min={yearSetting.firstQuarterStart}
                              max={addDays(yearSetting.secondQuarterStart, -1)}
                              value={yearSetting.firstQuarterEnd}
                              onChange={(e) =>
                                updateProp(
                                  "firstQuarterEnd",
                                  e.currentTarget.value
                                )
                              }
                              className="block w-full rounded-md border-0 py-1.5
                               text-gray-900 shadow-sm ring-1 ring-inset
                                ring-gray-300 placeholder:text-gray-400 
                                focus:ring-2 focus:ring-inset
                                 focus:ring-purple-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="mt-3 ">
                          <button
                            className="flex w-full justify-center rounded-md
                             bg-blue-500 px-3 py-1.5 text-sm 
                             font-semibold leading-6 text-white 
                             shadow-sm hover:bg-orange-500 
                             focus-visible:outline focus-visible:outline-2 
                             focus-visible:outline-offset-2
                              focus-visible:outline-pink-600"
                            type="button"
                            onClick={() =>
                              onButtonClick(
                                yearSetting.firstQuarterId,
                                "firstQuarterLocked"
                              )
                            }
                          >
                            {" "}
                            <i
                              className={
                                yearSetting.firstQuarterLocked
                                  ? "icon-lock"
                                  : "icon-unlock"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div className="sm:flex-col md:flex flex-row mb-5">
                        <div className="w-full">
                          <label
                            htmlFor="set-1"
                            className="block text-sm font-medium leading-6
                             text-gray-900"
                          >
                            {Labels.StartTrimestre2}
                          </label>
                          <div className="mt-2">
                            <input
                              id="set-1"
                              name="set-1"
                              type="date"
                              min={addDays(yearSetting.firstQuarterEnd, 1)}
                              max={yearSetting.secondQuarterEnd}
                              value={yearSetting.secondQuarterStart}
                              onChange={(e) =>
                                updateProp(
                                  "secondQuarterStart",
                                  e.currentTarget.value
                                )
                              }
                              className="block w-full rounded-md 
                              border-0 py-1.5 text-gray-900 
                              shadow-sm ring-1 ring-inset
                               ring-gray-300 placeholder:text-gray-400 
                               focus:ring-2 focus:ring-inset
                                focus:ring-purple-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="w-full mr-5">
                          <label
                            htmlFor="set-2"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {" "}
                            {Labels.EndTrimestre2}
                          </label>
                          <div className="mt-2 ">
                            <input
                              id="set-1"
                              name="set-1"
                              type="date"
                              min={yearSetting.secondQuarterStart}
                              max={addDays(yearSetting.thirdQuarterStart, -1)}
                              value={yearSetting.secondQuarterEnd}
                              onChange={(e) =>
                                updateProp(
                                  "secondQuarterEnd",
                                  e.currentTarget.value
                                )
                              }
                              className="block w-full rounded-md border-0 py-1.5
                               text-gray-900 shadow-sm ring-1 ring-inset
                                ring-gray-300 placeholder:text-gray-400 
                                focus:ring-2 focus:ring-inset
                                 focus:ring-purple-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <button
                            className="flex w-full justify-center rounded-md
                             bg-blue-500 px-3 py-1.5 text-sm 
                             font-semibold leading-6 text-white 
                             shadow-sm hover:bg-orange-500 
                             focus-visible:outline focus-visible:outline-2 
                             focus-visible:outline-offset-2
                              focus-visible:outline-pink-600"
                            type="button"
                            onClick={() =>
                              onButtonClick(
                                yearSetting.secondQuarterId,
                                "secondQuarterLocked"
                              )
                            }
                          >
                            <i
                              className={
                                yearSetting.secondQuarterLocked
                                  ? "icon-lock"
                                  : "icon-unlock"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div className="sm:flex-col md:flex flex-row mb-5">
                        <div className="w-full">
                          <label
                            htmlFor="set-1"
                            className="block text-sm font-medium leading-6
                             text-gray-900"
                          >
                            {Labels.StartTrimestre3}
                          </label>
                          <div className="mt-2">
                            <input
                              id="set-1"
                              name="set-1"
                              type="date"
                              min={addDays(yearSetting.secondQuarterEnd, 1)}
                              max={yearSetting.thirdQuarterEnd}
                              value={yearSetting.thirdQuarterStart}
                              onChange={(e) =>
                                updateProp(
                                  "thirdQuarterStart",
                                  e.currentTarget.value
                                )
                              }
                              className="block w-full rounded-md 
                              border-0 py-1.5 text-gray-900 
                              shadow-sm ring-1 ring-inset
                               ring-gray-300 placeholder:text-gray-400 
                               focus:ring-2 focus:ring-inset
                                focus:ring-purple-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="w-full ml-2 mr-5">
                          <label
                            htmlFor="set-2"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {" "}
                            {Labels.EndTrimestre3}
                          </label>
                          <div className="mt-2 ">
                            <input
                              id="set-1"
                              name="set-1"
                              type="date"
                              min={yearSetting.secondQuarterEnd}
                              value={yearSetting.thirdQuarterEnd}
                              onChange={(e) =>
                                updateProp(
                                  "thirdQuarterEnd",
                                  e.currentTarget.value
                                )
                              }
                              className="block w-full rounded-md border-0 py-1.5
                               text-gray-900 shadow-sm ring-1 ring-inset
                                ring-gray-300 placeholder:text-gray-400 
                                focus:ring-2 focus:ring-inset
                                 focus:ring-purple-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <button
                            className="flex w-full justify-center rounded-md
                             bg-blue-500 px-3 py-1.5 text-sm 
                             font-semibold leading-6 text-white 
                             shadow-sm hover:bg-orange-500 
                             focus-visible:outline focus-visible:outline-2 
                             focus-visible:outline-offset-2
                              focus-visible:outline-pink-600"
                            type="button"
                            onClick={() =>
                              onButtonClick(
                                yearSetting.thirdQuarterId,
                                "thirdQuarterLocked"
                              )
                            }
                          >
                            <i
                              className={
                                yearSetting.thirdQuarterLocked
                                  ? "icon-lock"
                                  : "icon-unlock"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <SubmitButton/>                   
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );


};

export default YearSettings;
