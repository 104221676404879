import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../common/utils";
registerLocale('fr', fr)

interface DateLabelValidationProps {
    selectedDate: Date | null,
    onChangeDate: (date: Date | null, event?: any) => void,
    hasError: boolean;
    errorLabel: string;
    label: string;

}


const DateLabelValidation = ({ selectedDate, onChangeDate,
    hasError,
    errorLabel,
    label }: DateLabelValidationProps) => {

    const Input = () => <input

        type={"text"}
        className={classNames(
            "pl-2",
            "block w-full",
            "ring-1 ring-inset",
            "",
            "rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6",
            "focus:'ring-1 ring-inset'",
            hasError
                ? "text-red-900  ring-1 ring-inset ring-red-300 focus:ring-red-300"
                : ""
        )}
    // value={selectedDate}
    // onChange={onChangeDate}
    />

    return <>
        <div className="sm:col-span-3 mb-2">
            <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {label}
            </label>
            <div className=" relative mt-2">
                <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    locale={fr}
                    selected={selectedDate}
                    onChange={onChangeDate}
                    className={classNames(
                        "pl-2",
                        "block w-full",
                        "ring-1 ring-inset",
                        "",
                        "rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6",
                        "focus:'ring-1 ring-inset'",
                        hasError
                            ? "text-red-900  ring-1 ring-inset ring-red-300 focus:ring-red-300"
                            : ""
                    )}

                />
                {hasError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-red-500"
                        />
                    </div>
                )}
            </div>

            {hasError && (
                <p id="email-error" className="mt-2 text-sm text-red-600">
                    {errorLabel}
                </p>
            )}
        </div>

    </>
}

export default DateLabelValidation