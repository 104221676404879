import React from "react";
import "./output.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Users from "./views/users/index";
import Subjects from "./views/subjects/index";
import AddSubject from "./views/subjects/add";
import Import from "./views/import/index";
import Students from "./views/students/index";
import Student from "./views/students/detail";
import Classes from "./views/class/index";
import AddClass from "./views/class/addClass";
import Teachers from "./views/teacher/index";
import Levels from "./views/levels/index";
import LevelDetail from "./views/levels/detail";
import Poles from "./views/poles/index";
import PoleDetail from "./views/poles/detail";
import Add from "./views/users/add";
import * as RoutesUrl from "./routing/routes";
import { ToastProvider } from "react-toast-notifications";
import Login from "./views/login/login";
import ForgottenPassword from "./views/login/forgottenPassword";
import ConfirmForgottenPassword from "./views/login/confirmForgottenPassword";
import UpdateUser from "./views/users/updateUser";
import Settings from "./views/settings/index";
import GestionAbsences from "./views/manageAbsences/index";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { isAdmin, isAuthenticated } from "./common/utils";
import MainLayout from "./layout/mainLayout";
import NonConnectedLayout from "./layout/nonConnectedLayout";
import StudentDetail from "./views/students/detail";
import ProtectedRoute from "./routing/protectedRoute";

function App() {
  return (
    <div className="App">
      {
        <ToastProvider>
          <Router>
            {(
              <>
                <MainLayout>
                  <Routes>
                    <Route path={RoutesUrl.LOGIN} element={<Login />}></Route>
                    <Route
                      path={RoutesUrl.FORGOTTEN_PASSWORD}
                      element={<ForgottenPassword />}
                    ></Route>
                    <Route
                      path={RoutesUrl.NEW_PASSWORD}
                      element={<ConfirmForgottenPassword />}
                    ></Route>
                    <Route element={<ProtectedRoute />}>
                      <Route path={RoutesUrl.HOME_ROUTE} />

                      {isAdmin() && (
                        <>
                          <Route element={<Import />} path={RoutesUrl.IMPORT} />
                          <Route
                            element={<Settings />}
                            path={RoutesUrl.YEAR_SETTING}
                          />
                          <Route element={<Levels />} path={RoutesUrl.LEVELS} />
                          <Route
                            element={<Users />}
                            path={RoutesUrl.UTILISATEURS}
                          />
                          <Route
                            element={<Add />}
                            path={RoutesUrl.UTILISATEURS_ADD}
                          />
                          <Route
                            element={<Subjects />}
                            path={RoutesUrl.MATIERES}
                          />
                          <Route
                            element={<AddSubject />}
                            path={RoutesUrl.MATIERES_ADD}
                          />
                          <Route
                            element={<Students />}
                            path={RoutesUrl.STUDENTS}
                          />
                          <Route
                            element={<Student />}
                            path={RoutesUrl.STUDENT}
                          />
                          <Route
                            element={<Teachers />}
                            path={RoutesUrl.TEACHERS}
                          />
                          <Route
                            element={<AddClass />}
                            path={RoutesUrl.CLASSES_ADD}
                          />
                          <Route
                            element={<AddClass />}
                            path={RoutesUrl.CLASSES_EDIT}
                          />

                          <Route element={<Levels />} path={RoutesUrl.LEVELS} />
                          <Route
                            element={<LevelDetail />}
                            path={RoutesUrl.LEVEL_EDIT}
                          />

                          <Route
                            element={<LevelDetail />}
                            path={RoutesUrl.LEVEL_ADD}
                          />

                          <Route element={<Poles />} path={RoutesUrl.POLES} />

                          <Route
                            element={<PoleDetail />}
                            path={RoutesUrl.POLE_EDIT}
                          />

                          <Route
                            element={<PoleDetail />}
                            path={RoutesUrl.POLE_ADD}
                          />

                          <Route
                            element={<UpdateUser />}
                            path={RoutesUrl.UTILISATEUR_UPDATE}
                          />
                          <Route
                            element={<Students />}
                            path={RoutesUrl.CLASSE_STUDENTS}
                          />
                          <Route
                            element={<StudentDetail />}
                            path={RoutesUrl.STUDENT}
                          />
                        </>
                      )}

                      <Route element={<Classes />} path={RoutesUrl.CLASSES} />

                      <Route
                        element={<GestionAbsences />}
                        path={RoutesUrl.GESTION_ABSENCE}
                      />
                    </Route>
                    <Route element={<Login />} path={RoutesUrl.LOGIN} />
                  </Routes>
                </MainLayout>
              </>
            )}
          </Router>
        </ToastProvider>
      }

    </div>
  );
}

export default App;
