import React, { useEffect, useState } from "react";
import BeeLoader from "../../layout/loader";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import { useParams, useNavigate } from "react-router-dom";
import ApiUrls from "../../common/urls";
import { Teacher } from "../teacher/teacher";
import { Class } from "./class";
import { Level } from "../levels/level";
import { getHeaders } from "../../common/utils";
import DeleteModal from "../common/delete";
import { fetchApi } from "../../common/api";
import { date } from "yup";
import InputLabelValidation from "../components/inputLabelValidation";
import Select from "../components/select";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";

const Labels = {
  IsRequired: "Ce champ est requis",
  CorrectForm: "Veuillez corriger les erreurs",
  Error: "Une erreur est survenue",
  SelectTeacher: "Sélectionnez un professeur",
  SelectLevel: "Sélectionnez un niveau",
  Label: "Détail d'une classe",
  Save: "Enregistrer",
  Cancel: "Annuler",
  Delete: "Supprimer",
  ChooseTeacher: "Choisissez un professeur ainsi que des jours de cours",
  SelectAge: "Choisissez la tranche d'âge",
  SelectGenre: "Choisissez le genre",
};

interface Day {
  name: string;
  value: number;
}

interface TeacherDay {
  teacherId: number;
  dayIds: number[];
}

const AddClass = () => {
  const days: Day[] = [
    {
      name: "Lundi",
      value: 1,
    },
    {
      name: "Mardi",
      value: 2,
    },
    {
      name: "Mercredi",
      value: 3,
    },
    {
      name: "Jeudi",
      value: 4,
    },
    {
      name: "Vendredi",
      value: 5,
    },
    {
      name: "Samedi",
      value: 6,
    },
    {
      name: "Dimanche",
      value: 0,
    },
  ];

  const id = (useParams() as any)?.id;
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState<Teacher[] | null>([]);
  const [description, setDescription] = useState("");
  const [levelId, setLevelId] = useState<number>();
  const [isLoading, setisLoading] = useState(false);
  const [levels, setLevels] = useState<Level[]>();
  const [errors, setErrors] = useState<any>({});
  const [canBeDeleted, setCanDelete] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [ageId, setAgeId] = useState<number>(-1);
  const [genreId, setGenreId] = useState<number>(-1);

  const [teacherDays, setTeacherDays] = useState<TeacherDay[] | null>([]);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  const validate = (): boolean => {
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!name) {
      errorsTemp["name"] = Labels.IsRequired;
    }
    if (!description) {
      errorsTemp["description"] = Labels.IsRequired;
    }
    if (!levelId) {
      errorsTemp["level"] = Labels.IsRequired;
    }
    if (!teacherDays || teacherDays.length === 0) {
      errorsTemp["teacher"] = Labels.ChooseTeacher;
    } else {
      let noDay = false;
      teacherDays.map((t) => {
        if (t.dayIds.length === 0) {
          noDay = true;
        }
      });

      if (noDay) {
        errorsTemp["teacher"] = Labels.ChooseTeacher;
      }
    }

    if (ageId === -1) {
      errorsTemp["age"] = Labels.IsRequired;
    }

    if (genreId === -1) {
      errorsTemp["genre"] = Labels.IsRequired;
    }

    isValid = Object.entries(errorsTemp).length === 0;
    setErrors(errorsTemp);
    return isValid;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}`;

    const body = {
      name: name,
      description: description,
      id: parseInt(id),
      levelId: levelId,
      teacherDays,
      age: ageId,
      genre: genreId,
    };

    setisLoading(true);
    const type = id ? "PUT" : "POST";
    const response = await fetch(url, {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = !id
        ? `la classe ${name} est créée`
        : `la classe ${name} est mise à jour`;

      addToast(message, { appearance: "success", autoDismiss: true });
      navigate(Routes.CLASSES);
      setisLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setisLoading(false);
    }
  };

  const fetchTeachers = async (): Promise<Teacher[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Teachers}`;
    console.log("fetchTeachers");
    return await fetchApi<Teacher[]>(url);
  };

  const fetchLevels = async (): Promise<Level[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Level}`;
    return await fetchApi<Level[]>(url);
  };

  const fetchClass = async (): Promise<Class | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${id}`;
    return await fetchApi<Class>(url);
  };

  useEffect(() => {
    if (id) {
      fetchClass().then((response) => {
        console.log(response);
        if (response) {
          setName(response.name);
          setCanDelete(response.canBeDeleted);

          setDescription(response.description);
          setLevelId(response.levelId);
          setTeacherDays(
            response.teachers.map((t) => {
              return { teacherId: t.id, dayIds: t.dayIds?.map((d) => d) };
            })
          );
          setAgeId(response.age);
          setGenreId(response.genre);
        }
      });
    }

    setisLoading(true);
    fetchTeachers().then((response) => {
      setTeachers(response);
      setisLoading(false);
    });
    fetchLevels().then((response) => {
      if (response) {
        setLevels(response);
      }

      setisLoading(false);
    });
  }, []);

  const onTeacherSelect = (id: number) => {
    if (teacherDays) {
      let teacherDaysCopies: TeacherDay[] = [...teacherDays];

      const elt = teacherDaysCopies.findIndex((t) => t.teacherId === id);
      if (elt === -1) {
        const day = {
          teacherId: id,
          dayIds: [],
        };
        teacherDaysCopies.push(day);
      } else {
        teacherDaysCopies.splice(elt, 1);
      }

      setTeacherDays(teacherDaysCopies);
    }
  };

  const onDaysSelect = (id: number, teacherId: number) => {
    if (teacherDays) {
      let teacherDaysCopies: TeacherDay[] = [...teacherDays];

      const elt = teacherDaysCopies.findIndex((t) => t.teacherId === teacherId);
      if (elt > -1) {
        let day = teacherDaysCopies[elt];
        if (!day.dayIds) {
          day.dayIds = [];
        }
        day.dayIds.indexOf(id) === -1
          ? day.dayIds.push(id)
          : day.dayIds.splice(day.dayIds.indexOf(id), 1);
      }
      setTeacherDays(teacherDaysCopies);
    }
  };

  const deleteClasse = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}`;

    const body = {
      id: parseInt(id),
    };

    const response = await fetch(url, {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `la classe ${name} est supprimée`;
      addToast(message, { appearance: "success", autoDismiss: true });
      navigate(Routes.CLASSES);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  const genres = [
    {
      id: 0,
      name: "Homme",
    },
    {
      id: 1,
      name: "Femme",
    },
    {
      id: 2,
      name: "Mixte",
    },
  ];

  const ages = [
    {
      id: 0,
      name: "Enf.",
    },
    {
      id: 1,
      name: "Ado.",
    },
    {
      id: 2,
      name: "Adulte",
    },
  ];

  return (
    <div>
      <DeleteModal
        handleClose={() => setOnDelete(false)}
        show={onDelete}
        confirm={deleteClasse}
      />

      <form onSubmit={onSubmit} method="POST">
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Ajout d'une classe
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Veuillez compléter les informations suivantes
              </p>
            </div>
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 ">
              <InputLabelValidation
                errorLabel={errors["name"]}
                type="text"
                hasError={errors["name"] !== undefined}
                onChangeValue={(e) => setName(e.currentTarget.value)}
                inputValue={name}
                label="Nom"
              />
              <InputLabelValidation
                errorLabel={errors["description"]}
                type="text"
                hasError={errors["description"] !== undefined}
                onChangeValue={(e) => setDescription(e.currentTarget.value)}
                inputValue={description}
                label="Description"
              />
              <div className="sm:col-span-3">
                <Select
                  data={levels}
                  errorLabel={errors["level"]}
                  hasError={errors["level"] !== undefined}
                  label={Labels.SelectLevel}
                  onChange={(e) => setLevelId(e)}
                  value={levelId}
                  selectLabel={Labels.SelectLevel}
                />
              </div>
              <div className="sm:col-span-3">
                <Select
                  data={ages}
                  errorLabel={errors["age"]}
                  value={ageId}
                  hasError={errors["age"] !== undefined}
                  label={Labels.SelectAge}
                  onChange={(e) => setAgeId(e)}
                  selectLabel={Labels.SelectAge}
                />
              </div>
              <div className="sm:col-span-3">
                <Select
                  data={genres}
                  errorLabel={errors["genre"]}
                  value={genreId}
                  hasError={errors["genre"] !== undefined}
                  label={Labels.SelectGenre}
                  onChange={(e) => setGenreId(e)}
                  selectLabel={Labels.SelectGenre}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 ">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Choix du professeur
              </h2>
            </div>
            <div className="">
              {levels && (
                <>
                  <div className="flow-root mb-5">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Choix du professeur
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Nom
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Rôle
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Jour de cours
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {teachers?.map((tc) => {
                              return (
                                <tr key={tc.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      checked={
                                        teacherDays &&
                                        teacherDays.filter(
                                          (i) => i.teacherId === tc.id
                                        ).length > 0
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => onTeacherSelect(tc.id)}
                                    />
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {tc.name}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {tc.role}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {" "}
                                    <div className="row">
                                      {days.map((d: Day) => {
                                        const teacher = teacherDays?.find(
                                          (t) => t.teacherId === tc.id
                                        );
                                        const checked =
                                          teacher !== undefined &&
                                          teacher.dayIds !== undefined
                                            ? teacher.dayIds.filter(
                                                (i) => i === d.value
                                              ).length > 0
                                            : false;
                                        const name = `${tc.id}-${d.name}`;
                                        return (
                                          <div
                                            key={d.name}
                                            className="col-md-3"
                                            style={{ textAlign: "left" }}
                                          >
                                            <input
                                              id={name}
                                              name={name}
                                              type="checkbox"
                                              checked={checked}
                                              disabled={teacher === undefined}
                                              onClick={() =>
                                                onDaysSelect(d.value, tc.id)
                                              }
                                            />{" "}
                                            <label htmlFor={name}>
                                              {d.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {errors["teacher"] && (
                    <span className="error">{errors["teacher"]}</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <CancelButton url={Routes.CLASSES} />
          <SubmitButton />
          {canBeDeleted && (
            <CancelButton
              onCancelClick={() => setOnDelete(true)}
              label="Supprimer"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default AddClass;
