import React, { Fragment, useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import {
  createDownloadLink,
  getHeaders,
  getHeadersPdf,
} from "../../common/utils";
import BeeLoader from "../../layout/loader";
import { NoteReport, StudentReport, Subject } from "./class";
import YearAverages from "./yearAverages";
import TrimestresHeader from "./trimestreHeader";
import { fetchApi, fetchPdf } from "../../common/api";
import { useToasts } from "react-toast-notifications";

interface ResultsProps {
  classId: number;
}

const Labels = {
  LastName: "Nom",
  FirstName: "Prénom",
  DownloadResults: "Télécharger les résulats",
  FirstYearSetting: "1e Trimestre",
  SecondYearSetting: "2e Trimestre",
  ThirdYearSetting: "3e Trimestre",
  Moy: "Moy",
  Error: "Une erreur est survenue",
  Year: "Année",
};

interface ResultColumn {
  id: number;
  name: string;
  coeff: number;
  color: string,
  penColor: string,
}

const Results = ({ classId }: ResultsProps) => {
  const [classeAverages, setClasseAverages] = useState<StudentReport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState<ResultColumn[]>();
  const [yearSetting, setYearSetting] = useState<number | undefined>();
  const [download, setDownload] = useState(false);
  const [sorted, setSorted] = useState(true);
  const [sortByName, setSortByName] = useState(true);
  const [yearAverage, setYearAverage] = useState(false);

  const { addToast } = useToasts();

  const fetchAverages = async (): Promise<StudentReport[] | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${classId}/averages`;
    if (yearSetting) {
      url += `/${yearSetting}`;
    }
    const result = await fetchApi<StudentReport[]>(url);

    if (result === null) {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      return null;
    } else return result;
  };

  const fetchCurrentYearSetting = async (): Promise<number | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/current-year-setting`;
    return await fetchApi<number>(url);
  };

  useEffect(() => {
    if (classId) {
      setIsLoading(true);
      fetchAverages().then((response) => {
        if (response) {
          setClasseAverages(response);
          setColumns(getColumns(response));
        }

        setIsLoading(false);
      });
    }
    if (yearSetting === undefined && yearAverage === false) {
      fetchCurrentYearSetting().then((response) => {
        if (response) {
          setYearSetting(response);
        }
      });
    }
  }, [classId, yearSetting]);

  const getColumns = (
    response: StudentReport[]
  ): ResultColumn[] | undefined => {
    if (!response || response.length === 0) {
      return undefined;
    }
    const reports = response[0];
    const results = reports.subjects.map((s: Subject) => {
      return { id: s.id, name: s.alias, coeff: s.coefficient,color:s.color, penColor:s.penColor  };
    });
    return results;
  };

  const getNoteContents = (
    report: StudentReport
  ): JSX.Element[] | undefined => {
    const elements =
      columns &&
      columns.map((c: ResultColumn) => {
        return (
          <td
            className="whitespace-nowrap pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
            style={{ width: "60px" }}
          >
            {report.notes.map((n: NoteReport) => {
              if (n.subjectId === c.id) {
                const value = n.value >= 0 ? n.value : undefined;
                return value;
              }
            })}
          </td>
        );
      });

    return elements;
  };

  const getAverageContents = (): JSX.Element[] | undefined => {
    const elements =
      classeAverages &&
      columns &&
      columns.map((c: ResultColumn) => {
        const report = classeAverages[0];

        return (
          <td
            style={{ width: "60px" }}
            className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
          >
            {report.notes.map((n: NoteReport) => {
              if (n.subjectId === c.id) {
                const value =
                  n.classeAverage >= 0 ? n.classeAverage : undefined;
                return value;
              }
            })}
          </td>
        );
      });

    return elements;
  };

  const getContents = () => {
    const content = classeAverages.map((report: StudentReport) => {
      return (
        <tr key={report.id} className="odd:bg-gray-200">
          <td
            className="whitespace-nowrap  pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
            style={{ textAlign: "left" }}
          >
            {`${report.lastName} ${report.firstName}`}
          </td>
          <td
            className="whitespace-nowrap  pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
            style={{ fontWeight: "bold" }}
          >
            {report.average > -1 ? report.average : undefined}
          </td>
          {getNoteContents(report)}
        </tr>
      );
    });

    const classeAverage = (
      <tr>
        <td  className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-left">Classe</td>
        <td  className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          {classeAverages[0].classAverage > -1
            ? classeAverages[0].classAverage
            : undefined}
        </td>
        {getAverageContents()}
      </tr>
    );

    const result = (
      <Fragment>
        {content}
        <tr className="blank_row">&nbsp;</tr>
        {classeAverage}
      </Fragment>
    );

    return result;
  };

  const constructHeaders = (): JSX.Element[] | undefined => {
    const elements = columns?.map((c: ResultColumn) => {
     
      return (
        <th   scope="col"
        className="py-3.5 pl-2 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
        style={{
          backgroundColor: c.color,
          color: c.penColor,
          width: "60px",
        }}>
          <div>{c.name}</div>
          <div>{c.coeff}</div>
        </th>
      );
    });

    return elements;
  };

  const downloadResults = async () => {
    setDownload(true);
    let url =
      yearAverage === false
        ? `${process.env.REACT_APP_API}/${ApiUrls.Classes}/export-results/${classId}`
        : `${process.env.REACT_APP_API}/${ApiUrls.Classes}/year-student-passage/${classId}?all=true`;

    if (yearSetting && yearAverage === false) {
      url += `/${yearSetting}`;
    }

    const blob = await fetchPdf(url);
    if (!blob) return;
    const name = classeAverages[0].classe;
    const fileDownloaded =
      yearAverage === false
        ? `${name}-Trimestre-${yearSetting}.csv`
        : "Résultats-année.csv";

    createDownloadLink(blob, fileDownloaded);

    setDownload(false);
  };

  const getActiveSettings = (value: number) =>
    value === yearSetting && yearSetting !== undefined ? "btn active" : "btn";

  const onSort = () => {
    const copies = [...classeAverages];
    const sortedClasses = sorted
      ? copies.sort(
          (a: StudentReport, b: StudentReport) => a.average - b.average
        )
      : copies.sort(
          (a: StudentReport, b: StudentReport) => b.average - a.average
        );
    setSorted(!sorted);
    setClasseAverages(sortedClasses);
  };

  const onSortByName = () => {
    const copies = [...classeAverages];
    const sortedClasses = sortByName
      ? copies.sort((a, b) => a.lastName.localeCompare(b.lastName))
      : copies.sort((a, b) => b.lastName.localeCompare(a.lastName));
    setSortByName(!sortByName);
    setClasseAverages(sortedClasses);
  };
  const getActiveTab = () => (yearAverage === true ? "btn active" : "btn");

  const setYears = () => {
    setYearSetting(undefined);
    setYearAverage(true);
  };

  const setYearsSettings = (value: number) => {
    setYearSetting(value);
    setYearAverage(false);
  };
  return (
    <div className="">
      {isLoading && <BeeLoader />}
      {!isLoading && classeAverages.length === 0 && (
        <div>Aucun élève dans cette classe</div>
      )}
      {!isLoading && classeAverages.length !== 0 && (
        <div className="row">
          <TrimestresHeader
            chooseTrimestre={setYearsSettings}
            setYears={setYears}
            downloadResults={downloadResults}
            currentyearAverage={yearAverage}
            download={download}
            currentSetting={yearSetting}
          />
        </div>
      )}
      <div className="">
        {!isLoading && yearAverage && <YearAverages id={classId} />}
        {!isLoading &&
          !yearAverage &&
          yearSetting &&
          classeAverages.length > 0 && (
            <>
              <div className="flow-root mb-5">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            onClick={onSortByName}
                          >
                            <div style={{ textAlign: "left" }}>
                              {Labels.LastName}{" "}
                              <span className="ml-5">
                                {sortByName ? (
                                  <i className="icon-sort-by-attributes"></i>
                                ) : (
                                  <i className="icon-sort-by-attributes-alt"></i>
                                )}
                              </span>
                              {classeAverages && (
                                <div>{classeAverages.length} élève(s)</div>
                              )}
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-2 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                          >{`T${yearSetting}`}</th>
                          {constructHeaders()}
                        </tr>
                      </thead>
                      <tbody>{getContents()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default Results;
