import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import Button from "../components/button";
import CancelButton from "../components/cancelButton";

interface DeleteModalProps {
  show: boolean;
  handleClose: () => void;
  confirm: () => void;
}

const Labels = {
  Warning: "Avertissement",
  Sure: "Êtes vous certain de vouloir supprimer?",
  Confirm: "Confirmer",
  Cancel: "Annuler",
};

const DeleteModal = ({ show, handleClose, confirm }: DeleteModalProps) => {
  const onConfirmClick = () => {
    confirm();
    handleClose();
  };

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Labels.Warning}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{Labels.Sure}</Modal.Body>
        <Modal.Footer>
          <CancelButton onCancelClick={handleClose} label={Labels.Cancel} />
          <Button onClick={onConfirmClick} label={Labels.Confirm} />
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default DeleteModal;
