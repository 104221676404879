import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiUrls from "../../common/urls";
import { getHeaders, isEmailAddress } from "../../common/utils";
import BeeLoader from "../../layout/loader";
import {
  genres,
  initStudent,
  reasons,
  Status,
  statuses,
  Student,
  UpdateProps,
} from "./student";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { Class } from "../class/class";
import HistoryView from "./history";
import DeleteModal from "../common/delete";
import { fetchApi } from "../../common/api";
import InputLabelValidation from "../components/inputLabelValidation";
import Select from "../components/select";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";

const Labels = {
  addLabel: "Détail élève",
  isRequired: "Ce champ est requis",
  Email: "Le format n'est pas conforme",
  CorrectForm: "Veuillez corriger les erreurs",
  Error: "Une erreur est survenue",
  ChooseValue: "Sélectionnez une valeur",
};

const StudentDetail = () => {
  const id = (useParams() as any)?.id;
  const [student, setStudent] = useState<Student>(initStudent);
  const [classes, setClasses] = useState<Class[]>();
  const [selectedClasse, setSelectedClasse] = useState<number | undefined>();
  const [selectedStatus, setSelectedStatus] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [genre, setGenre] = useState<number | undefined>();
  const { addToast } = useToasts();
  const history = useNavigate();
  const [canSelectClass, setCanSelectClass] = useState(true);
  const [canDelete, setCanDelete] = useState(false);

  const validate = (): boolean => {
    if (!student) {
      return false;
    }
    setIsFormValid(true);
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!student?.lastName) {
      errorsTemp["lastName"] = Labels.isRequired;
    }
    if (!student?.firstName) {
      errorsTemp["firstName"] = Labels.isRequired;
    }
    if (!student?.birthDateLabel) {
      errorsTemp["birthDateLabel"] = Labels.isRequired;
    }

    if (!student?.zipCode) {
      errorsTemp["zipCode"] = Labels.isRequired;
    }
    if (!student?.address) {
      errorsTemp["address"] = Labels.isRequired;
    }
    if (!student?.city) {
      errorsTemp["city"] = Labels.isRequired;
    }
    if (
      student?.legalResponsible2Email &&
      !isEmailAddress(student?.legalResponsible2Email)
    ) {
      errorsTemp["legalResponsible2Email"] = Labels.Email;
    }

    if (student?.email && !isEmailAddress(student?.email)) {
      errorsTemp["email"] = Labels.Email;
    }

    if (
      student?.legalResponsible1Email &&
      !isEmailAddress(student?.legalResponsible1Email)
    ) {
      errorsTemp["legalResponsible1Email"] = Labels.Email;
    }
    if (genre === undefined) {
      errorsTemp["genre"] = Labels.isRequired;
    }
    if (selectedStatus === Status.Registered && !selectedClasse) {
      errorsTemp["selectedClasse"] = Labels.isRequired;
    }

    if (selectedStatus === "-1") {
      errorsTemp["selectedStatus"] = Labels.isRequired;
    }

    if (
      (student?.reason === null || student?.reason === -1) &&
      selectedStatus === Status.Unregistered
    ) {
      errorsTemp["reason"] = Labels.isRequired;
    }

    isValid = Object.entries(errorsTemp).length === 0;
    setIsFormValid(isValid);
    setErrors(errorsTemp);
    return isValid;
  };

  const updateProp = (prop: UpdateProps, value: string | number) => {
    let temp: any = { ...student };
    temp[prop] = value;
    setStudent(temp);
  };

  const onDelete = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Students}`;
    const body = {
      id: student?.id,
    };

    const response = await fetch(url, {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `l'élève ' ${student?.lastName} ${student?.firstName} est supprimé`;
      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.STUDENTS);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Students}`;
    const type = id ? "PUT" : "POST";
    if (student) {
      student.birthDate = new Date(student.birthDateLabel);
      student.classeId = selectedClasse;
      student.status = parseInt(selectedStatus);
      if (genre) {
        student.genre = genre;
      }
    }
    setIsLoading(true);
    const response = await fetch(url, {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(student),
    });

    if (response.ok) {
      const message = id
        ? `l'élève ${student?.lastName} ${student?.firstName} est mis à jour`
        : `l'élève ${student?.lastName} ${student?.firstName} est créé`;

      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.STUDENTS);
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const fetchStudent = async (): Promise<Student | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Students}/${id}`;
    return await fetchApi<Student>(url);
  };

  const fetchClasses = async (): Promise<Class[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}`;
    return await fetchApi<Class[]>(url);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchStudent().then((response) => {
        if (response) {
          setStudent(response);
          setSelectedClasse(response.classeId);
          if (
            !response.classeId &&
            response.status.toString() !== Status.Registered
          ) {
            setCanSelectClass(false);
          }
          setSelectedStatus(response.status.toString());

          setGenre(response.genre);
        }
        setIsLoading(false);
      });
    }
    fetchClasses().then((response) => {
      if (response) {
        setClasses(response);
      }
    });
  }, []);

  const onChangeStatus = (status: string) => {
    console.log(status);
    setCanSelectClass(true);
    if (status !== Status.Registered) {
      setSelectedClasse(undefined);
      setCanSelectClass(false);
    }
    setSelectedStatus(status);
  };

  return (
    <div className="row">
      <DeleteModal
        handleClose={() => setCanDelete(false)}
        show={canDelete}
        confirm={onDelete}
      />

      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}

      {!isLoading && (
        <>
          <form onSubmit={onSubmit}>
            <div className="space-y-12">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Informations élève
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Veuillez compléter le formulaire suivant
                  </p>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <InputLabelValidation
                    key="lastName"
                    errorLabel={errors["lastName"]}
                    hasError={errors["lastName"] !== undefined}
                    inputValue={student.lastName}
                    label="Nom"
                    onChangeValue={(e) =>
                      updateProp("lastName", e.currentTarget.value)
                    }
                  />
                  <InputLabelValidation
                    key="firstName"
                    errorLabel={errors["firstName"]}
                    hasError={errors["firstName"] !== undefined}
                    inputValue={student.firstName}
                    label="Prénom"
                    onChangeValue={(e) =>
                      updateProp("firstName", e.currentTarget.value)
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["birthDateLabel"]}
                    hasError={errors["birthDateLabel"] !== undefined}
                    inputValue={student.birthDateLabel}
                    type="date"
                    label="Date de naissance"
                    onChangeValue={(e) =>
                      updateProp("birthDateLabel", e.currentTarget.value)
                    }
                  />
                  <div className="sm:col-span-3">
                    <Select
                      data={genres}
                      errorLabel={errors["genre"]}
                      hasError={errors["genre"] !== undefined}
                      value={student.genre}
                      label="Genre"
                      onChange={(e) => setGenre(e)}
                      selectLabel="Choix genre"
                    />
                  </div>

                  <InputLabelValidation
                    errorLabel={errors["address"]}
                    hasError={errors["address"] !== undefined}
                    inputValue={student.address}
                    label="Adresse"
                    onChangeValue={(e) =>
                      updateProp("address", e.currentTarget.value)
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["zipCode"]}
                    hasError={errors["zipCode"] !== undefined}
                    inputValue={student.zipCode}
                    label="CP"
                    onChangeValue={(e) =>
                      updateProp("zipCode", e.currentTarget.value)
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["city"]}
                    hasError={errors["city"] !== undefined}
                    inputValue={student.city}
                    label="Ville"
                    onChangeValue={(e) =>
                      updateProp("city", e.currentTarget.value)
                    }
                  />

                  <div className=" sm:col-span-3"></div>
                  <InputLabelValidation
                    errorLabel={errors["cellular"]}
                    hasError={errors["cellular"] !== undefined}
                    inputValue={student.cellular}
                    label="Tél personnel"
                    onChangeValue={(e) =>
                      updateProp("cellular", e.currentTarget.value)
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["email"]}
                    hasError={errors["email"] !== undefined}
                    inputValue={student.email}
                    label="Mail personnel"
                    onChangeValue={(e) =>
                      updateProp("email", e.currentTarget.value)
                    }
                  />
                  <div className="sm:col-span-3">
                    <Select
                      data={statuses}
                      errorLabel={errors["selectedStatus"]}
                      hasError={errors["selectedStatus"] !== undefined}
                      value={selectedStatus}
                      label="Statut"
                      onChange={(e) => onChangeStatus(e.toString())}
                      selectLabel="Choisissez un statut"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Select
                      disabled={selectedStatus !== Status.Unregistered}
                      data={reasons}
                      errorLabel={errors["reason"]}
                      hasError={errors["reason"] !== undefined}
                      value={student.reason}
                      label="Motif"
                      onChange={(e) => updateProp("reason", e)}
                      selectLabel="Choisissez une raison"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    {classes && (
                      <Select
                        data={classes}
                        disabled={!canSelectClass}
                        errorLabel={Labels.isRequired}
                        hasError={errors["selectedClasse"] !== undefined}
                        value={selectedClasse}
                        label="Classe"
                        onChange={(e) => setSelectedClasse(e)}
                        selectLabel="Choisissez une classe"
                      />
                    )}
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Remarques
                    </label>
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <textarea
                        className="form-control"
                        value={student.comments}
                        onChange={(e) =>
                          updateProp("comments", e.currentTarget.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Informations Responsable préférentiel
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Veuillez compléter le formulaire suivant
                  </p>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <InputLabelValidation
                    errorLabel={Labels.isRequired}
                    hasError={false}
                    inputValue={student.legalResponsible1FullName}
                    label="Resp. préferentiel"
                    onChangeValue={(e) =>
                      updateProp(
                        "legalResponsible1FullName",
                        e.currentTarget.value
                      )
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["legalResponsible1Email"]}
                    hasError={errors["legalResponsible1Email"] !== undefined}
                    inputValue={student.legalResponsible1Email}
                    label="Mail Resp. préferentiel"
                    onChangeValue={(e) =>
                      updateProp(
                        "legalResponsible1Email",
                        e.currentTarget.value
                      )
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["legalResponsible1Cellular"]}
                    hasError={false}
                    inputValue={student.legalResponsible1Cellular}
                    label="Tél Resp. préferentiel"
                    onChangeValue={(e) =>
                      updateProp(
                        "legalResponsible1Cellular",
                        e.currentTarget.value
                      )
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Informations Responsable 2
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Veuillez compléter le formulaire suivant
                  </p>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <InputLabelValidation
                    errorLabel={Labels.isRequired}
                    hasError={false}
                    inputValue={student.legalResponsible2FullName}
                    label="Resp. légal 2"
                    onChangeValue={(e) =>
                      updateProp(
                        "legalResponsible2FullName",
                        e.currentTarget.value
                      )
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["legalResponsible2Email"]}
                    hasError={errors["legalResponsible2Email"] !== undefined}
                    inputValue={student.legalResponsible2Email}
                    label="Mail Resp. légal 2"
                    onChangeValue={(e) =>
                      updateProp(
                        "legalResponsible2Email",
                        e.currentTarget.value
                      )
                    }
                  />
                  <InputLabelValidation
                    errorLabel={errors["legalResponsible2Cellular"]}
                    hasError={errors["legalResponsible2Cellular"] !== undefined}
                    inputValue={student.legalResponsible2Cellular}
                    label="Tél Resp. légal 2"
                    onChangeValue={(e) =>
                      updateProp(
                        "legalResponsible2Cellular",
                        e.currentTarget.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <CancelButton url={Routes.STUDENTS} />
              <SubmitButton />
              {student?.canBeDeleted && (
                <CancelButton
                  onCancelClick={() => setCanDelete(true)}
                  label="Supprimer"
                />
              )}
            </div>
          </form>
          <div className="space-y-12">
            <HistoryView
              id={id}
              firstName={student.firstName}
              lastName={student.lastName}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default StudentDetail;
