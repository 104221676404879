import React, { useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import { Note } from "../students/student";
import AddWork from "./addWork";
import { ClassContent, YearStudentReport } from "./class";
import ContentStudent from "./contentStudent";
import Filters from "./filters";
import { useToasts } from "react-toast-notifications";
import LocalStorageKey from "../../common/localStorage";
import { getHeaders, isAdmin } from "../../common/utils";
import ActionButtons from "./actionButtons";
import Averages from "./averages";
import Results from "./results";
import Absences from "./absences";
import Students from "../students";
import YearAverages from "./yearAverages";
import TrimestresHeader from "./trimestreHeader";
import { fetchApi } from "../../common/api";
import { HttpStatus } from "../../common/status";
import Summary from "./summary";
import Button from "../components/button";

interface ClassContentProps {
  id: number;
}

interface AverageResponse {
  averageValue: number;
  subjectAverageValue: number;
  classSubjectAverage: number;
}

const Labels = {
  AddWork: "Ajouter devoir",
  Error: "Une erreur est survenue",
  FirstYearSetting: "1e Trimestre",
  SecondYearSetting: "2e Trimestre",
  ThirdYearSetting: "3e Trimestre",
  YearBlocked:
    "La période est clôturée. Seuls les administrateurs peuvent ôter la clôture.",
  Year: "Année",
};

enum Mode {
  Note,
  Absence,
  Results,
  Reports,
  Students,
  Year,
  Summary,
}

type StudentPerson = {
  id: number;
  note: number;
  noteId: number;
};

const DisplayClassContent = ({ id }: ClassContentProps) => {
  const currentId = id;
  const [content, setContent] = useState<ClassContent>();
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filterSubjectId, setFilterSubjectId] = useState<number | undefined>();
  const [askAddWork, setAskAddWork] = useState(false);
  const [currentNote, setCurrentNote] = useState<Note>();
  const [currentOrder, setCurrentOrder] = useState(0);
  const [currentMode, setCurrentMode] = useState<Mode>(Mode.Note);
  const [yearSetting, setYearSetting] = useState<number | undefined>();
  const [yearSettingLocked, setYearSettingLocked] = useState(false);

  const { addToast } = useToasts();

  const fetchContent = async (): Promise<ClassContent | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/content/${currentId}/${yearSetting}`;
    return await fetchApi<ClassContent>(url);
  };

  const fetchYearSettingLock = async (): Promise<boolean | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year/lock/${yearSetting}`;
    return await fetchApi<boolean>(url);
  };
  const fetchCurrentYearSetting = async (): Promise<number | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/current-year-setting`;
    return await fetchApi<number>(url);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      fetchContent().then((response) => {
        if (response) {
          setContent(response);
        }

        setIsLoading(false);
        setAskAddWork(false);
      });
    }
    if (yearSetting === undefined) {
      fetchCurrentYearSetting().then((response) => {
        if (response) {
          setYearSetting(response);
        }
      });
    }
  }, [id, reload, yearSetting]);

  useEffect(() => {
    if (yearSetting !== undefined) {
      fetchYearSettingLock().then((response) => {
        if (response !== null) {
          setYearSettingLocked(response);
        }
      });
    }
  }, [yearSetting]);

  const addedWork = async (note: Note) => {
    if (yearSettingLocked) {
      return;
    }
    const url = !currentNote
      ? `${process.env.REACT_APP_API}/${ApiUrls.Note}`
      : `${process.env.REACT_APP_API}/${ApiUrls.Note}/update-detail`;

    const body = {
      classeId: id,
      subjectId: note.subjectId,
      coefficient: note.coefficient,
      scale: note.scale,
      creationDate: note.date,
      order: currentOrder,
      comment: note.comment,
      yearSetting,
      bonus: note.bonus,
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      const json = await response.json();
      if (json && !json.success) {
        addToast(json.message, { appearance: "error", autoDismiss: true });
      }
    } catch (e: any) {}

    setAskAddWork(false);
    setReload(!reload);
  };

  const lock = async (classeId: number, order: number) => {
    if (yearSettingLocked) {
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Note}/toggle`;
    const body = {
      classeId,
      order,
      yearSetting,
    };

    if (content) {
      const copy = { ...content };
      copy.students.forEach((s) => {
        if (s.notes.length > order) {
          s.notes[order].isBlocked = !s.notes[order].isBlocked;
        }
      });
      setContent(copy);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      });

      if (response.status === HttpStatus.Error) {
        addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      }
    } catch (e) {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  const updateNote = async (
    studentId: number,
    id: number,
    value: number,
    ready: boolean
  ) => {
    if (yearSettingLocked) {
      return;
    }

    if (value < -0.1) {
      value = -1;
    }

    if (content) {
      const copy = { ...content };
      const student = copy.students.find((s) => s.id === studentId);
      if (student) {
        let note = student.notes.find((n) => n.id === id);

        if (note) {
          note.value =
            note.scale && note.scale + note.bonus < value ? note.value : value;

          value = note.value;
        }
      }
      setContent(copy);
    }

    if (ready) {
      const url = `${process.env.REACT_APP_API}/${ApiUrls.Note}`;
      const body = {
        id,
        value,
      };
      try {
        const response = await fetch(url, {
          method: "Put",
          headers: getHeaders(),
          body: JSON.stringify(body),
        });

        const bodyResponse = (await response.json()) as AverageResponse;
        if (content) {
          const copy = { ...content };
          const student = copy.students.find((s) => s.id === studentId);
          if (student) {
            let note = student.notes.find((n) => n.id === id);
            if (note) {
              note.subjectAverageValue = bodyResponse.subjectAverageValue;
            }

            if (note) {
              copy.students.forEach((s) => {
                s.notes.forEach((n) => {
                  if (
                    n.subjectId === note?.subjectId &&
                    n.date === note?.date
                  ) {
                    n.classeAverage = bodyResponse.averageValue;
                    n.classSubjectAverageValue =
                      bodyResponse.classSubjectAverage;
                  }
                });
              });
            }
          }
          setContent(copy);
        }
      } catch (e) {
        const items = localStorage.getItem(LocalStorageKey.Notes);
        let notes: StudentPerson[] = [];
        if (items !== null) {
          notes = JSON.parse(items);
        }
        const studentPerson = {
          id: studentId,
          note: value,
          noteId: id,
        };
        const copy = notes.find((c) => c.noteId === studentPerson.noteId);
        if (copy) {
          copy.note = value;
        } else {
          notes.push(studentPerson);
        }

        localStorage.setItem(LocalStorageKey.Notes, JSON.stringify(notes));

        addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      }
    }
  };

  const onFilter = (s: number): void => {
    setFilterSubjectId(s);
  };

  const del = async (classeId: number, order: number) => {
    if (yearSettingLocked) {
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Note}`;
    const body = {
      classeId,
      order,
      yearSetting,
    };

    const response = await fetch(url, {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = "Le devoir est supprimé";
      if (content) {
        const copy = { ...content };
        copy.students.forEach((s) => {
          s.notes.splice(order, 1);
        });
        setContent(copy);
      }

      addToast(message, { appearance: "success", autoDismiss: true });
      //setReload(!reload);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  const editWork = (order: number) => {
    if (content) {
      const note = content.students[0].notes[order];

      if (note) {
        setCurrentNote(note);
        setAskAddWork(true);
        setCurrentOrder(order);
      }
    }
  };

  const onAskAddWork = () => {
    setAskAddWork(!askAddWork);
    setCurrentNote(undefined);
  };

  const setNote = () => {
    setCurrentMode(Mode.Note);
  };

  const setAverage = () => {
    setCurrentMode(Mode.Reports);
  };

  const setResults = () => {
    setCurrentMode(Mode.Results);
  };

  const setAbsences = () => {
    setCurrentMode(Mode.Absence);
  };

  const setStudents = () => {
    setCurrentMode(Mode.Students);
  };

  const setSummary = () => {
    setCurrentMode(Mode.Summary);
  };
  const setYearsSettings = (value: number) => {
    setYearSetting(value);
    setCurrentMode(Mode.Note);
  };

  return (
    <div className="">
      {!askAddWork && id > 0 && (
        <ActionButtons
          notes={setNote}
          averages={setAverage}
          results={setResults}
          absences={setAbsences}
          students={setStudents}
          summary={setSummary}
          tabSelected={currentMode}
        />
      )}
      {id > 0 && (currentMode === Mode.Note || currentMode === Mode.Year) && (
        <TrimestresHeader
          chooseTrimestre={setYearsSettings}
          currentSetting={yearSetting}
        />
      )}

      {id > 0 && currentMode === Mode.Note && (
        <div className="row mb-2">
          {yearSettingLocked === true && (
            <label className="bold">{Labels.YearBlocked}</label>
          )}
        </div>
      )}

      {currentMode === Mode.Note && askAddWork && id > 0 && (
        <AddWork
          addWork={addedWork}
          classId={id}
          currentNote={currentNote}
          yearSetting={yearSetting}
          cancel={() => setAskAddWork(false)}
        />
      )}

      {currentMode === Mode.Note && id > 0 && !askAddWork && yearSetting && (
        <div className="flex flex-row mb-2 w-full sm:w-50">
          <div className="w-32">
            <Filters subjectFilter={onFilter} classId={id} />
          </div>

          <div className="w-32 mt-2 ml-2">
            {!askAddWork && yearSetting && (
              <Button
                label="Ajouter devoir"
                onClick={onAskAddWork}
                disabled={yearSettingLocked}
              />
            )}
          </div>
        </div>
      )}

      {isLoading && <BeeLoader />}
      {!isLoading &&
        !askAddWork &&
        yearSetting &&
        content &&
        currentMode === Mode.Note && (
          <ContentStudent
            students={content.students}
            lock={lock}
            classeId={id}
            subjects={content.subjects}
            filterSubjectId={filterSubjectId}
            deleteNote={del}
            updateNote={updateNote}
            editWork={editWork}
            yearSetting={yearSetting}
            yearSettingLocked={yearSettingLocked}
          />
        )}
      {!isLoading && currentMode === Mode.Year && <YearAverages id={id} />}

      {!isLoading && !askAddWork && currentMode === Mode.Reports && (
        <Averages classId={id} />
      )}
      {!isLoading && !askAddWork && currentMode === Mode.Results && (
        <Results classId={id} />
      )}
      {!isLoading && !askAddWork && currentMode === Mode.Absence && (
        <Absences classId={id} />
      )}

      {!isLoading && !askAddWork && currentMode === Mode.Students && (
        <Students classeId={id} />
      )}

      {!isLoading && !askAddWork && currentMode === Mode.Summary && (
        <Summary classId={id} />
      )}
    </div>
  );
};

export default DisplayClassContent;
