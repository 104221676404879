import React, { useEffect, useState } from 'react';

import ApiUrls from '../../common/urls';
import { createDownloadLink, getFileHeaders, getHeaders } from '../../common/utils';
import BeeLoader from '../../layout/loader';
import { Teacher } from './teacher';
import './styles.scss';
import { fetchApi } from '../../common/api';
import TeacherCard from './teacherCard';



const Teachers = () => {
    const [teachers, setTeachers] = useState<Teacher[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTeacherId, setCurrentTeacherId] = useState(0);
    const [isDownLoading, setIsDownLoading] = useState(false);
    const fetchTeachers = async (): Promise<Teacher[] | null> => {
        const url = `${process.env.REACT_APP_API}/${ApiUrls.Teachers}`;
        return await fetchApi<Teacher[]>(url);
    }

    useEffect(() => {

        setIsLoading(true);
        fetchTeachers()
            .then(response => {
                if (response) {
                    setTeachers(response);
                    setCurrentTeacherId(response[0].id)
                }

                setIsLoading(false);
            });

    }, [])

    const downloadTeachers = async () => {

        const url = `${process.env.REACT_APP_API}/${ApiUrls.Teachers}/export-teachers`;

        setIsDownLoading(true);
        const response = await fetch(url, {
            method: 'GET',
            headers: getFileHeaders()
        });

        const blob = await response.blob();
        createDownloadLink(blob, 'professeurs.csv');
        setIsDownLoading(false);
    }
    const getActiveClasse = (expectedTeacher: number): string => {
        return currentTeacherId === expectedTeacher ? 'active' : '';
    }
    const onMenuClick = (expectedTeacher: number) => {
        setCurrentTeacherId(expectedTeacher);
    }
    
   
    return <div className="row">
        <h4>Liste des professeurs</h4>
        {isLoading && <BeeLoader />}
        {!isLoading &&
            <div className='row'>
                
                <div className='teachers'>

                    <span className='col-md-2'>
                        <button className='btn mb-2'
                            disabled={isLoading}
                            title={'Télécharger les professeurs'} onClick={() => downloadTeachers()}>
                            <i title='Télécharger les professeurs' className={isDownLoading ? "icon-spinner" : 'icon-download-alt'}></i></button>

                    </span>
                </div>
            
                <div className='levels'>
                    <div className='row'>
                        <div className='col-md-3 menu'>
                            {
                                teachers.map(l => {
                                    return <div className={getActiveClasse(l.id)}
                                    style={{textAlign:'left'}}
                                        onClick={() => onMenuClick(l.id)}>
                                        <div style={{marginLeft:'15px'}}>
                                            {
                                                l.name
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='col-md-1'>

                        </div>
                        <div className='col-md-8'>
                            {
                                currentTeacherId && <TeacherCard 
                                teacher={teachers.find(l => l.id === currentTeacherId)} />
                            }
                        </div>
                    </div>


                </div>
            </div>
        }

    </div>

}

export default Teachers;