import React, { Fragment, useEffect, useState } from "react";
import {
  createDownloadLink,
  getHeaders,
  getHeadersPdf,
} from "../../common/utils";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import { History } from "./student";
import { fetchApi, fetchPdf } from "../../common/api";

interface HistoryProps {
  id: number;
  lastName?: string;
  firstName?: string;
}

const Labels = {
  FirstYearSetting: "1eT",
  SecondYearSetting: "2eT",
  ThirdYearSetting: "3eT",
};

const HistoryView = ({ id, lastName, firstName }: HistoryProps) => {
  const [histories, setHistories] = useState<History[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchHistories = async (): Promise<History[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Students}/${id}/history`;
    return await fetchApi<History[]>(url);
  };

  const downloadNotes = async (
    classId: number,
    yearSetting: number,
    name: string
  ) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Students}/${id}/history/${classId}/${yearSetting}`;

    const blob = await fetchPdf(url);
    if (!blob) return;

    const linkName = `${lastName}.${firstName}-${name}-${yearSetting}T.pdf`;
    createDownloadLink(blob, linkName);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchHistories().then((response) => {
        if (response) {
          setHistories(response);
        }

        setIsLoading(false);
      });
    }
  }, []);

  return (
    <div className="col-md-9">
      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="space-y-12">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Historique élève
                </h2>
              </div>
              <div className="">
                {histories &&
                  histories.map((h) => {
                    return (
                      <>
                        <div className="flex justify-between">
                          <div className="w-full text-right">{h.period}</div>

                          <div className="w-full text-right">{h.class}</div>
                          <div
                            className="w-full text-right cursor-pointer"
                            onClick={() => downloadNotes(h.id, 1, h.class)}
                          >
                            {Labels.FirstYearSetting}
                          </div>
                          <div
                            className="w-full text-right cursor-pointer"
                            onClick={() => downloadNotes(h.id, 2, h.class)}
                          >
                            {Labels.SecondYearSetting}
                          </div>
                          <div
                            className="w-full text-right cursor-pointer"
                            onClick={() => downloadNotes(h.id, 3, h.class)}
                          >
                            {Labels.ThirdYearSetting}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      )}
      {/* {
            !isLoading && <Fragment>
                <div className='text-center'>

                    <h4>Historique</h4>
                    {
                        histories && histories.map(h => {
                            return <div className='row'>
                                <div className='col-md-1'>
                                    {
                                        h.period
                                    }
                                </div>
                                <div className='col-md-3'>
                                    {h.class}
                                </div>
                                <div className="trimestre-header" onClick={() => downloadNotes(h.id, 1, h.class)}>{Labels.FirstYearSetting}</div>
                                <div className="trimestre-header" onClick={() => downloadNotes(h.id, 2, h.class)}>{Labels.SecondYearSetting}</div>
                                <div className="trimestre-header" onClick={() => downloadNotes(h.id, 3, h.class)}>{Labels.ThirdYearSetting}</div>
                            </div>
                        })
                    }
                </div>

            </Fragment>
        } */}
    </div>
  );
};

export default HistoryView;
