import React, { useEffect, useState, Fragment } from "react";
import Subject from "./subject";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import { useToasts } from "react-toast-notifications";
import DeleteModal from "../common/delete";
import "./style.scss";
import { classNames, getHeaders } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { Level } from "../levels/level";
import { fetchApi } from "../../common/api";
import SubjectCard from "./subjectCard";
import SideBarNavigation from "../components/sideBarNavigation";

const Labels = {
  Error: "Une erreur est survenue",
  NoContent: "Aucune matière à afficher",
  Add: "Ajouter",
  Title: "Liste des matières",
};

const Subjects = () => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [levels, setLevels] = useState<Level[]>([]);
  const [currentSubjectId, setCurrentSubjectId] = useState(0);
  const { addToast } = useToasts();
  const history = useNavigate();

  const onDelete = async (id: number) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Subjects}`;

    const body = {
      id: id,
    };

    setIsLoading(true);
    const response = await fetch(url, {
      method: "Delete",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      addToast("Suppression effectuée", {
        appearance: "success",
        autoDismiss: true,
      });
      setIsLoading(false);
      setReload(!reload);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const fetchSubjects = async (): Promise<Subject[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Subjects}`;
    return await fetchApi<Subject[]>(url);
  };
  const fetchLevels = async (): Promise<Level[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Level}`;
    return await fetchApi<Level[]>(url);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchSubjects().then((response) => {
      if (response && response.length > 0) {
        setSubjects(response);
        setCurrentSubjectId(response[0].id);
      }

      setIsLoading(false);
    });
    fetchLevels().then((response) => {
      if (response) {
        setLevels(response);
      }
    });
  }, [reload]);

  const clickOnDelete = (i: number) => {
    setCurrentSubjectId(i);
    setShowDelete(true);
  };
  const getActiveClasse = (expectedSubject: number): string => {
    return currentSubjectId === expectedSubject ? "active" : "";
  };
  const onMenuClick = (expectedSubject: number) => {
    setCurrentSubjectId(expectedSubject);
  };

  const clickOnDisplay = (id?: number) => {
    history(`/matieres/detail/${id}`);
  };

  return (
    <div className="">
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        confirm={() => onDelete(currentSubjectId)}
      />
      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}
      {!isLoading && subjects.length === 0 && (
         <h2 className="text-md font-bold text-gray-900">{Labels.NoContent}</h2>
      )}

      {!isLoading && subjects.length > 0  && (
        <>
          <div className="sm:flex-col flex-row md:flex">
            <SideBarNavigation
              data={subjects}
              onClick={onMenuClick}
              selectedValue={currentSubjectId}
            />
            <div className="w-full">
              { (
                <SubjectCard
                  clickOnDelete={clickOnDelete}
                  clickOnDisplay={clickOnDisplay}
                  levels={levels}
                  subject={subjects.find((s) => s.id === currentSubjectId)}
                />
              )}
            </div>
           
          </div>
        </>
      )}
    </div>
  );
};

export default Subjects;
