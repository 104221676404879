import React, { useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import {
  classNames,
  createDownloadLink,
  getHeaders,
  getHeadersPdf,
  isAdmin,
} from "../../common/utils";
import BeeLoader from "../../layout/loader";
import Pole from "../poles/pole";
import { NoteReport, StudentComment, StudentReport } from "./class";
import { useToasts } from "react-toast-notifications";
import { Modal } from "react-bootstrap";
import { fetchApi, fetchPdf } from "../../common/api";
import LocalStorageKey from "../../common/localStorage";
import TrimestresHeader from "./trimestreHeader";
import Button from "../components/button";
import CancelButton from "../components/cancelButton";
import InputLabelValidation from "../components/inputLabelValidation";
import SearchBar from "../components/searchBar";

interface AveragesProps {
  classId: number;
}

const Labels = {
  Error: "Une erreur est survenue",
  Emails: "Ci-dessous les adresses email auxquelles sera envoyé le bulletin",
  Confirm: "Confirmer",
  Cancel: "Annuler",
  StudentEmail: "Email élève",
  FatherEmail: "Email Resp. préférentiel",
  MotherEmail: "Email Resp. légal 2",
  FirstYearSetting: "1e Trimestre",
  SecondYearSetting: "2e Trimestre",
  ThirdYearSetting: "3e Trimestre",
  DownloadAll: "Tout télécharger",
  Search: "Recherche élève",
  SendAll: "Etes-vous certain de vouloir envoyer tous les bulletins?",
  WarningSendAll: "Avertissement",
  YearBlocked:
    "La période est clôturée. Seuls les administrateurs peuvent ôter la cloture.",
};

type StudentCommentBody = {
  description: string;
  studentId: number;
  poleId: number;
  yearSetting: number | undefined;
  id: number | null | undefined;
  isGlobal: boolean;
};

const Averages = ({ classId }: AveragesProps) => {
  const [original, setOriginal] = useState<StudentReport[]>([]);
  const [classeAverages, setClasseAverages] = useState<StudentReport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [yearSetting, setYearSetting] = useState<number | undefined>(undefined);
  const [currentReport, setcurrentReport] = useState<StudentReport>();
  const [downloadAll, setDownloadAll] = useState(false);
  const [sendAll, setSendAll] = useState(false);
  const [canSendAll, setCanSendAll] = useState(false);
  const [yearSettingLocked, setYearSettingLocked] = useState(false);
  const [selectedStudentsIds, setSelectedStudentsIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const { addToast } = useToasts();

  const fetchAverages = async (): Promise<StudentReport[] | null> => {
    let url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${classId}/averages`;
    if (yearSetting) {
      url += `/${yearSetting}`;
    }
    const result = await fetchApi<StudentReport[]>(url);
    if (result === null) {
      return null;
    } else return result;
  };

  const fetchYearSettingLock = async (): Promise<boolean | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year/lock/${yearSetting}`;

    return await fetchApi<boolean>(url);
  };

  const fetchCurrentYearSetting = async (): Promise<number | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/current-year-setting`;
    return await fetchApi<number>(url);
  };

  useEffect(() => {
    if (classId) {
      setIsLoading(true);
      fetchAverages().then((response) => {
        if (response !== null) {
          setClasseAverages(response);
          setOriginal(response);
        }
        setIsLoading(false);
      });
    }
    if (yearSetting === null || yearSetting === undefined) {
      fetchCurrentYearSetting().then((response) => {
        if (response !== null) {
          setYearSetting(response);
        }
      });
    }
  }, [classId, yearSetting]);

  useEffect(() => {
    fetchYearSettingLock().then((response) => {
      if (response !== null) setYearSettingLocked(response);
    });
  }, [yearSetting]);

  const onCommentBlur = async (
    poleId: number,
    studentId: number,
    comment: StudentComment | undefined,
    description: string,
    isGlobal: boolean
  ) => {
    if (yearSettingLocked) {
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/comment`;

    const body = {
      description: description,
      studentId: studentId,
      poleId: poleId,
      yearSetting: yearSetting,
      id: comment?.id,
      isGlobal,
    };

    try {
      const type = !comment || comment?.id < 0 ? "POST" : "PUT";
      const response = await fetch(url, {
        method: type,
        headers: getHeaders(),
        body: JSON.stringify(body),
      });

      const id = await response.json();
      if (comment) {
        comment.id = id;
      }
    } catch (e) {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      const items = localStorage.getItem(LocalStorageKey.Comments);
      let comments: StudentCommentBody[] = [];
      if (items !== null) {
        comments = JSON.parse(items);
      }
      const comment = body;
      const copy = comments.find((c) => c.id === comment.id);
      if (copy) {
        copy.description = body.description;
      } else {
        comments.push(comment);
      }

      localStorage.setItem(LocalStorageKey.Comments, JSON.stringify(comments));
    }
  };

  const savePdf = async (id: string, name: string, all: boolean) => {
    let url = all
      ? `${process.env.REACT_APP_API}/${ApiUrls.Classes}/download-all/${id}`
      : `${process.env.REACT_APP_API}/${ApiUrls.Classes}/download/${id}`;

    if (yearSetting) {
      url += `/${yearSetting}`;
    }

    const blob = await fetchPdf(url);
    if (!blob) return;
    const linkName = all ? `${name}.zip` : `${name}.pdf`;
    createDownloadLink(blob, linkName);
    setDownloadAll(false);
  };

  const send = async (id: string | undefined, all: boolean) => {
    setSendAll(false);
    setCanSendAll(false);

    let url = all
      ? `${process.env.REACT_APP_API}/${ApiUrls.Classes}/send-all`
      : `${process.env.REACT_APP_API}/${ApiUrls.Classes}/send/${id}`;

    if (yearSetting) {
      url += `/${yearSetting}`;
    }
    let response: Response;
    if (all) {
      response = await fetch(url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(selectedStudentsIds),
      });
    } else {
      response = await fetch(url, {
        method: "GET",
        headers: getHeadersPdf(),
      });
    }
    if (response.ok) {
      addToast("Le bulletin a été envoyé", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }

    setSelectedStudentsIds([]);
  };

  const getActiveSettings = (value: number) =>
    value === yearSetting ? "btn active" : "btn";

  const searchStudent = (value: string) => {
    const copies =
      value !== ""
        ? original.filter(
            (o) =>
              o.lastName
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              o.firstName
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase())
          )
        : original;
    if (copies.length > 0) {
      setClasseAverages(copies);
    }
  };

  const onSelectStudent = (studentId: number) => {
    const copies: number[] = [...selectedStudentsIds];
    const find = copies.find((c) => c === studentId);
    if (!find) {
      copies.push(studentId);
    } else {
      copies.splice(copies.indexOf(find), 1);
    }
    setSelectedStudentsIds(copies);
  };

  const onSelectAll = () => {
    const ids = selectAll ? [] : original.map((o) => o.id);
    setSelectedStudentsIds(ids);
    setSelectAll(!selectAll);
  };

  const buttonClasses = classNames(
    "flex w-full justify-center rounded-md",
    "bg-blue-500 px-3 py-1.5 text-sm ",
    " font-semibold leading-6 text-white",
    "shadow-sm hover:bg-orange-500 ",
    "focus-visible:outline focus-visible:outline-2",
    "focus-visible:outline-offset-2",
    "focus-visible:outline-pink-600"
  );

  return (
    <div>
      {isLoading && <BeeLoader />}
      {!isLoading && yearSettingLocked === true && (
        <label className="bold">{Labels.YearBlocked}</label>
      )}
      {!isLoading && (
        <>
          <div className="flex flex-row">
            <TrimestresHeader
              currentSetting={yearSetting}
              chooseTrimestre={setYearSetting}
            />
            <div className="w-10 ml-2 mt-2 justify-items-end">
              <button
                className={buttonClasses}
                disabled={downloadAll}
                title={Labels.DownloadAll}
                onClick={() => {
                  setDownloadAll(true);
                  savePdf(
                    classId.toString(),
                    `${classeAverages[0].classe}`,
                    true
                  );
                }}
              >
                <i
                  title="Télécharger tous les bulletins"
                  className={downloadAll ? "icon-spinner" : "icon-download-alt"}
                ></i>
              </button>
            </div>
            <div className="w-10 mt-2 ml-2 justify-items-end">
              {isAdmin() && (
                <button
                  className={buttonClasses}
                  disabled={sendAll}
                  title={"Envoyer tous les bulletins"}
                  onClick={() => setCanSendAll(true)}
                >
                  <i
                    title="Envoyer tous les bulletins"
                    className={sendAll ? "icon-spinner" : "icon-envelope"}
                  ></i>
                </button>
              )}
            </div>

            <div className="hidden md:flex ml-auto">
              <SearchBar
                onSearch={searchStudent}
                placeHolder="Recherche elève"
              />
            </div>
          </div>
          <div className="w-full flex flex-row md:hidden">
            <SearchBar onSearch={searchStudent} placeHolder="Recherche elève" />
          </div>
          <div>
            <div className="flow-root mb-5 ml-3">
              <div className="overflow-x-auto">
                <div className="">
                  {!isLoading &&
                    yearSetting &&
                    classeAverages.length > 0 &&
                    classeAverages.map((average: StudentReport) => {
                      const name = `${average.lastName} ${average.firstName}`;
                      const id = `${average.id}-${name}`;
                      const globalComment = average.comments.find(
                        (c) => c.isGlobal
                      );

                      return (
                        <div className="row" key={average.id}>
                          <div className="">
                            <div className="flex flex-row justify-end">
                              <div className="mb-3">
                                <button
                                  className={buttonClasses}
                                  onClick={() =>
                                    savePdf(
                                      average.id.toString(),
                                      `${average.firstName}-${average.lastName}-${average.classe}-${average.period}`,
                                      false
                                    )
                                  }
                                  type="button"
                                >
                                  <i
                                    title="Télécharger"
                                    className="icon-download-alt"
                                  ></i>
                                </button>
                              </div>
                              {isAdmin() && (
                                <div className="ml-2">
                                  <button
                                    className={buttonClasses}
                                    onClick={() => {
                                      setcurrentReport(average);
                                      setIsSending(true);
                                    }}
                                    type="button"
                                  >
                                    <i
                                      title="Envoyer"
                                      className="icon-envelope"
                                    ></i>
                                  </button>
                                </div>
                              )}
                            </div>
                            <div id={id} className="plum mb-5" key={average.id}>
                              <div className="row  snuff cadre ">
                                <div className="col-12 center">{name}</div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="border col-1"></div>
                                <div className="border col-3">
                                  {" "}
                                  Matières / المواد{" "}
                                </div>
                                <div className="border col-2"> Moyennes </div>
                                <div className="border col-6">
                                  Appréciations / ملحوظات
                                </div>
                              </div>
                              <div className="row ">
                                <div className="border col-1"></div>
                                <div className="border col-3"></div>
                                <div className="border col-1 text-truncate">
                                  {" "}
                                  Elève{" "}
                                </div>
                                <div className="border col-1 text-truncate">
                                  {" "}
                                  Classe{" "}
                                </div>
                                <div className="border col-6"></div>
                              </div>
                              <div className="">
                                {average.poles &&
                                  average.poles.map((p: Pole, i: number) => {
                                    const subjects = average.notes.filter(
                                      (n) => n.poleId === p.id
                                    );
                                    const comment = average.comments.find(
                                      (c) => c.poleId === p.id && !c.isGlobal
                                    );
                                    const mainClasse =
                                      i % 2 === 0 ? "row snuff" : "row";
                                    return (
                                      <div className={mainClasse}>
                                        <div
                                          className="border col-1 text-truncate"
                                          title={p.name}
                                        >
                                          {p.name}
                                        </div>
                                        <div className="border col-3 ">
                                          {subjects &&
                                            subjects.map((note: NoteReport) => {
                                              const subjectName =
                                                average.subjects.find(
                                                  (s) => s.id === note.subjectId
                                                )?.name;
                                              const traduction =
                                                average.subjects.find(
                                                  (s) => s.id === note.subjectId
                                                )?.traduction;

                                              let value = subjectName;
                                              if (traduction) {
                                                value = `${value}/${traduction}`;
                                              }
                                              return <div>{value}</div>;
                                            })}
                                        </div>

                                        <div className="border col-1">
                                          {subjects &&
                                            subjects.map((note: NoteReport) => {
                                              const subjectId =
                                                average.subjects.find(
                                                  (s) => s.id === note.subjectId
                                                )?.id;
                                              const studentAverage =
                                                average.notes.find(
                                                  (n) =>
                                                    n.subjectId === subjectId
                                                )?.value;
                                              const moy =
                                                studentAverage !== undefined &&
                                                studentAverage > -1
                                                  ? studentAverage
                                                  : undefined;
                                              return <div>{moy}</div>;
                                            })}
                                        </div>
                                        <div className="border col-1">
                                          {subjects &&
                                            subjects.map((note: NoteReport) => {
                                              const subjectId =
                                                average.subjects.find(
                                                  (s) => s.id === note.subjectId
                                                )?.id;

                                              const classeAverage =
                                                average.notes.find(
                                                  (n) =>
                                                    n.subjectId === subjectId
                                                )?.classeAverage;

                                              const moyenne =
                                                classeAverage !== undefined &&
                                                classeAverage > -1
                                                  ? classeAverage
                                                  : undefined;

                                              return (
                                                <div className="">
                                                  {moyenne}
                                                </div>
                                              );
                                            })}
                                        </div>
                                        <div className="border col-6">
                                          <textarea
                                            className="form-control comment"
                                            disabled={yearSettingLocked}
                                            readOnly={yearSettingLocked}
                                            defaultValue={comment?.comment}
                                            onBlur={(e) =>
                                              onCommentBlur(
                                                p.id,
                                                average.id,
                                                comment,
                                                e.currentTarget.value,
                                                false
                                              )
                                            }
                                            maxLength={300}
                                            placeholder="Appréciation"
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>

                              <div className="row ">
                                <div className="border col-1"></div>
                                <div className="border col-3">
                                  Moyenne générale
                                </div>
                                <div className="border col-1">
                                  {average.average > -1
                                    ? average.average
                                    : undefined}
                                </div>
                                <div className="border col-1">
                                  {average.classAverage > -1
                                    ? average.classAverage
                                    : undefined}
                                </div>
                                <div className="border col-6"></div>
                              </div>
                              <br />
                              <div className="row ">
                                <div className="border col-1"></div>
                                <div className="border col-3">Classement</div>
                                <div className="border col-1">
                                  {average.classement}
                                </div>
                                <div className="border col-1">
                                  {`/${average.total}`}
                                </div>
                                <div className="border col-6"></div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="border">
                                  <textarea
                                    className="form-control comment"
                                    disabled={yearSettingLocked}
                                    readOnly={yearSettingLocked}
                                    defaultValue={globalComment?.comment}
                                    onBlur={(e) =>
                                      onCommentBlur(
                                        -1,
                                        average.id,
                                        globalComment,
                                        e.currentTarget.value,
                                        true
                                      )
                                    }
                                    maxLength={400}
                                    placeholder="Appréciation globale"
                                  />
                                </div>
                              </div>
                              <div className="row assiduite">
                                <div className="col-6">
                                  {`Absences : ${
                                    average.totalAbsences !== ""
                                      ? average.totalAbsences.split(" ").length
                                      : 0
                                  }`}
                                </div>
                                <div className="col-6">
                                  {`Retards : ${
                                    average.totalLates !== ""
                                      ? average.totalLates.split(" ").length
                                      : 0
                                  }`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        show={canSendAll}
        onHide={() => setCanSendAll(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{Labels.WarningSendAll}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onClick={(e) => onSelectAll()}
                  />{" "}
                  Tout sélectionner
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Nom
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Prénom
                </th>
              </tr>
            </thead>
            <tbody>
              {original.map((s) => {
                return (
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <input
                        type="checkbox"
                        checked={
                          selectedStudentsIds &&
                          selectedStudentsIds.filter((i) => i === s.id).length >
                            0
                        }
                        onChange={() => {}}
                        onClick={(e) => onSelectStudent(s.id)}
                      />
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {s.lastName}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {s.firstName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {}
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onCancelClick={() => setCanSendAll(false)} />
          <Button
            label={Labels.Confirm}
            onClick={() => {
              setSendAll(true);
              send(classId.toString(), true);
            }}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={isSending}
        onHide={() => setIsSending(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{Labels.Emails}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <InputLabelValidation
              errorLabel=""
              hasError={false}
              label={Labels.StudentEmail}
              onChangeValue={() => {}}
              disabled={true}
              readonly={true}
              inputValue={currentReport?.email}
            />
            <InputLabelValidation
              errorLabel=""
              hasError={false}
              label={Labels.MotherEmail}
              onChangeValue={() => {}}
              disabled={true}
              readonly={true}
              inputValue={currentReport?.motherEmail}
            />
            <InputLabelValidation
              errorLabel=""
              hasError={false}
              label={Labels.FatherEmail}
              onChangeValue={() => {}}
              disabled={true}
              readonly={true}
              inputValue={currentReport?.fatherMail}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onCancelClick={() => setIsSending(false)} />
          <Button
            label={Labels.Confirm}
            disabled={
              !currentReport?.email &&
              !currentReport?.motherEmail &&
              !currentReport?.fatherMail
            }
            onClick={() => {
              send(currentReport?.id.toString(), false);
              setIsSending(false);
            }}
          />
        </Modal.Footer>
      </Modal>

      {!isLoading && classeAverages.length === 0 && (
        <div>Aucun élève dans cette classe</div>
      )}
    </div>
  );
};
export default Averages;
