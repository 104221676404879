import React, { Fragment, useEffect, useState } from "react";
import ApiUrls from "../../common/urls";
import { classNames, getHeaders } from "../../common/utils";
import BeeLoader from "../../layout/loader";
import * as Routes from "../../routing/routes";
import DeleteModal from "../common/delete";
import { Level } from "./level";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import "./styles.scss";
import { fetchApi } from "../../common/api";
import LevelCard from "./levelCard";
import SideBarNavigation from "../components/sideBarNavigation";

const Labels = {
  Error: "Une erreur est survenue",
  NoContent: "Aucune niveau à afficher",
  Add: "Ajouter",
  Actions: "Actions",
  Title: "Liste des niveaux",
};

const Levels = () => {
  const [levels, setLevels] = useState<Level[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>();
  const [showDelete, setShowDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const { addToast } = useToasts();
  const [currentLevel, setCurrentLevel] = useState(0);
  const history = useNavigate();

  const fetchLevels = async (): Promise<Level[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Level}`;
    return await fetchApi<Level[]>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchLevels().then((response) => {
      if (response && response.length > 0) {
        setLevels(response);
        setCurrentLevel(response[0].id);
      }
      setIsLoading(false);
    });
  }, [reload]);

  const clickOnDelete = (i: number) => {
    setCurrentId(i);
    setShowDelete(true);
  };

  const clickOnDisplay = (id?: number) => {
    history(`/niveau/detail/${id}`);
  };
  const onDelete = async (id: number | undefined) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Level}`;

    const body = {
      id: id,
    };

    setIsLoading(true);
    const response = await fetch(url, {
      method: "Delete",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      addToast("Suppression effectuée", {
        appearance: "success",
        autoDismiss: true,
      });
      setIsLoading(false);
      setReload(!reload);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const onMenuClick = (expectedLevel: number) => {
    setCurrentLevel(expectedLevel);
  };

  return (
    <div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        confirm={() => onDelete(currentId)}
      />

      {isLoading && (
        <div className="flex justify-center">
          <BeeLoader />
        </div>
      )}
      {!isLoading && levels && levels.length === 0 && (
         <h2 className="text-md font-bold text-gray-900">{Labels.NoContent}</h2>
      )}
      {!isLoading && levels && levels.length > 0 && (
        <Fragment>
          <div className="sm:flex-col flex-row md:flex">
            <SideBarNavigation
              data={levels}
              onClick={onMenuClick}
              selectedValue={currentLevel}
            />

            <div className="w-full pl-2">
              {" "}
              {currentLevel && (
                <LevelCard
                  clickOnDelete={clickOnDelete}
                  clickOnDisplay={clickOnDisplay}
                  level={levels.find((l) => l.id === currentLevel)}
                />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Levels;
