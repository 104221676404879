import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../common/utils";

interface InputLabelValidationProps {
  label: string;
  hasError: boolean;
  inputValue?: string | number;
  onChangeValue: React.ChangeEventHandler<HTMLInputElement>;
  errorLabel: string;
  type?: "text" | "number" | "email" | "password" | "date" ;
  disabled?: boolean,
  min?:string | number,
  max?:string,
  pattern?:string,
  readonly?:boolean
}

const InputLabelValidation = ({
  hasError,
  label,
  inputValue,
  onChangeValue,
  errorLabel,
  type,
  disabled,
  min,
  max,
  pattern,readonly
}: InputLabelValidationProps) => {
  return (
    <div className="sm:col-span-3 mb-2">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className=" relative mt-2">
        <input
          readOnly={readonly}
          disabled={disabled}
          type={type ?? "text"}
          min={min}
          max={max}
          pattern={pattern}
          className={classNames(
            "pl-2",
            "block w-full",
            "ring-1 ring-inset",
            "",
            "rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6",
            "focus:'ring-1 ring-inset'",
            hasError
              ? "text-red-900  ring-1 ring-inset ring-red-300 focus:ring-red-300"
              : ""
          )}
          value={inputValue}
          onChange={onChangeValue}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-500"
            />
          </div>
        )}
      </div>
      {hasError && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorLabel}
        </p>
      )}
    </div>
  );
};

export default InputLabelValidation;
