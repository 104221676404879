import React, { useEffect, useState } from "react";
import { Class } from "./class";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import DisplayClasses from "./displayClasses";
import * as Routes from "../../routing/routes";
import DisplayClassContent from "./content";
import "./style.scss";
import { fetchApi } from "../../common/api";
import SideBarNavigation from "../components/sideBarNavigation";
import SearchBar from "../components/searchBar";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";
import { classNames, isAdmin } from "../../common/utils";
import { Item, Menu, useContextMenu } from "react-contexify";

const Labels = {
  NoEntries: "Aucune classe à afficher"
};

const Classes = () => {
  const [classes, setClasses] = useState<Class[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [filteredClasses, setFilteredClasses] = useState<Class[]>([]);

  const history = useNavigate();
  const fetchClasses = async (): Promise<Class[] | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}`;
    return await fetchApi<Class[]>(url);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchClasses().then((response) => {
      if (response) {
        setClasses(response);
        setFilteredClasses(response);
        if (response.length === 1) {
          setCurrentId(response[0].id);
        }
      }
      setIsLoading(false);
    });
  }, []);

  const updateCurrentId = (id: number) => {
    setCurrentId(id);
  };
  const filterClass = (value: string) => {
    const copies = [...classes];
    const filteredClasses = copies.filter((c) =>
      c.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setFilteredClasses(filteredClasses);
  };
  const edit = () => {
    const url = `/classes/modification/${currentId}`;
    history(url);
  };
  const MENU_ID = "display-class-id";
  const { show } = useContextMenu({ id: MENU_ID });
  const onContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault();
    setCurrentId(index);
  };
  const displayMenu = (e: any) => {
    show({ event: e, props: { key: "value" } });
  };
  return (
    <div className="row">
      {isAdmin() && (
        <div className="col-md-2">
          <Menu id={MENU_ID}>
            <Item onClick={edit}>Editer</Item>
          </Menu>
        </div>
      )}
      {isLoading && (
        <>
          <div className="flex justify-center">
            <BeeLoader />
          </div>
        </>
      )}

      {!isLoading && (
        <>
          <div className="w-full">
            <div className="hidden w-full md:flex flex-row justify-end">
              <SearchBar
                onSearch={filterClass}
                placeHolder="Recherche classe"
              />
              {isAdmin() && (
                <div className=" mt-2 mb-2 ml-2 space-x-4">
                  <Button
                    label="Ajouter"
                    onClick={() => history(Routes.CLASSES_ADD)}
                  />
                </div>
              )}
            </div>
            <div className="w-full flex flex-row justify-end md:hidden">
              <SearchBar
                onSearch={filterClass}
                placeHolder="Recherche classe"
              />
              {isAdmin() && (
                <div className="relative mt-2 mb-2 ml-2">
                  <Button
                    label="Ajouter"
                    onClick={() => history(Routes.CLASSES_ADD)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="sm:flex-col flex-row md:flex">
            <div className="w-full md:w-20 mt-2 flex justify-left">
              <div className="sm:hidden w-full">
                <select
                  id="tabs"
                  name="tabs"
                  value={currentId}
                  onChange={(e) =>
                    updateCurrentId(parseInt(e.currentTarget.value))
                  }
                  className="block w-full rounded-md border-gray-300
           focus:border-purple-500 focus:ring-purple-500"
                >
                  <option value={-1}>---</option>
                  {filteredClasses.map((tab) => (
                    <option key={tab.id} value={tab.id}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav aria-label="Sidebar" className="flex flex-1 flex-col">
                  <ul role="list" className="-mx-2 space-y-1">
                    {filteredClasses.map((item) => (
                      <li key={item.name}>
                        <a
                          onContextMenu={displayMenu}
                          onClick={() => updateCurrentId(item.id)}
                          className={classNames(
                            currentId === item.id
                              ? "bg-orange-500"
                              : "bg-blue-500",
                            "text-white",
                            "hover:bg-orange-500 hover:bg-opacity-75 hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 ",
                            "hover:no-underline cursor-pointer"
                          )}
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="w-full">
              {currentId > -1 && <DisplayClassContent id={currentId} />}
              {currentId === -1 && classes.length > 0 && (
                <>
                  <div className="flex justify-center">
                    <h4 className="text-sm font-bold">
                      Veuillez choisir une classe
                    </h4>
                  </div>
                </>
              )}
              {classes.length === 0 && (
                <>
                  <div className="flex justify-center">
                    <h4 className="text-sm font-bold">
                      Aucune classe à afficher
                    </h4>
                  </div>
                </>
              )}
            </div>{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default Classes;
