import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiUrls from "../../common/urls";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import BeeLoader from "../../layout/loader";
import { getHeaders } from "../../common/utils";
import { BeeUser } from "./beeUser";
import { fetchApi } from "../../common/api";
import InputLabelValidation from "../components/inputLabelValidation";
import Select from "../components/select";
import { roles } from "./add";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";

const Labels = {
  IsRequired: "Ce champ est requis",
  Diff: "Le mot de passe ainsi que sa confirmation sont différents",
  Email: "Le format de l'identifiant n'est pas conforme",
  Lenght: "Veuillez entrer un mot de passe compris entre 5 et 10 caractères",
  CorrectForm: "Veuillez corriger les erreurs",
  Error: "Une erreur est survenue",
};

const UpdateUser = () => {
  const userName = (useParams() as any)?.username;

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [role, setRole] = useState<number>(-1);
  const [teacherId, setTeacherId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { addToast } = useToasts();
  const history = useNavigate();

  const validate = (): boolean => {
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!lastName) {
      errorsTemp["lastName"] = Labels.IsRequired;
    }
    if (!firstName) {
      errorsTemp["firstName"] = Labels.IsRequired;
    }

    if (!role) {
      errorsTemp["role"] = Labels.IsRequired;
    }

    isValid = Object.entries(errors).length === 0;
    setErrors(errorsTemp);
    return isValid;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Users}`;

    const body = {
      lastName,
      firstName,
      userName,
      teacherId,
      role:role === 0 ? "BeeLearningAdmin": "BeeLearningProf",
    };

    setIsLoading(true);
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const message = `l'utilisateur ${lastName} ${firstName} est mis à jour`;
      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.UTILISATEURS);
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  const fetchUser = async (): Promise<BeeUser | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Users}/${userName}`;
    return await fetchApi<BeeUser>(url);
  };

  useEffect(() => {
    setIsLoading(true);

    fetchUser().then((response) => {
      if (response) {
        setLastName(response.lastName);
        setFirstName(response.firstName);
        const role = roles.find(r => r.alias === response.role);
        if(role){
            setRole(role.id);
        }        
        setTeacherId(response.id);
      }

      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <form onSubmit={onSubmit} method="post">
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Modification d'un utilisateur
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Veuillez compléter le formulaire suivant
              </p>
            </div>
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["lastName"] !== undefined}
                  inputValue={lastName}
                  label="Nom"
                  onChangeValue={(e) => setLastName(e.currentTarget.value)}
                />
              </div>
              <div className="sm:col-span-3">
                <InputLabelValidation
                  errorLabel={Labels.IsRequired}
                  hasError={errors["firstName"] !== undefined}
                  inputValue={firstName}
                  label="Prénom"
                  onChangeValue={(e) => setFirstName(e.currentTarget.value)}
                />
              </div>
              <div className="sm:col-span-3">
                <Select
                  hasError={errors["role"] != undefined}
                  errorLabel={Labels.IsRequired}
                  data={roles}
                  label="Choisissez un rôle"
                  onChange={setRole}
                  value={role}
                  selectLabel="Choisissez le rôle"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <CancelButton label="Annuler" url={Routes.UTILISATEURS} />
            <SubmitButton />
          </div>
        </div>
      </form>
      {/* <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <h4>Edition d'un utilisateur</h4>
                <form className="form-horizontal" method="post">
                    <div className="form-group ">
                        <div className='form-floating'>
                            <input type="text" placeholder="Nom" className="form-control" required
                                value={lastName}
                                onChange={(e) => setLastName(e.currentTarget.value)} />
                            {
                                errors['lastName'] &&
                                <span className='error'>{errors['lastName']}</span>
                            }
                            <label>Nom</label>
                        </div>

                    </div>
                    <div className="form-group ">
                        <div className='form-floating'>
                            <input type="text" placeholder="Prénom" className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.currentTarget.value)}
                                required />
                            {
                                errors['firstName'] &&
                                <span className='error'>{errors['firstName']}</span>
                            }
                            <label>Prénom</label>
                        </div>

                    </div>

                    <div className="form-group">
                        <div className='form-floating'>
                            <select name="Role"
                                value={role}
                                onChange={(e) => setRole(e.currentTarget.value)}
                                className="form-select">
                                <option>Rôle</option>
                                <option value='BeeLearningAdmin'>Administrateur</option>
                                <option value='BeeLearningProf'>Professeur</option>
                            </select>
                            {
                                errors['role'] &&
                                <span className='error'>{errors['role']}</span>
                            }
                            <label>Rôles</label>
                        </div>

                    </div>

                    <div className="form-group">
                        <div className="text-center">
                            {
                                isLoading && <BeeLoader />
                            }
                            {
                                !isLoading && <div>
                                    <button type="button" onClick={onSubmit} className="btn mr-5">   <i className='icon-save'></i> </button>
                                    <a className='btn' href='/utilisateurs'>  <i className='icon-undo'></i></a>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-4"></div>
        </div> */}
    </div>
  );
};

export default UpdateUser;
