import React, { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LocalStorageKey from "../../common/localStorage";
import ApiUrls from "../../common/urls";
import BeeLoader from "../../layout/loader";
import * as Routes from "../../routing/routes";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import PhotoIcon from "@heroicons/react/24/outline/PhotoIcon";

const Labels = {
  Error: "Une erreur est survenue",
};

const Import = () => {
  const [isLoading, setisLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const { addToast } = useToasts();
  const history = useNavigate();

  const onSubmit = async (e:SyntheticEvent) => {
    e.preventDefault();
    
    if (file) {
      const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/import`;
      let formData = new FormData();
      formData.append("file", file);
      const token = localStorage.getItem(LocalStorageKey.Auth);

      setisLoading(true);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const message = `Import réussi`;
        addToast(message, { appearance: "success", autoDismiss: true });
        history(Routes.YEAR_SETTING);
        setisLoading(false);
      } else {
        addToast(Labels.Error, { appearance: "error", autoDismiss: true });
        setisLoading(false);
      }
    }
  };

  const updateFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const content = e.target.files![0];
    setFile(content);
  };

  return (
    <>
      {isLoading && <BeeLoader />}
      {!isLoading && (
        <form>
          <div className="space-y-12">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Paramétrage
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Veuillez importer le fichier excel fourni avec les données
                  désirées
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Importer le fichier</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={updateFile}
                          />
                        </label>
                      </div>
                      <p className="text-xs leading-5 text-gray-600"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
             text-white shadow-sm
              hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={onSubmit}
            >
              Sauvegarder les données
            </button>
          </div>
        </form>
      )}
    </>
  );

  // return <div>
  //     <div className='row'>
  //         <div className="col-md-2"></div>
  //         <div className="col-md-8">
  //         <label>Veuillez ici importer un fichier excel avec les paramètres nécessaires pour le bon fonctionnement de l'application</label>
  //             {
  //                 !isLoading &&

  //                     <form >
  //                         <div className="form-group">
  //                             <input className="form-control-file" type="file" onChange={updateFile} />
  //                             <button className="btn " type="button" onClick={onSubmit}>Importer</button>
  //                         </div>
  //                     </form>

  //             }
  //             {
  //                 isLoading && <BeeLoader/>
  //             }
  //         </div>
  //         <div className="col-md-2"></div>
  //     </div>

  // </div>
};

export default Import;
