import React, { Fragment } from 'react';
import { Teacher } from './teacher';

interface TeacherCardProps {
    teacher?: Teacher
}

const TeacherCard = ({ teacher }: TeacherCardProps) => {
    return <Fragment>
        <div className='level-card'>
            <div >
                <div className=''>
                    <label>Informations</label>
                </div>
                <div style={{textAlign:'left'}}>
                    {
                        teacher?.email
                    }
                </div>
                <div style={{textAlign:'left'}}>
                    {
                        teacher?.classes.split(',').map(ass => {
                            return <div className='teacher-subject'>{ass}</div>
                        })
                    }
                </div>
            </div>
            

        </div>
    </Fragment>
}

export default TeacherCard