import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiUrls from "../../common/urls";
import { getHeaders } from "../../common/utils";
import BeeLoader from "../../layout/loader";
import Pole from "./pole";
import * as Routes from "../../routing/routes";
import { useToasts } from "react-toast-notifications";
import { fetchApi } from "../../common/api";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";
import InputLabelValidation from "../components/inputLabelValidation";

const Labels = {
  editLabel: "Détail Pôle",
  isRequired: "Ce champ est requis",
  CorrectForm: "Veuillez corriger les erreurs",
  Error: "Une erreur est survenue",
  Cancel: "Annuler",
  Save: "Enregistrer",
};

const PoleDetail = () => {
  const id = (useParams() as any).id;
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState<any>({});
  const { addToast } = useToasts();
  const history = useNavigate();

  const fetchPoleDetail = async (): Promise<Pole | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Pole}/${id}`;
    return await fetchApi<Pole>(url);
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchPoleDetail().then((response) => {
        if (response) {
          setName(response.name);
        }
        setIsLoading(false);
      });
    }
  }, [id]);

  const validate = (): boolean => {
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!name) {
      errorsTemp["name"] = Labels.isRequired;
    }
    isValid = Object.entries(errors).length === 0;

    setErrors(errorsTemp);

    console.log(errorsTemp);
    return isValid;
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Pole}`;
    const type = id ? "PUT" : "POST";
    const level = {
      name: name,
      id: parseInt(id),
    };
    setIsLoading(true);
    const response = await fetch(url, {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(level),
    });

    if (response.ok) {
      const message = id
        ? `le pôle  est mis à jour`
        : `le pôle ${name} est créé`;

      addToast(message, { appearance: "success", autoDismiss: true });
      history(Routes.POLES);
      setIsLoading(false);
    } else {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        {isLoading && (
          <div className="flex justify-center">
            <BeeLoader />
          </div>
        )}
        {!isLoading && (
          <>
            <form onSubmit={onSubmit}>
              <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      {Labels.editLabel}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      Veuillez compléter les informations suivantes
                    </p>
                  </div>
                 
                  <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 ">
                    <InputLabelValidation errorLabel={errors["name"]}
                    hasError={errors["name"] !== undefined}
                    inputValue={name}
                    onChangeValue={(e) => setName(e.currentTarget.value)}
                    label="Nom du pôle"/>
                   
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <CancelButton url={Routes.POLES} />
                <SubmitButton />
              </div>
            </form>
          </>
          
        )}
      </div>
    </>
  );
  
};

export default PoleDetail;
