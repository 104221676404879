import React, { Fragment } from "react";
import { Level } from "../levels/level";
import Subject from "./subject";
import { useNavigate } from "react-router";

import * as RoutesUrl from "../../routing/routes";
import Button from "../components/button";

interface SubjectCardProps {
  subject?: Subject;
  levels: Level[];
  clickOnDelete: (i: number) => void;
  clickOnDisplay: (i?: number) => void;
}

const SubjectCard = ({
  subject,
  levels,
  clickOnDelete,
  clickOnDisplay,
}: SubjectCardProps) => {
  const history = useNavigate();
  return (
    <>
      <div className="flex justify-end mt-3">
        <Button label="Ajouter" onClick={() => history("/matieres/detail")} />
      </div>
      <div className="space-y-12 ml-3">
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-10 
              border-gray-900/10 pb-12 "
        >
          <div className="">
            <div
              className="grid grid-cols-1 gap-x-8 gap-y-10 
              border-b border-gray-900/10 pb-12 
              md:grid-cols-2"
            >
              <div className="md:col-span-1 text-left">
               
                <div className="flow-root mb-5">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Niveaux associés
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Coefficient
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {subject?.levelCoeffs.map((l) => {
                            const level = levels.filter(
                              (ll) => ll.id === l.level
                            );
                            return (
                              <>
                                {
                                  level.length > 0 && 
                                  <tr className="odd:bg-gray-200">
                                  <td className="whitespace-nowrap   pl-4 pr-3 text-center text-sm font-medium text-gray-900 sm:pl-0">
                                    {`${level[0].name}`}
                                  </td>
                                  <td className="whitespace-nowrap  pl-4 pr-3  text-center text-sm font-medium text-gray-900 sm:pl-0">
                                    {`${l.coefficient}`}
                                  </td>
                                </tr>
                                }
                               
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <div></div>
                  <Button
                    label="Editer"
                    onClick={() => clickOnDisplay(subject?.id)}
                  />

                  {subject?.canBeDeleted && (
                    <>
                      <Button
                        label="Supprimer"
                        onClick={() => clickOnDelete(subject?.id)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubjectCard;
