export const HOME_ROUTE = '/';
export const UTILISATEURS = '/utilisateurs';
export const UTILISATEURS_ADD = '/utilisateur/ajout';
export const UTILISATEUR_UPDATE = '/utilisateur/modification/:username';
export const MATIERES = '/matieres';
export const MATIERES_ADD = '/matieres/detail/:id?';
export const YEAR_SETTING = '/parametrage/annee';
export const IMPORT = '/parametrage/import';
export const STUDENTS = '/eleves';
export const STUDENT = '/eleve/:id?';
export const CLASSES = '/classes';
export const TEACHERS = '/professeurs';
export const CLASSES_ADD = '/classes/ajout';
export const CLASSES_EDIT = '/classes/modification/:id';
export const LOGIN = '/login';
export const LEVELS='/niveaux';
export const LEVEL_ADD='/niveau/ajout';
export const LEVEL_EDIT='/niveau/detail/:id';
export const POLE_EDIT='/pole/detail/:id';
export const POLES='/poles';
export const POLE_ADD='/pole/ajout';
export const FORGOTTEN_PASSWORD='/mot-de-passe-oublie/';
export const NEW_PASSWORD='/mot-de-passe/:guid';
export const ABSENCES = '/absences';
export const CLASSE_STUDENTS = '/eleves/:id';
export const GESTION_ABSENCE = '/gestion-absences'